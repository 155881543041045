<!--
* @Description: 非融已完成
* @Author: xueshuai
* @CreateDate: 2024/3/22 9:56:08
* @FileName: YsList.vue
!-->
<template>
    <div>
        <div class="">
            <div class="fr-title">山东省融资担保有限公司保函业务</div>
            <div class="form-area">
                <div class="row">
                    <div class="label">投标人名称：</div>
                    <div class="download">{{ data.enterpriseName }}</div>
                </div>
                <div class="row">
                    <div class="label">招标人名称：</div>
                    <div class="download">{{ data.tenderer }}</div>
                </div>
                <div class="row">
                    <div class="label">招标项目名称：</div>
                    <div class="download">{{ data.projectName }}</div>
                </div>
                <div class="row">
                    <div class="label">申请时间：</div>
                    <div class="download">{{ data.applyTime }}</div>
                </div>
                <div class="row">
                    <div class="label">受理流水号：</div>
                    <div class="download">{{ data.acceptNo }}</div>
                </div>
                <div class="row">
                    <div class="label">业务进展：</div>
                    <u class="download dif" @click="goState(data.applyStage, data.acceptNo)">{{
                        returnStatus(data.applyStage)
                    }}</u>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getUrl, cancelAuth, reAuth } from "@/api/task/YsList/YsListProgress"
import { getAcceptNoDetail } from "@/api/feiRong/index";
export default {
    name: "YsListProgress",
    props: {
        data: {
            type: Object,
            default: {}
        },
    },
    watch: {
        data: {
            handler(newV) {
                this.list = newV
            },
            deep: true,
        }
    },
    data() {
        return {
            list: [],
            dictList: [
                { name: '授权失败，请重新授权或联系工作人员', value: '1' },
                { name: '获取数据失败，请重新授权或联系工作人员', value: '2' },
                { name: '信息采集失败，若请重新授权或联系工作人员', value: '3' },
            ],
            stateList: [
                { name: '申请保函', value: '0' },
                { name: '邀约条款', value: '1' },
                { name: '资料填写', value: '2' },
                { name: '审核中', value: '3' },
                { name: '费用缴纳', value: '4' },
                { name: '保函生成', value: '5' },
                { name: '审核未通过', value: '6' },
                { name: '第三方平台支付中', value: '7' },
                { name: '订单已关闭', value: '8' },
            ],
            allList: []
        }
    },
    created() {
        this.list = this.data
    },
    mounted() {
        // console.log(new Date(this.appList[0].createTime).getTime());
        // console.log(new Date(this.data[0].creatTime).getTime());
    },
    methods: {
        returnStatus(arg) {
            let only = this.stateList.filter((item) => {
                return item.value == arg
            })
            return only[0].name
        },
        // 下一步
        onNext(row) {
            getUrl({ id: row.id }).then(res => {
                if (res.code == 200) {
                    var user = navigator.userAgent
                    var isAndroid = user.indexOf("Android") > -1 || user.indexOf("Adr") > -1;
                    // var isIos = !!user.match(/\(i[^;]+;( U;)? CPU.+Mac OS X)/);
                    if (isAndroid) {
                        window.open(res.msg)
                    } else {
                        window.location.href = res.msg
                    }
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        //非融根据不同状态跳转不同页面
        goState(state, acceptNo, generateeLetterNo) {
            if (state == 1 || state == 2) {
                this.$router.push({
                    name: 'bidApplicate',
                    query: {
                        state: state,
                        acceptNo: acceptNo,
                        generateeLetterNo: generateeLetterNo
                    }
                })
            } else if (state == 3) {
                this.$router.push({
                    name: 'bidResult',
                    query: {
                        state: state,
                        acceptNo: acceptNo,
                    }
                })
            } else if (state == 4) {
                this.$router.push({
                    name: 'payBank',
                    query: {
                        acceptNo: acceptNo,
                    }
                })
            } else if (state == 5) {
                this.$router.push({
                    name: 'letterDownload',
                    query: {
                        acceptNo: acceptNo,
                    }
                })
            } else if (state == 6) {
                this.$router.push({
                    name: 'bidResult',
                    query: {
                        state: state,
                        acceptNo: acceptNo,
                    }
                })
            } else if (state == 7) {
                this.$router.push({
                    name: 'bidResult',
                    query: {
                        state: state,
                        acceptNo: acceptNo,
                    }
                })
            } else if (state == 8) {
                this.$router.push({
                    name: 'bidResult',
                    query: {
                        state: state,
                        acceptNo: acceptNo,
                    }
                })
            }
            //     READAGREEMENT("1", "邀约条款"),
            //     RICHINFO("2", "资料填写"),
            //     AUDITING("3", "审核中"),
            //     PAYMENT("4", "费用缴纳"),
            //     LETTER("5", "保函生成"),
            //     REFUSED("6", "审核未通过"),
            //     THIRDPAY("7", "第三方平台支付中"), ('8','订单关闭')    
        },
        goBank(acceptNo) {
            getAcceptNoDetail(acceptNo)
                .then(res => {
                    if (res.data) {
                        let user = navigator.userAgent
                        let isAndroid = user.indexOf("Android") > -1 || user.indexOf("Adr") > -1;
                        // var isIos = !!user.match(/\(i[^;]+;( U;)? CPU.+Mac OS X)/);
                        if (isAndroid) {
                            window.open(res.data.sytUrl)
                        } else {
                            window.location.href = res.data.sytUrl
                        }
                    }
                })
                .catch(() => { })
        },
        // 取消授权
        onCel(row) {
            cancelAuth({ id: row.id }).then(res => {
                if (res.code == 200) {
                    this.$emit('list', 0)
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        // 重新授权
        async onEmpower(row) {
            await reAuth({ id: row.id }).then(res => {
                if (res.code == 200) {
                    this.$emit('list', 0, 1)
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        // 执行父组件补充资料
        onSup(row) {
            this.$emit('progress', row)
        },
        // 执行父组件取消
        onCancel(id, type) {
            this.$emit('onCancel', id, type)
        }
    }
}
</script>

<style lang='scss' scoped>
.card {
    font-size: 26px;
    box-shadow: 0 4px 18px 0 rgba(218, 205, 189, 0.5), inset 2px 4px 0px 0px #fff;

    .title {
        margin-bottom: 20px;
        color: #888888;
    }

    background: #fff;
    margin: 10px 0 20px 0;
    padding: 25px 30px;
    border-radius: 20px;
}

.fr-title {
    font-size: 36px;
    color: #212121;
}

.form-area {
    color: #212121;
    padding-top: 16px;

    .row {
        display: flex;
        align-items: center;
        font-size: 28px;
        line-height: 80px;
        border-bottom: 2px solid #f5f5f5;

        .label {
            color: #898989;
        }

        .download {}

        .dif {
            color: #8b6841;
        }
    }
}

.btn-box {
    width: 100%;
    text-align: center;
    position: relative;

    .footer-btn {
        font-size: 26px;
        height: 66px;
        width: 40%;
        left: 50%;
        margin-left: -22%;
        color: #fff;
        background-image: url('../../../assets/images/newImg/button-homex.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;

        /** 立即申请 样式 */
        span {
            font-size: 26px;
            font-family: none;
            width: 200px;
            position: absolute;
            top: 14px;
            left: 50%;
            margin-left: -100px;
        }
    }
}

.err-text {
    color: rgb(255, 72, 72);
}

.btn-inline {
    display: inline-block;
    margin-top: 30px;
    width: 50%;

    .footer-btn-submit {
        font-size: 26px;
        height: 66px;
        width: 70%;
        left: 30%;
        margin-left: -22%;
        color: #fff;
        background-image: url('../../../assets/images/newImg/button-homex.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;

        /** 立即申请 样式 */
        span {
            font-size: 26px;
            font-family: none;
            width: 200px;
            position: absolute;
            top: 14px;
            left: 50%;
            margin-left: -100px;
        }

    }

    .footer-btn-cel {
        font-size: 26px;
        height: 66px;
        width: 70%;
        left: 30%;
        margin-left: -22%;
        color: #000;
        border: #b9b9b9 1px solid;
        border-radius: 50px;
        background: #fff;
        position: relative;

        /** 立即申请 样式 */
        span {
            font-size: 26px;
            font-family: none;
            width: 200px;
            position: absolute;
            top: 14px;
            left: 50%;
            margin-left: -100px;
        }

    }
}
</style>