<template>
	<div id="app" class="common user-bac-one">
		<keep-alive>
            <!-- 添加了key 导致路由内添加keepAlive失效 -->
			<router-view :key="key" v-wechat-title="$route.meta.title"></router-view>
		</keep-alive>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
import { getInfo } from "@/api/home/index"
import storage from 'store'
export default {
	name: 'App',
	components: {
	},
	computed: {
        ...mapGetters(['userLoginType', 'token', 'goLogin']),
		key() {
            return this.$route.path + Math.random();
        },
    },
    data() {
        return {
            tokens: '',
        }
    },
    created () {
        // ------------------------------------------------------------
        let ua = navigator.userAgent.toLowerCase()
        let user = navigator.userAgent
        // 是否微信打开
        let isWeiXin = ua.indexOf('micromessenger') != -1
        // 是否开发环境
        let isDev = null
        let hostName = window.location.hostname
        let host = "localhost"
        let iptdev = "196.18.10.11"
        let iptest = "10.0.48.5"
        let dev = "xcxdev"
        let test = "xcxtest"
        let ysc = "xcxysc"
        if ((hostName.indexOf(host) != -1) || (hostName.indexOf(iptdev) != -1) || (hostName.indexOf(iptest) != -1) || (hostName.indexOf(dev) != -1) || (hostName.indexOf(test) != -1) || (hostName.indexOf(ysc) != -1)) {
            isDev = true
        } else {
            isDev = false
        }
        // 判断安卓和ios
        var isAndroid = user.indexOf("Android") > -1 || user.indexOf("Adr") > -1;
        if (isAndroid) {
            if (!isWeiXin && !isDev) {
                window.open("https://open.weixin.qq.com/connect/oauth2/authorize?appid=888")
            }
        } else {
            if (!isWeiXin && !isDev) {
                window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=888"
            }
        }
        // --------------------------------------------------------------
        this.tokens = storage.get('ACCESS_TOKEN')
        if ((this.token != '' && this.token != null && this.token != undefined) || (this.tokens != undefined && this.tokens != '')) {
            this.$store.dispatch('setKJD', true)
            storage.set('setKJD', true)
        } else {
            storage.set('setKJD', true)
        }
        if (this.tokens != undefined && this.tokens != '') {
            getInfo().then(res => {
                if (res.code == 200) {
                    // 缓存当前帐号信息
                    storage.set('setUserType', res.data.ysOpenUser.userType)
                    this.$store.dispatch('setUserType', res.data.ysOpenUser.userType)
                    this.$store.dispatch('setPhoneNumber', res.data.ysOpenUser.phoneNumber)
                    this.$store.dispatch('setRealName', res.data.ysOpenUser.realName)
                    this.$store.dispatch('setComName', res.data.ysOpenUser.userName)
                    storage.set('REAL_NAME', res.data.ysOpenUser.realName)
                }
            })
        }
    },
    mounted() {
        // 去登录按钮  刷新后还存在未登录的情况重置显示 按钮 赋值
        if ((this.token != '' && this.token != null && this.token != undefined) || (this.tokens != undefined && this.tokens != '')) {
            this.$store.dispatch('setGoLogin', '1')
        } else {
            this.$store.dispatch('setGoLogin', '0')
        }
    },
    methods: {
    },
}
</script>

<style lang="scss" scope>
</style>
