import request from "../../request/index"

/**
 * 校验已存在申请
 * @param { Object } data 
 * @returns 
 */
 export function verifyDirectStatus () {
    return request({
        url: '/open/direct/verifyStatus',
        method: 'get'
    })
}

/**
 * 区域直保个人提交
 * @param { Object } data 
 * @returns 
 */
export function directSaveP (data) {
    return request({
        url: '/open/direct/saveP',
        method: 'post',
        data
    })
}

/**
 * 区域直保企业提交
 * @param { Object } data 
 * @returns 
 */
 export function directSaveC (data) {
    return request({
        url: '/open/direct/saveC',
        method: 'post',
        data
    })
}

/**
 * 政府审核列表待审核
 * @param { Object } data 
 * @returns 
 */
 export function govListUn (data) {
    return request({
        url: '/platform/direct/governmentList/0',
        method: 'get',
        params: data

    })
}

/**
 * 政府审核列表已审核
 * @param { Object } data 
 * @returns 
 */
 export function govListYes (data) {
    return request({
        url: '/platform/direct/governmentList/1',
        method: 'get',
        params: data

    })
}

/**
 * 政府审核
 * @param { Object } data 
 * @returns 
 */
 export function govAudit (id, type, status, opinion) {
    return request({
        url: '/platform/direct/governmentCheck',
        method: 'post',
        params: {id, type, status, opinion}

    })
}

/**
 * 审核人员获取详情
 * @param { Object } data 
 * @returns 
 */
 export function getDetail (id, type) {
    return request({
        url: '/platform/direct/getDetail',
        method: 'get',
        params: {id:id, type:type}

    })
}


/**
 * 银行查看待查看列表
 * @param { Object } data 
 * @returns 
 */
 export function getBankCheckListUn (data) {
    return request({
        url: '/platform/direct/bankCheckList/0',
        method: 'get',
        params: data

    })
}

/**
 * 银行查看已查看列表
 * @param { Object } data 
 * @returns 
 */
 export function getBankCheckListYes (data) {
    return request({
        url: '/platform/direct/bankCheckList/1',
        method: 'get',
        params: data

    })
}

/**
 * 银行查看
 * @param { Object } data 
 * @returns 
 */
 export function bankRead (data) {
    return request({
        url: '/platform/direct/bankRead',
        method: 'post',
        data
    })
}
/**
 * 政府拒绝
 * @param { Object } data 
 * @returns 
 */
 export function refuse (data) {
    return request({
        url: '/platform/direct/businessCheck',
        method: 'post',
        data
    })
}