
/** 个人 手机号 修改 路由 */
// 个人 手机号可用
// export const prePhoneYesLogin = {
//     name: 'prePhoneYesLogin',
//     path: '/user/updataPhone/prePhoneYesLogin',
//     meta:{
//         title:'修改手机号',
//         keepAlive: true
//     },
//     component: () => import ('@/pages/user/updataPhone/prePhoneYesLogin.vue'),
// }
// 个人 手机号不可用
export const prePhoneNoLogin = {
    name: 'prePhoneNoLogin',
    path: '/user/updataPhone/prePhoneNoLogin',
    meta:{
        title:'修改手机号',
        keepAlive: true
    },
    component: () => import ('@/pages/user/updataPhone/prePhoneNoLogin.vue'),
}
// 人脸识别
export const phoneFaceLogin = {
    name: 'phoneFaceLogin',
    path: '/user/updataPhone/child/phoneFaceLogin',
    meta:{
        title:'修改手机号',
        keepAlive: true
    },
    component: () => import ('@/pages/user/updataPhone/child/phoneFaceLogin.vue'),
}
// 新旧手机号修改
export const editPhoneLogin = {
    name: 'editPhoneLogin',
    path: '/user/updataPhone/editPhoneLogin',
    meta:{
        title:'修改手机号',
        keepAlive: true
    },
    component: () => import ('@/pages/user/updataPhone/editPhoneLogin.vue'),
}


/** 企业 手机号 修改 路由 */
// 企业 手机号可用
// export const comEditPhoneYesLogin = {
//     name: 'comEditPhoneYesLogin',
//     path: '/user/updataPhone/comEditPhoneYesLogin',
//     meta:{
//         title:'修改手机号',
//         keepAlive: true
//     },
//     component: () => import ('@/pages/user/updataPhone/comEditPhoneYesLogin.vue'),
// }
// 企业 手机号不可用
export const comEditPhoneNoLogin = {
    name: 'comEditPhoneNoLogin',
    path: '/user/updataPhone/comEditPhoneNoLogin',
    meta:{
        title:'修改手机号',
        keepAlive: true
    },
    component: () => import ('@/pages/user/updataPhone/comEditPhoneNoLogin.vue'),
}
// 企业人脸识别 营业执照识别 list展示
export const comPhoneListLogin = {
    name: 'comPhoneListLogin',
    path: '/user/updataPhone/child/comPhoneListLogin',
    meta:{
        title:'修改手机号',
        keepAlive: true
    },
    component: () => import ('@/pages/user/updataPhone/child/comPhoneListLogin.vue'),
}
// 企业人脸识别 营业执照识别 list展示
export const conLicenseUplod = {
    name: 'conLicenseUplod',
    path: '/user/updataPhone/child/conLicenseUplod',
    meta:{
        title:'修改手机号',
        keepAlive: true
    },
    component: () => import ('@/pages/user/updataPhone/child/conLicenseUplod.vue'),
}