<!--
* @Description: 客户经理个人中心
* @Author: zhaogeng
* @CreateDate: 2024/03/13 16:40:00
* @FileName: mine.vue
!-->
<template>
   <div class="cusman-bac-oner">

    <div style="text-align: center;">
        <img src="../../assets/images/cusminer.png" class="topimg" @click="infoEdit()" style="width: 70px;height: 70px">
    </div>
    <div class="name">{{ info.managerName }}</div>
    <div class="tag">客户经理</div>
    <div class="cardForm" style="width:92%;height:150px;margin-left:4%;margin-top:3%;padding: 10px 15px;box-shadow:0px 2px 10px 0px rgba(218,205,189,0.5),inset 1px 2px 0px 0px #FFFFFF">
        <p class="card-title">我负责的业务</p>
        <div class="item-box">
            <div class="item-left-img" @click="onGoIng(0)">
                <div class="item-left-img-in">
                    <img src="../../assets/images/yw-shenqing.png" style="width:46px;height:46px">
                    <span class="item-text">申请中</span>
                </div>
                <div class="num num-ing">{{ cusunList }}</div>
            </div>
            <div class="item-line">

            </div>
            <div class="item-right-img" @click="onGoIng(1)">
                <div class="item-right-img-in">
                    <img src="../../assets/images/yw-wancheng.png" style="width:46px;height:46px">
                    <span class="item-text">已完成</span>
                </div>
                <div class="num num-success">{{ cusaccList }}</div>
            </div>
        </div>
    </div>
    <div class="cardForm" style="width:92%;margin-left:4%;margin-top:3%">
              <div class="footer ">
                <ul class="ul">
                    <li class="li" @click="invite()">
                        <img class="img-icon" src="../../assets/images/cus-erweima.png" alt="">
                        邀请客户
                        <van-icon name="arrow" class="icon" color="#898989" />
                    </li>
                    <li class="li" @click="onWePage()">
                        <img class="img-icon" src="../../assets/images/aboutUs-icon.png" alt="">
                        关于我们
                        <van-icon name="arrow" class="icon" color="#898989" />
                    </li>
                    <li class="li"  @click="onTel()">
                        <img class="img-icon" src="../../assets/images/aboutUs-phone.png" alt="">
                        <span style="margin-left:5px">客服电话</span><span style="margin-left: 77px;font-size:14px;color: #999;">工作日 9:00-18:00</span>
                        <van-icon name="arrow" class="icon" color="#898989" />
                    </li>
                </ul>
              </div>
            </div>
        <!-- 客服弹框 -->
        <van-popup v-model="popupShow" round position="bottom" :style="{ height: '15%' }">
                <van-list>
                    <van-cell @click="onTelPhone('0531-82670774')" style="padding: 20px;text-align: center;color:#3399FF;" title="0531-82670774" />
                </van-list>
            </van-popup>
   </div>
</template>

<script>
import { 
    cusInfo,getDoing,getCompleted // 个人信息
} from "@/api/cusManagerlogin/index"
export default {
    // 组件注册
    components: {
    },
    data () {
        return {
            popupShow: false,
            info:{},
            cusunList:0,
            cusaccList:0,
        }
    },
    created() {
        this.getListNum()
        cusInfo().then(res => {
            let data = res.data
            console.log(data)
            this.info = data
        })
    },
    mounted () {},
    methods: {
        getListNum() {
            getDoing().then(res => {
                this.cusunList = res.data.total
            })
            getCompleted().then(res => {
                this.cusaccList = res.data.total
            })
        },
        /**
         * 跳转我的业务
         * @param {Number} type 进入0-'申请中'或 1-'已完成'
         */
        onGoIng(type) {
            this.$router.push({
                path: '/cusManagerMine/task',
                query: {active:type}
            })
        },
        infoEdit(){
            this.$router.push({path: '/cusManagerinfo'})
        },
        //我的二维码
        invite(){
            this.$router.push({path: '/invite'})
        },
        // 跳转至关于我们
        onWePage() {
            this.$router.push('/wepage/index')
        },
        onTelPhone(phone) {
            window.location.href = `tel:${phone}`
            this.popupShow = false
        },
        // 客服电话
        onTel() {
            this.popupShow = true
        },
    }
}
</script>

<style lang='scss' scoped>
    .cusman-bac-oner {
    // background: #fff;
    background-image: url('../../assets/images/cusmanger.png');
    width: 100%;
    height: 100%;
    padding-top: 100px;
    background-repeat: no-repeat;
    background-size: cover;

}
.setting{
    width: 70px;
    height: 70px;
    float:right;
    margin-top: 1px;
    margin-right: 30px;
}
.user-bac-one {
    background-image: none
}
.name{
    width: 100%;
    //height: 24px;
    font-weight: 500;
    font-size: 38px;
    color: #212121;
    margin-top: 32px;
    text-align: center;
    padding:0 60px;
}
.tag{
    width: 160px;
    height: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 26px;
    color: #8B6841;
    background: #EFE8DC;
    border:1px solid rgba(255,255,255,0.5);
    border-radius: 25px;;
    margin-top: 20px;
    margin-left:297px;
    padding-top:2px;
}
.cardForm{
    width: 100%;
    height: 50%;
    margin-top:25%;
    border: 1px;
    border-radius:20px;
    box-shadow: 0px -6px 4px 0px rgba(218,205,189,0.2);
    background-color: white;
    overflow: hidden;
}
.footer{
    padding: 20px 40px;
    .ul{
        margin: 0;
        padding: 0;
        .li{
            padding: 20px 0;
            font-size: 30px;
            line-height: 30px;
            margin: 20px 0 0 0;
            border-bottom: 1px solid rgb(225, 225, 225);
            .img-icon{
                width: 50px;
                height: 50px;
                margin-right: 10px;
                vertical-align: middle;
            }
            .left-icon{
                margin-right: 40px;
            }
            .icon{
                float: right;
                font-weight: bold;
            }
        }
    }
}
.card-title{
    margin: 0;
    font-size: 28px;
    font-weight: 400;
}
.item-box{
    width: 100%;
    display: flex;
    height: 90%;
    justify-content:space-around;
    align-items: center;
    padding: 0 30px;
}
.item-left-img{
    position:relative;
    padding: 20px 30px;
}
.item-left-img-in{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.item-line{
    width: 1px;
    height: 70%;
    border-right: 4px solid rgb(237, 237, 237);
}
.item-right-img{
    position:relative;
    padding: 20px 30px;
}
.item-right-img-in{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.item-text{
    margin-top: 20px;
    font-size: 28px;;
}
.topimg{
    margin-top: 89px;
}
.num{
    width: 42px;
    height: 42px;
    font-size: 22px;
    text-align: center;
    line-height: 43px;
    border-radius: 21px;
    background: #d10909;
    color: #fff;
    position: absolute;
    top: 0;
    right: -10px;
}
.num-success{
    background: #03ac3b;
}
</style>