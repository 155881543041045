import Vue from 'vue'
import App from './App.vue'
// 全局样式
import 'vant/lib/index.css';
import "vue-easytable/libs/theme-default/index.css";
import './assets/style/variable.scss'
import './assets/iconfont/iconfont.css'
import './assets/style/index.scss'
// 引入字体
import "@/assets/text/text.css"
import "@/assets/text/title.css"
import "@/assets/text/remark.css"
// 导入vant
// ,{ Locale }
import Vant from 'vant'
// import zhCN from 'vant/es/locale/lang/zh-CN';
// 导入路由
import router from './router/index'
// 导入vuex
import store from './store/index'
// 移动端适配
import 'lib-flexible'
// 引入自定义容器
import card from "./components/Reefresh"
import moment from 'moment'
import VueWechatTitle from 'vue-wechat-title'
import "./permission"

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole);
//注册全局容器
Vue.component('card', card)
Vue.config.productionTip = false

Vue.prototype.$moment = moment

Vue.use(Vant)
// Locale.use('zh-CN', zhCN)


Vue.use(VueWechatTitle);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
