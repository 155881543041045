import pirText from '../../pages/icbc/agreement/pirText.vue'
import preText from '../../pages/icbc/agreement/preText.vue'
import userText from '../../pages/icbc/agreement/userText.vue'

export const icbc = {
    name: 'icbcEdit',
    path: '/home/edit/icbc',
    meta:{
        title:'工商银行业务',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/icbc/edit.vue'),
}

export const icbcCom = {
    name: 'icbcComEdit',
    path: '/home/comEdit/icbc',
    meta:{
        title:'工商银行业务',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/icbc/comEdit.vue'),
}


export const icbcPir = {
    name: 'icbcPir',
    path: '/icbc/icbcPir',
    meta:{
        title:'企业信息授权书',
        keepAlive: true
    },
    component: pirText
}

export const icbcPre = {
    name: 'icbcPre',
    path: '/icbc/icbcPre',
    meta:{
        title:'个人信息授权书',
        keepAlive: true
    },
    component: preText
}

export const icbcUser = {
    name: 'icbcUser',
    path: '/icbc/icbcUser',
    meta:{
        title:'敏感个人信息授权书',
        keepAlive: true
    },
    component: userText
}