<!--
* @Description: 专项产品
* @Author: zhaogeng
* @CreateDate: 2024/03/14 15:10:30
* @FileName: Product.vue
!-->
<template>
    <div v-if="views == '1'" class="cardForm-yw-box">
        <div class="cardForm-yw">
            <div class="top">
                <div class="topTitle">{{ product.name }}</div>
                
            </div>
            <div class="mid">
                <div v-show="infoList[0]" class="midText1">{{ infoList[0] }}</div>
                <div v-show="infoList[1]" class="midText2">{{ infoList[1] }}</div>
            </div>
            <div class="content-box" style="display:flex;margin-left:10px">
                <div class="cont-left">
                    <div v-show="infoList[2]" class="btm-text1">{{ infoList[2] }}</div>
                    <div v-show="infoList[3]" class="btm-text2">{{ infoList[3] }}</div>
                </div>
                <div class="cont-right">
                    <div v-show="infoList[4]" class="btm-text3">{{ infoList[4] }}</div>
                    <div v-show="infoList[5]" class="btm-text4">{{ infoList[5] }}</div>
                </div>
            </div>
            <div class="content-box" style="display:flex;margin-left:10px;margin-bottom:10px">
                <div class="cont-left">
                    <div v-show="infoList[6]" class="last1">{{ infoList[6] }}</div>
                </div>
                <div class="cont-right">
                    <div v-show="infoList[7]" class="last2">{{ infoList[7] }}</div>
                </div>
            </div>
            <div class="btns">
                <!-- <div  style="display: inline-block;"> -->
                    <van-button v-for="(items, index) in buttonList" :key="index" @click.stop="turnHot(index, product)" class="topButton1">
                        {{ items }}
                    </van-button>
                <!-- </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { buttonShow } from "@/api/home/homer"
import { getInfo } from "@/api/home/index"
import storage from 'store'
export default {
    props: {
        data: {
            type: Object,
        },
    },
    watch: {
        data: {
            immediate: true,
            handler(newValue, oldValue) {
                this.product = newValue
                this.views = '1'
                this.infoList = newValue.productDetails.split(';')
                if (newValue.productFunction == '') {
                    this.buttonList = []
                } else {
                    this.buttonList = newValue.productFunction.split('，')
                }
                if (storage.get('ACCESS_TOKEN')) {
                    this.getButton(newValue)
                }
            }
        },
    },
    // 组件注册
    computed: {
        ...mapGetters(['userTypes', 'phone', 'token', 'realName', 'goLogin'])
    },
    data() {
        return {
            product: null,
            infoList: [],
            buttonList: [],
            views: '',
            isAvailable: '',
            tokenType: '',
            type: null,
            page: {
                pageNum: 1,
                pageSize: 10,
            },
            num: 0,
            isAvailable: null,
            realNameType: '', // 是否认证
        }
    },
    created() {
        this.num = this.$route.query.num
        if (storage.get('ACCESS_TOKEN')) {
            getInfo().then(res => {
                if (res.code == 200) {
                    // 缓存当前帐号信息
                    storage.set('setUserType', res.data.ysOpenUser.userType)
                    this.$store.dispatch('setUserType', res.data.ysOpenUser.userType)
                    this.$store.dispatch('setPhoneNumber', res.data.ysOpenUser.phoneNumber)
                    this.$store.dispatch('setRealName', res.data.ysOpenUser.realName)
                    this.$store.dispatch('setComName', res.data.ysOpenUser.userName)
                    // 1个人 2企业
                    // 存储当前帐号类型
                    this.type = this.userTypes
                    this.realNameType = this.realName
                    this.tokenType = this.goLogin
                }
            })
        }
    },
    methods: {
        getButton(item) {
            buttonShow(item.id).then(res => {
                if (res.code == 200) {
                    let data = res.data
                    item.bhIsAvailable = data.bhIsAvailable
                    item.bqIsAvailable = data.bqIsAvailable
                }
            })
        },
        /**
         * 科技贷按钮 go pages
         * @param {Number} index 下标
         * @param {Object} item 当前数据
         */
         turnHot(index, item) {
            if (!(storage.get('ACCESS_TOKEN'))) {
                this.$dialog.confirm({
                    confirmButtonText: '前往登录',
                    confirmButtonColor: '#1989fa',
                    message: '请先登录您的账号',
                }).then(() => {
                    this.$router.push({ name: 'login' })
                })
                return false
            }
            if (this.realNameType != '1') {
                this.$dialog.confirm({
                    confirmButtonText: '前往实名认证',
                    confirmButtonColor: '#1989fa',
                    message: '当前帐号没有实名认证，请完成实名认证后在进行申请',
                }).then(() => {
                    // 进入对应账号的认证页面
                    if (this.type == '2') {
                        this.$router.push({ name: 'comHome' })
                    } else {
                        this.$router.push({ name: 'preHome' })
                    }
                })
                return false
            }
            // 科技贷跳转
            if (item.jumpPath === '/ldScience') {
                if (storage.get('setUserType') == '1') {
                    this.$dialog({
                        message: '该产品仅适用于企业用户，个人用户无权申请！',
                    })
                    return false
                }
                // 0 -> 保前
                if (index == 0) {
                    switch (item.bqIsAvailable) {
                        case '0':
                            this.$dialog.confirm({
                                confirmButtonText: '同意并继续',
                                cancelButtonText: '不同意',
                                title: "用户隐私保护提示",
                                confirmButtonColor: '#1989fa',
                                message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。获取您的手机号和个人信息，我们将按照法律法规要求向您提供服务，保护您的个人信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息》</span>、<span style="color: #000;font-weight: 600;">《授权企业信息》</span>、<span style="color: #000;font-weight: 600;">《敏感个人信息授权》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人信息的保护措施`,
                            }).then(() => {
                                // 跳转保前页面
                                this.$router.push({
                                    path: '/ldScience/edit',
                                    query: { prodId: item.id }
                                })
                            })
                            break;
                        case '1':
                            this.$dialog.confirm({
                                confirmButtonText: '确定',
                                confirmButtonColor: '#1989fa',
                                message: '当前无权限申请产品，请联系当地担保机构！',
                            })
                            break;
                        case '2':
                            this.$dialog.confirm({
                                confirmButtonText: '查看申请记录',
                                cancelButtonText: '取消',
                                confirmButtonColor: '#1989fa',
                                message: '该产品存在申请中的业务',
                            }).then(() => {
                                // 查看历史记录
                                this.$router.push({ name: 'task' })
                            })
                            break;
                    }
                }
                // 1 -> 保后
                if (index == 1) {
                    switch (item.bhIsAvailable) {
                        case '0':
                            this.$dialog.confirm({
                                confirmButtonText: '同意并继续',
                                cancelButtonText: '不同意',
                                title: "用户隐私保护提示",
                                confirmButtonColor: '#1989fa',
                                message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。获取您的手机号和个人信息，我们将按照法律法规要求向您提供服务，保护您的个人信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息》</span>、<span style="color: #000;font-weight: 600;">《授权企业信息》</span>、<span style="color: #000;font-weight: 600;">《敏感个人信息授权》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人信息的保护措施`,
                            }).then(() => {
                                // 跳转保前页面
                                this.$router.push({
                                    path: '/ldAfter/edit',
                                    query: { prodId: item.id }
                                })
                            })
                            break;
                        case '1':
                            this.$dialog({
                                message: '"您的担保业务申请流程尚未完成，请点击 “快速申请” 按钮完成业务申请流程！"',
                            })
                            break;
                        case '2':
                            this.$dialog.confirm({
                                confirmButtonText: '查看申请记录',
                                cancelButtonText: '取消',
                                confirmButtonColor: '#1989fa',
                                message: '该产品存在申请中的业务',
                            }).then(() => {
                                // 查看历史记录
                                this.$router.push({ name: 'task' })
                            })
                            break;
                    }
                }
                return false
            }
            if (item.jumpPath === '/tbb') {
                if (storage.get('setUserType') == '1') {
                    this.$dialog({
                        message: '该产品仅适用于企业用户，个人用户无权申请！',
                    })
                    return false
                }
                if (index == 0) {
                    this.$router.push({
                        path: '/takeBid/bidApplicate',
                    })
                } else {
                    this.$router.push({
                        path: '/takeBid/letterCheck',
                    })
                }
            }
        },
    }
}
</script>

<style lang='scss' scoped>
.van-button--normal {
    padding: 0;
}
.cardForm-yw-box{
    padding-top: 30px;
}
.cardForm-yw {
    width: 92%;
    min-height: 400px;
    margin: 0 auto;
    border: 3px solid #E6D2BC;
    border-radius: 20px;
    box-shadow: 0px 2px 10px 0px rgba(237, 226, 212, 0.7), inset 1px 2px 0px 0px #FFFFFF;
    background-color: white;
    backdrop-filter: blur(6px);
    // padding-bottom:120px;
}

.top {
    width: 100%;
    padding-top: 20px;
    height: 70px;
    overflow: hidden;
    position: relative;
}

.topTitle {
    position: absolute;
    top:24px;
    left: 30px;
    font-weight: 600;
    font-size: 35px;
    color: #212121;
}

.btns {
    display: flex;
    justify-content: space-around;
    .topButton1 {
        border: 1px solid #E6D2BC;
        border-radius: 40px;
        color: #fff;
        text-align: center;
        height: 60px;
        font-weight: 400;
        font-size: 30px;
        line-height: 50px;
        text-align: left;
        padding: 0px 65px;
        background: #BEA588;
    }
}


.mid {
    display: flex
}

.midText1 {
    text-align: center;
    //border-radius: 40px;
    // display: inline-block;
    width: 250px;
    height: 50px;
    font-weight: 300;
    font-size: 24px;
    line-height: 50px;
    margin-left: 20px;
    margin-top: 26px;
    color: #898989;
    background: #F2F2F2;
    padding-top: 2px;
}

.midText2 {
    text-align: center;
   // border-radius: 40px;
    // display: inline-block;
    width: 250px;
    height: 50px;
    font-weight: 300;
    font-size: 24px;
    line-height: 50px;
    margin-left: 19px;
    // padding-top: -50px;
    margin-top: 26px;
    color: #898989;
    padding-top: 2px;
    background: #F2F2F2;
}

.content-box {
    display: flex;
    justify-content: space-between;
    padding: 0 140px 0 20px;
    margin-top: 20px;
}

.cont-left {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
}

.cont-right {
    display: flex;
    justify-content: left;
    width:150px;
}

.btm-text1 {
    text-align: center;
    border-radius: 16px;
    font-weight: 600;
    font-size: 34px;
    line-height: 50px;
    color: #D10909;
}

.btm-text2 {
    text-align: center;
    border-radius: 16px;
    font-weight: 600;
    font-size: 32px;
    line-height: 50px;
    color: #898989;
    margin-left: 10px;
}

.btm-text3 {
    text-align: center;
    border-radius: 16px;
    font-weight: 600;
    font-size: 34px;
    line-height: 50px;
    color: #D10909;
    //margin-left:-150px;
}

.btm-text4 {
    text-align: center;
    border-radius: 16px;
    font-weight: 600;
    font-size: 32px;
    line-height: 50px;
    color: #898989;
    margin-left: 10px;
}

.last1 {
    text-align: center;
    border-radius: 16px;
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    color: #898989;
}

.last2 {
    text-align: center;
    border-radius: 16px;
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    color: #898989;
}</style>