<!--
* @Description: 保函业务
* @Author: zhaogeng
* @CreateDate: 2024/03/20 09:21:16
* @FileName: noFinancing.vue
!-->
<template>
    <div class="cardForm" style="width:94%;margin-left:3%">
        <div class="card-inline-box" style="overflow:hidden;">
            <div class="topTitle">
                <img src="../../assets/images/product.png" class="topImg">
                <span class="text">专项产品</span>
            </div>
            <div class="component" v-for="(item, index) in productList" :key="index">
                <Product :data="item"></Product>
            </div>
        </div>
    </div>
</template>
<script>
import { getGoosList } from "@/api/home/homer"
import Product from "@/pages/jinrongServe/components/Product.vue"
export default {
    // 组件注册
    components: { Product },
    data() {
        return {
            productList: []
        }
    },
    created() {
        this.getList()
    },
    mounted() { },
    methods: {
        getList() {
            getGoosList({ parentId: this.$route.query.parentId, pageSize: 100, pageNum: 1, miniShow: 1 }).then(res => {
                this.productList = res.rows
                console.log(res.rows)
            })
        }
    }
}
</script>

<style lang='scss' scoped>
.topImg {
    display: inline-block;
    width: 60px;
    height: 60px;
}

.topTitle {
    margin: 20px 0 0 20px;
    display: flex;
    align-items: center;
    .text{
        margin-left: 20px;
        color: #212121;
        font-weight: 600;
        font-size: 35px;
        display: inline-block;
    }
}

.cardForm {
    width: 100%;
    height: 97%;
    margin-top: 5%;
    border: 1px;
    border-radius: 8px 8px 8px 8px;
    box-shadow: 0px -6px 4px 0px rgba(218, 205, 189, 0.2);
    background-color: white;
    justify-content: center;
    align-items: center;
}
.card-inline-box{
    padding-bottom: 30px;
}
</style>