

export const lsViews = {
    name: 'preView',
    path: '/lsBank/PreView',
    meta:{
        title:'保费缴纳',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/task/LsViews/PreView.vue'),
}
export const lsComViews = {
    name: 'comView',
    path: '/lsBank/comView',
    meta:{
        title:'保费缴纳',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/task/LsViews/ComView.vue'),
}


export const lsSuccess = {
    name: 'lsSuccess',
    path: '/lsBank/lsSuccess',
    meta:{
        title:'支付',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/task/LsViews/Success.vue'),
}

export const lsError = {
    name: 'lsError',
    path: '/lsBank/lsError',
    meta:{
        title:'支付',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/task/LsViews/Error.vue'),
}

export const lsPrePush = {
    name: 'lsPrePush',
    path: '/lsBank/lsPrePush',
    meta:{
        title:'支付',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/task/LsViews/amount/prePush.vue'),
}