<!--
* @Description: 我的
* @Author: sunchunchun
* @CreateDate: 2022/08/30 17:32:21
* @LastEditTime: 2022/08/30 17:32:21
* @FileName: mine.vue
!-->
<template>
    <div class="fex-box">
        <!-- box -->
        <div class="box user-bac-one"> 
            <div class="info-bg">
                <!-- hearder box -->
            <div class="header" @click="onPath">
                <!-- 头像 -->
                <div class="right">
                    <img class="img" src="../../assets/images/nologin-headpic.png" alt="img">
                </div>
                <div class="left">
                    <!-- 姓名 -->
                    <p class="name-clas" style="margin: 0;text-align: center" v-if="realName==1">
                        {{ name }}<img class="name-img" src="../../assets//images/name-view.png" alt="">
                    </p>
                    <!-- 手机号 -->
                    <p class="p phone" style="font-size:15px">
                        {{ phoneNum }}
                    </p>
                    <div class="tag-box" v-if="userTypes">
                        <div class="tag-color">
                            <p class="p color" style="padding: 2px;color:#8B6841;" v-if="userTypes==1">个人</p>
                            <p class="p color" style="padding: 2px;color:#8B6841;" v-if="userTypes==2">企业</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 通知 -->
            <div style="margin-left:29%;font-size:14px;font-weight:400;margin-top:-3%;margin-bottom:10px" v-if="realName==0">
                <div style="display:inline-block">您还未实名认证，</div>
                <div style="display:inline-block;width:25%;height:22px;background:rgba(190,165,136,0.2);border-radius: 12px;border: 1px solid rgba(255,255,255,0.5);margin-left:2%;margin-top:2px;"  @click="onReal" mode="link">
                    <span style="margin-left:13%;color:#8B6841;display:inline-block;margin-top:4%">去认证</span>
                    <div style="display:inline-block"><img src="../../assets/images/btn-left.png" style="margin-left:70%;margin-top:58%" alt=""></div>
                </div>
            </div>
            <!-- footer -->
            <div class="cardForm" style="width:92%;height:150px;margin-left:4%;margin-top:0;padding: 10px 15px;box-shadow:0px 2px 10px 0px rgba(218,205,189,0.5),inset 1px 2px 0px 0px #FFFFFF">
                <p class="card-title">我的业务</p>
                <div class="item-box">
                    <div class="item-left-img" @click.stop="onGotask(0)">
                        <div class="item-left-img-in">
                            <img src="../../assets/images/yw-shenqing.png" style="width:46px;height:46px">
                            <span class="item-text">申请中</span>
                        </div>
                        <div class="num num-ing">{{ unList }}</div>
                    </div>
                    <div class="item-line">

                    </div>
                    <div class="item-right-img" @click.stop="onGotask(1)">
                        <div class="item-right-img-in">
                            <img src="../../assets/images/yw-wancheng.png" style="width:46px;height:46px">
                            <span class="item-text">已完成</span>
                        </div>
                        <div class="num num-success">{{ accList }}</div>
                    </div>
                </div>
            </div>
            <div class="cardForm" style="width:92%;margin-left:4%;margin-top:3%">
              <div class="footer ">
                <ul class="ul">
                    <li class="li" @click="onMeCom">
                        <img class="img-icon" src="../../assets/images/aboutUs-company.png" alt="">
                        我的担保公司
                        <van-icon name="arrow" class="icon" color="#595959" />
                    </li>
                    <li class="li" @click="onWePage">
                        <img class="img-icon" src="../../assets/images/aboutUs-icon.png" alt="">
                        关于我们
                        <van-icon name="arrow" class="icon" color="#595959" />
                    </li>
                    <li class="li" @click="onTel()">
                        <img class="img-icon" src="../../assets/images/aboutUs-phone.png" alt="">
                        <span style="margin-left:5px">客服电话</span><span style="margin-left: 77px;font-size:14px;color: #999;">工作日 9:00-18:00</span>
                        <van-icon name="arrow" class="icon" color="#595959" />
                    </li>
                </ul>
              </div>
            </div>
            <!-- 客服弹框 -->
            <van-popup v-model="popupShow" round position="bottom" :style="{ height: '15%' }">
                <van-list>
                    <van-cell @click="onTelPhone('0531-82670774')" style="padding: 20px;text-align: center;color:#BEA588;" title="0531-82670774" />
                </van-list>
            </van-popup>
            <!-- 浮动 问题反馈 -->
            <div class="customer" @click="onFeedback">
                <img src="../../assets/images/customer.png"  alt="" >
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getUnList, getAccList } from "@/api/home/index"
import { setPhoneEncr } from "@/utils/validate"
import { getInfo } from "@/api/home/index"
import { inApplication, completed } from "@/api/feiRong/index";
export default {
    data () {
        return {
            popupShow: false,
            phoneNum: '',
            realName: 0,
            userTypes: null,
            unList: 0,
            accList: 0,
            name: '',
        }
    },
    created() {
        this.getListNum()
        // 个人信息 获取
        getInfo().then(res => {
            // 判断身份证
            if (res.data.ysLegalpersonInformation) {
                let endDate = this.$moment(res.data.ysLegalpersonInformation.periodEnd).format('YYYY-MM-DD HH:mm:ss')
                let newDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
                let flag = this.$moment(endDate).isBefore(newDate)
                if (flag) {
                    this.$dialog.confirm({
                        confirmButtonText: '确认',
                        cancelButtonText: "取消",
                        message: '当前身份证日期过期，请重新上传有效身份证件',
                    }).then(() => {
                        this.$router.push({
                            name: 'overdueList'
                        })
                    }).catch(() => {
                        this.$store.dispatch('Logout').then(() => {
                            window.location.reload()
                        })
                    })
                }
            }
            let data = res.data
            this.phoneNum = setPhoneEncr(data.ysOpenUser.phoneNumber)
            this.userTypes = data.ysOpenUser.userType
            if(data.ysOpenUser.userType == 2 ){
                this.name = data.ysEnterpriseInformation.companyName
            }else{
                this.name = data.ysLegalpersonInformation.name
            }
            this.realName = data.ysOpenUser.realName
        })
    },
    methods: {
        // 获取申请中 已完成 数量
        getListNum() {
            let doningnum1 = 0
            let doningnum2 = 0
            let completenum1 = 0
            let completenum2 = 0
            Promise.all([
                // 初始化时获取已完成数量
                getUnList().then(res => {
                    if (res.code == 200) {
                        doningnum1 = res.data.total
                    }
                }),
                inApplication().then(res => {
                    if (res.code == 200) {
                        doningnum2 = res.data.length
                    }
                })
            ]).then(() => {
                this.unList = doningnum1 + doningnum2
            })
            Promise.all([
                // 初始化时获取已完成数量
                getAccList().then(res => {
                    if (res.code == 200) {
                        completenum1 = res.data.total
                    }
                }),
                completed().then(res => {
                    if (res.code == 200) {
                        completenum2 = res.data.length
                    }
                })
            ]).then(() => {
                this.accList = completenum1 + completenum2
            })

            // getAccList().then(res => {
            //     this.accList = res.data.total
            // })
        },
        onGotask(num) {
            this.$router.push({name: 'task', params: {active: num}})
        },
        onTelPhone(phone) {
            window.location.href = `tel:${phone}`
            this.popupShow = false
        },
        // 客服电话
        onTel() {
            this.popupShow = true
        },
        // 跳转 我的担保公司
        onMeCom() {
            if (this.realName == 0) {
                this.$dialog.confirm({
                    confirmButtonText: '前往实名认证',
                    confirmButtonColor: '#1989fa',
                    message: '当前帐号没有实名认证，请完成实名认证后进行操作',
                }).then(() => {
                    // 进入对应账号的认证页面
                    if (this.userTypes == 2) {
                        this.$router.push({name: 'comHome', query: {content: "企业认证"}})
                    } else {
                        this.$router.push({name: 'preHome', query: {content: "个人认证"}})
                    }
                }).catch(() => {});
            } else {
                this.$router.push('/mecom/index')
            }
        },
        // 跳转至关于我们
        onWePage() {
            this.$router.push('/wepage/index')
        },
        // 基本信息页面
        onPath() {
            // 判断是企业还是个人
            if (this.userTypes == 1) {
                if (this.realName == 1) {
                    this.$router.push({name: 'approveInfo'})
                } else {
                    this.$router.push({name: 'info'})
                }
            } else {
                if (this.realName == 1) {
                    this.$router.push({name: 'comapproveInfo'})
                } else {
                    this.$router.push({name: 'cominfo'})
                }
            }
        },
        // 跳转至认证页面
        onReal() {
            console.log(this.userTypes)
            if (this.userTypes == 2) {
                this.$router.push({name: 'comHome', query: {content: "企业认证"}})
            } else {
                this.$router.push({name: 'preHome', query: {content: "个人认证"}})
            }
        },
        // 跳转至问题反馈页面
        onFeedback() {
            this.$router.push('/feedback')
        },
    }
}
</script>

<style lang='scss' scoped>
.info-bg{
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/cusmanger.png');
    background-repeat:no-repeat;
    background-size: cover;
}
.fex-box{
    position: relative;
}
.box{
    width: 100%;
    height: 100vh;
    .customer{
        height: 150px;
        width:150px;
        right: -12px;
        bottom: 200px;
        z-index: 999;
        position: fixed;
        img{
            width: 100%;
            height: 100%;
        }
        span{
            display: inline-block;
            margin-right: 10px;
        }
    }
    
    .header{
        padding: 190px 40px 30px 40px;
        overflow: hidden;
        .left{
            width: 100%;
            text-align: center;
            p{
                margin: 0;
            }
            .phone{
                font-size: 36px;
                font-weight: bold;
                margin: 10px 0 !important;
            }
            .color{
                color: #BEA588;
            }
        }   
        .right{
            width: 100%;
            text-align: center;
            .img{
                width: 130px;
                height: 130px;
            }
        }
        .tag-box{
            width: 100%;
            text-align: center;
            font-size:26px;
            .tag-color{
                width:100px;
                text-align: center;
                background:rgba(190,165,136,0.2);
                border-radius: 55px;
                border: 1px solid rgba(255,255,255,0.5);
                margin: 0 auto;
            }
        }
    }
    /** footer */
    .footer{
        padding: 20px 40px;
        .ul{
            margin: 0;
            padding: 0;
            .li{
                padding: 20px 0;
                font-size: 30px;
                line-height: 30px;
                margin: 20px 0 0 0;
                border-bottom: 1px solid rgb(225, 225, 225);
                .img-icon{
                    width: 50px;
                    height: 50px;
                    margin-right: 10px;
                    vertical-align: middle;
                }
                .left-icon{
                    margin-right: 40px;
                }
                .icon{
                    float: right;
                    font-weight: bold;
                }
            }
        }
    }
}
.flot-tel{
    width: 100%;
    position: fixed;
    bottom: 150px;
    .tel-icon{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.num{
    width: 42px;
    height: 42px;
    font-size: 22px;
    text-align: center;
    line-height: 43px;
    border-radius: 21px;
    background: #d10909;
    color: #fff;
    position: absolute;
    top: 0;
    right: -10px;
}
.num-success{
    background: #03ac3b;
}
.cardForm{
    width: 100%;
    height: 50%;
    margin-top:25%;
    border: 1px;
    border-radius:20px;
    box-shadow: 0px -6px 4px 0px rgba(218,205,189,0.2);
    background-color: white;
    overflow: hidden;
}

.card-title{
    margin: 0;
    font-size: 30px;
}
.item-box{
    width: 100%;
    display: flex;
    height: 90%;
    justify-content:space-around;
    align-items: center;
    padding: 0 30px;
}
.item-left-img{
    padding: 20px 30px;
    position: relative;
}
.item-left-img-in{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.item-line{
    width: 1px;
    height: 70%;
    border-right: 4px solid rgb(237, 237, 237);
}
.item-right-img{
    padding: 20px 30px;
    position: relative;
}
.item-right-img-in{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.item-text{
    margin-top: 20px;
    font-size: 28px;;
}
.name-clas{
    font-size: 30px;
    margin: 10px 0 !important;
    .name-img{
        width: 38px;
        height: 38px;
        vertical-align: middle;
        margin-left: 10px;
        margin-top: -3px;
    }
}
</style>