const getters = {
    userLoginType: state => state.login.userLoginType,
    models: state => state.home.models,
    token: state => state.login.token,
    perLoginTime: state => state.login.perLoginTime, // 个人登录获取验证码倒计时
    entLoginTime: state => state.login.entLoginTime, // 企业登录获取验证码
    cusmLoginTime: state => state.login.cusmLoginTime, // 企业登录获取验证码
    perRegTime: state => state.login.perRegTime, // 个人注册验证码
    entRegTime: state => state.login.entRegTime, // 企业注册验证码
    dataInfo: state => state.login.dataInfo, // 登陆时的个人信息
    dataReg: state => state.login.dataReg, // 注册时的个人信息
    faceData: state => state.login.faceData, // 认证时ocr识别的身份证信息
    userTypes: state => state.login.userTypes, // 个人信息
    phone: state => state.login.phone, // 个人信息
    realName: state => state.login.realName, // 个人信息
    comName: state => state.login.comName, // 个人信息

    comLices: state => state.login.comLices, // 营业执照
    comCards: state => state.login.comCards, // 身份证
    comFaces: state => state.login.comFaces, // 人脸识别

    preCards: state => state.login.preCards, // 个人身份证识别
    preFaces: state => state.login.preFaces, // 个人人脸识别

    comEditFaces: state => state.login.comEditFaces, // 修改公司名称 人脸识别
    comEditLices: state => state.login.comEditLices, // 修改公司名称 营业执照
    comEditPhone: state => state.login.comEditPhone, // 修改公司登陆手机号


    phoneLices: state => state.login.phoneLices, // 修改公司手机号时的认证状态码
    phoneFaces: state => state.login.phoneFaces, // 修改公司手机号时的认证状态码

    overdueFace: state => state.home.overdueFace, // 重新认证身份信息
    overdueCard: state => state.home.overdueCard, // 重新认证身份信息


    isBig: state => state.home.isBig, // 是否是大屏登录

    
    goLogin: state => state.login.goLogin, // 查看是否登录
    goKjd: state => state.login.goKjd, // kjd


}
export default getters