import axios from 'axios';
import store from '@/store'
import storage from 'store'
import { Notify, Toast } from 'vant';
import router from "@/router/index"
// 创建 axios 实例
const request = axios.create({
    headers: {'Authorization': storage.get('ACCESS_TOKEN') ? `${storage.get('ACCESS_TOKEN')}` : null ,
     'authentication-token': storage.get('ACCESS_TOKEN') ? `${storage.get('ACCESS_TOKEN')}` : null,
                },
    // API 请求的默认前缀
    baseURL: process.env.VUE_APP_BASE_API,
    // baseURL: '',
    timeout: 50000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
    const toast = Toast({
        type: 'loading',
        duration: 0,
        forbidClick: true,
        message: "加载中..."
    });
    toast.clear()
    if (error.response) {
        const data = error.response.data
      // 从 localstorage 获取 token
      const token = storage.get('ACCESS_TOKEN') ? storage.get('ACCESS_TOKEN') : null
      if (error.response.status === 403) {
        Notify({ type: 'danger', message: data.resp_msg });
      }
      // todo 后续需要 跳转到统一登陆页面
      if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
        Notify({ type: 'danger', message: '登录超时，请重新登录！' });
        if (token) {
          if (store.getters.isBig) {
            this.$router.push('/preliminary')
          } else {
            store.dispatch('Logout').then(() => {
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            })
          }
        }
      }
      if (error.response.status === 500) {
        console.log(data,'====')
        Notify({ type: 'danger', message: data.msg || "登陆超时，请重新登录" });
          if (store.getters.isBig) {
            this.$router.push('/preliminary')
          } else {
            store.dispatch('Logout').then(() => {
              setTimeout(() => {
                window.location.reload()
              }, 1000)
          })
          }
      }
    }
    return Promise.reject(error)
}
// request interceptor
request.interceptors.request.use(config => {
    const toast = Toast({
        type: 'loading',
        duration: 0,
        forbidClick: true,
        message: "加载中..."
    });
    const token = storage.get('ACCESS_TOKEN') ? storage.get('ACCESS_TOKEN') : null
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        if (config.headers['authentication-token']) {
            config.headers['authentication-token'] = token
            config.headers['Authorization'] = token
        } else {
            config.headers['authentication-token'] = token
            config.headers['Authorization'] = token
        }
    }
    return config
}, errorHandler)
  
// response interceptor
request.interceptors.response.use(async (response) => {
  if (response.data.code === 3003 || response.data.code === 401) {
        Notify({ type: 'danger', message: response.data.msg || "登陆超时，请重新登录" });
        store.dispatch('Logout').then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 1000)
        })
    }
    const toast = Toast({
        type: 'loading',
        duration: 0,
        forbidClick: true,
        message: "加载中..."
    });
    toast.clear()
    if (!response.config.url.includes('workflow') || response.config.url.includes('getButtonAndTabs')) {
      return response.data
    }
    // alert('业务正在处理')
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(response.data)
      }, 2000)
    })
    return promise
}, errorHandler)

export default request
