import pirText from '../../pages/rcc/agreement/pirText.vue'
import preText from '../../pages/rcc/agreement/preText.vue'
import userText from '../../pages/rcc/agreement/userText.vue'

export const rcc = {
    name: 'editRcc',
    path: '/home/edit/rcc',
    meta:{
        title:'省农信联社业务',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/rcc/edit.vue'),
}

export const rccCom = {
    name: 'comEditRcc',
    path: '/home/comEdit/rcc',
    meta:{
        title:'省农信联社业务',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/rcc/comEdit.vue'),
}
export const rccPir = {
    name: 'rccPir',
    path: '/rccBank/rccPir',
    meta:{
        title:'企业信息授权书',
        keepAlive: true
    },
    component: pirText
}

export const rccPre = {
    name: 'rccPre',
    path: '/rccBank/rccPre',
    meta:{
        title:'个人信息授权书',
        keepAlive: true
    },
    component: preText
}

export const rccUser = {
    name: 'rccUser',
    path: '/rccBank/rccUser',
    meta:{
        title:'敏感个人信息授权书',
        keepAlive: true
    },
    component: userText
}