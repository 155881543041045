import request from "../../request/index"

/**
 * 登录
 * @param { Object } data 
 * @returns 
 */
export function cusLogin (data) {
    return request({
        url: '/open/khjl/sms/login',
        method: 'post',
        params: data,
        headers: {
            'authentication-token': ''
        }
    })
}

/**
 * 获取客户经理info
 * @param { Object } data 
 * @returns 
 */
export function cusInfo (data) {
    return request({
        url: '/open/khjl/getinfo',
        method: 'get',
        params: data,
        headers: {
            'authentication-token': ''
        }
    })
}
/**
 * 获取验证码
 * @param { Object } data 
 * @returns 
 */
export function getPhoneNum (data) {
    return request({
        url: '/open/send/code',
        method: 'get',
        params: data,
        headers: {
            'authentication-token': ''
        }
    })
}
/**
 * 判断当前账号是否存在
 * @param { Object } data 
 * @returns 
 */
export function cusIsExist (data) {
    return request({
        url: '/open/user/customerManager/isExist',
        method: 'get',
        params: data,
        headers: {
            'authentication-token': ''
        }
    })
}


/**
 * 查询已完成list
 * @param { Object } data 
 * @returns 
 */
export function getDoing (data) {
    return request({
        url: '/open/khjl/getDoing',
        method: 'get',
        params: data
    })
}
/**
 * 查询已完成list
 * @param { Object } data 
 * @returns 
 */
export function getCompleted (data) {
    return request({
        url: '/open/khjl/getCompleted',
        method: 'get',
        params: data
    })
}


/**
 * 获取客户经理可邀请的列表
 * @param { Object } data 
 * @returns 
 */
export function getGoodsList (data) {
    return request({
        url: '/open/khjl/getGoodsList',
        method: 'get',
        params: data
    })
}

/**
 * 根据id获取二维码
 * @param { Object } data 
 * @returns 
 */
export function generateQRcode (data) {
    return request({
        url: '/open/khjl/generateQRcode',
        method: 'post',
        params: data
    })
}

/**
 * 根据id获取二维码
 * @param { Object } data 
 * @returns 
 */
export function judgeCodeValidity (data) {
    return request({
        url: '/open/invitedgoods/judgeCodeValidity',
        method: 'get',
        params: data
    })
}