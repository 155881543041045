<!--
* @Description: 首页改版
* @Author: zhaogeng
* @CreateDate: 2024/03/12 19:39:59
* @FileName: homer.vue
!-->
<template >
    <div class="cusman-bac-oner">
        <img class="titleimg" src="../../assets/images/hometitle.png">
        <div class="applyfor">
            <p class="card-title">快速申请</p>
            <div class="item-box">
                <div class="item-left-img" @click="guarantee()">
                    <div class="item-left-img-in">
                        <img src="../../assets/images/danbaoyushen.png" style="width:46px;height:46px">
                        <span class="item-text">{{ guaranteeList.name }}</span>
                        <span class="item-tag">{{ guaranteeList.descInfo }}</span>
                    </div>
                </div>
                <div class="item-line">
                </div>
                <div class="item-right-img" @click="applyfor()">
                    <div class="item-right-img-in">
                        <img src="../../assets/images/danbaoshenqing.png" style="width:46px;height:46px">
                        <span class="item-text">{{ applyforList.name }}</span>
                        <span class="item-tag">{{ applyforList.descInfo }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="hot">
            <div class="hotTitle">热点产品</div>
            <div v-for="(item, index) in productList" :key="index">
                <div :class="item.jumpPath == '/ldScience' ? 'kjd-bg' : 'tbb-bg'">
                    <div class="hotone">
                        <div class="hotoneTitle">{{ item.name }}</div>
                        <div class="hotoneTag">{{ item.descInfo }}</div>
                        <div style="display:flex">
                            <div v-for="(items, indexer) in item.buttonList" :key="indexer" style="margin-right:6%">
                                <van-button @click="goLuDanPage(indexer, item)" class="hotoneButton"
                                    v-if="items.path == '/ldScience'" icon-position="right">{{ items.name }}<span
                                        class="gt">&gt;</span>
                                </van-button>
                                <van-button @click="goLuDanPage(indexer, item)" class="hotoneButton"
                                    style="background: rgba(230,188,188, .3; color: #8B4141"
                                    v-if="(items.path == '/tbb')" icon-position="right">{{ items.name }}<span
                                        class="gt">&gt;</span>
                                </van-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="serve">
            <div class="serveTitle">综合金融服务</div>
            <div class="boxer">
                <div class="boxer1">
                    <img class="boxer1_img" src="../../assets/images/box2img.png">
                    <div class="boxer1_text" @click="rongzi(financeList, 1)">{{ financeList.name }}</div>
                </div>
                <div class="boxer2">
                    <img class="boxer2_img" src="../../assets/images/box1img.png">
                    <div class="boxer2_text" @click="feirong(nofinanceList, 2)">{{ nofinanceList.name }}</div>
                </div>
                <div class="boxer3">
                    <img class="boxer3_img" src="../../assets/images/box3img.png">
                    <div class="boxer3_text" @click="gongyinglian()">{{ supplyChainList.name }}</div>
                </div>
                <div class="boxer4">
                    <img class="boxer4_img" src="../../assets/images/box4img.png">
                    <div class="boxer4_text" @click="yingjizhuandai()">{{ emergenceList.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getAccList } from "@/api/home/index"
import { mapGetters } from "vuex"
import { getGoosList, buttonShow } from "@/api/home/homer"
import { getInfo } from "@/api/home/index"
import storage from 'store'
import { checkApplyExist } from "@/api/feiRong/index";
import { getUnList } from "@/api/home/index"
export default {
    // 组件注册
    components: {},
    computed: {
        ...mapGetters(['userTypes', 'phone', 'token', 'realName', 'goKjd', 'goLogin'])
    },
    data() {
        return {
            type: '', // 当前登录类型 1-个人 2-企业
            id: '', // 扫描二维码后的接收参数
            goosId: '',
            guaranteeId: null,
            productList: [],
            productButton: [],
            product: [],
            text: '',
            guaranteeId: null,
            productButton: [],
            product: [],
            text: '',
            productButton: [],
            product: [],
            products: [],
            serveList: [],
            guaranteeList: '',
            applyforList: '',
            financeList: '',
            nofinanceList: '',
            supplyChainList: '',
            emergenceList: '',
            financeID: null,//融资parentId
            isAvailable: '',//热点产品按钮
            views: '0',
            list: [],
            tokenType: '', // 是否登录
            realNameType: '', // 是否认证
            page: { // 保前保后 list 条数请求
                pageNum: 1,
                pageSize: 300
            },
            successNum: 0, // 判断是否存在申请完成的保前数据
            msg: '',
        }
    },
    created() {
        this.msg = this.getQueryString('msg')
        let token = JSON.stringify(this.msg)
        if (token && token != '' && token != null && token != 'null' && token.length > 10) {
            this.$store.dispatch('Login', JSON.parse(token))
            this.$toast.success('登陆成功')
            this.$store.dispatch('setLoginUserType', 'appuser')
            // 获取个人信息
            getInfo().then(res => {
                this.$store.dispatch('setUserType', res.data.ysOpenUser.userType)
                this.$store.dispatch('setPhoneNumber', res.data.ysOpenUser.phoneNumber)
                this.$store.dispatch('setRealName', res.data.ysOpenUser.realName)
                setTimeout(_ => {
                    this.$router.push({ path: '/home/index' })
                }, 300)
            })
        }
        this.tokenType = this.goLogin
        if (storage.get('ACCESS_TOKEN')) {
            getInfo().then(res => {
                if (res.code == 200) {
                    // 判断身份证
                    if (res.data.ysLegalpersonInformation) {
                        let endDate = this.$moment(res.data.ysLegalpersonInformation.periodEnd).format('YYYY-MM-DD HH:mm:ss')
                        let newDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
                        let flag = this.$moment(endDate).isBefore(newDate)
                        if (flag) {
                            this.$dialog.confirm({
                                confirmButtonText: '确认',
                                cancelButtonText: "取消",
                                message: '当前身份证日期过期，请重新上传有效身份证件',
                            }).then(() => {
                                this.$router.push({
                                    name: 'overdueList'
                                })
                            }).catch(() => {
                                this.$store.dispatch('Logout').then(() => {
                                    window.location.reload()
                                })
                            })
                        }
                    }
                    // 缓存当前帐号信息
                    storage.set('setUserType', res.data.ysOpenUser.userType)
                    this.$store.dispatch('setUserType', res.data.ysOpenUser.userType)
                    this.$store.dispatch('setPhoneNumber', res.data.ysOpenUser.phoneNumber)
                    this.$store.dispatch('setRealName', res.data.ysOpenUser.realName)
                    this.$store.dispatch('setComName', res.data.ysOpenUser.userName)
                    // 1个人 2企业
                    // 存储当前帐号类型
                    this.type = this.userTypes
                    this.realNameType = this.realName
                }
            })
        }
        this.onLoad()
        this.getList()
    },
    methods: {
        // 接收地址栏内的参数
        getQueryString(name) {
            let str = window.location.href.split('?')[1]
            if (str) {
                var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
                var r = str.match(reg)
                if (r != null) {
                    return decodeURI(r[2])
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        /**
         * 获取当前产品列表
         */
        onLoad() {
            if (storage.get('ACCESS_TOKEN')) {
                // 查询是否存在申请完成的保前数据
                getAccList(this.page).then(res => {
                    if (res.code == 200) {
                        // 判断是否存在数据
                        let len = 0
                        for (let row of res.data.rows) {
                            if (row.productType == 7) {
                                len++
                            }
                        }
                        this.successNum = len
                    }
                })
            }
            getGoosList({ parentId: 'noFilter', pageSize: 100, pageNum: 1, miniShow: 1 }).then(res => {
                this.guaranteeId = res.rows[0].id
                this.product = res.rows
                this.products = res.rows
                this.guaranteeList = res.rows[0]
                this.applyforList = res.rows[1]
                this.financeList = res.rows[2]
                this.nofinanceList = res.rows[3]
                this.supplyChainList = res.rows[4]
                this.emergenceList = res.rows[5]
                this.financeID = res.rows[2].id
                console.log(res, 'menu')
            })
        },
        /**
         * 变更热点产品底部提交按钮
         */
        async getList() {
            await getGoosList({ isHotSpotProducts: '1', pageSize: 100, pageNum: 1, miniShow: 1 }).then(res => {
                if (res.code == 200) {
                    this.list = res.rows
                    this.productList = []
                    let arr = this.list.map((item, index) => {
                        let name = []
                        name = item.productFunction.split('，')
                        item.buttonList = []
                        name.map((nameItem, inx) => {
                            let obj = {}
                            obj.name = nameItem
                            obj.path = item.jumpPath
                            obj.id = item.id
                            item.buttonList.push(obj)
                        })
                        return this.getGoodsId(item, item.buttonList)
                    })
                    // 结束后返回的 btn List
                    Promise.all(arr).then(res_p => {
                        console.log(res_p)
                        this.productList = JSON.parse(JSON.stringify(res_p))
                    })
                }
            })
        },
        /**
         * 同步变更数据结构
         * @param {Object} item 
         */
        getGoodsId(item, list) {
            return new Promise((resolve, reject) => {
                if (storage.get('ACCESS_TOKEN')) {
                    buttonShow(item.id).then(res => {
                        if (res.code == 200) {
                            let data = res.data
                            item.bhIsAvailable = data.bhIsAvailable
                            item.bqIsAvailable = data.bqIsAvailable
                            resolve(item)
                        }
                    })
                } else {
                    resolve(item)
                }
            })
        },
        guarantee() {
            if (this.type === 1) {
                if (this.realName == 0) {
                    this.$dialog.confirm({
                        confirmButtonText: '前往实名认证',
                        confirmButtonColor: '#1989fa',
                        message: '当前帐号没有实名认证，请完成实名认证后在进行预审',
                    }).then(() => {
                        // 进入对应账号的认证页面
                        if (this.userTypes == 2) {
                            this.$router.push({ name: 'comHome' })
                        } else {
                            this.$router.push({ name: 'preHome' })
                        }
                    }).catch(() => {

                    });
                } else {
                    this.$dialog.confirm({
                        confirmButtonText: '同意并继续',
                        cancelButtonText: '不同意',
                        title: "用户隐私保护提示",
                        confirmButtonColor: '#1989fa',
                        message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。`,
                    }).then(() => {
                        this.$router.push({
                            path: '/guaranPerEdit',
                            query: {
                                parentId: this.guaranteeId,
                                source: "0"
                            }
                        })
                    })
                }
            } else if (this.type === 2) {
                if (this.realName == 0) {
                    this.$dialog.confirm({
                        confirmButtonText: '前往实名认证',
                        confirmButtonColor: '#1989fa',
                        message: '当前帐号没有实名认证，请完成实名认证后在进行预审',
                    }).then(() => {
                        // 进入对应账号的认证页面
                        if (this.userTypes == 2) {
                            this.$router.push({ name: 'comHome' })
                        } else {
                            this.$router.push({ name: 'preHome' })
                        }
                    }).catch(() => {

                    });
                } else {
                    this.$dialog.confirm({
                        confirmButtonText: '同意并继续',
                        cancelButtonText: '不同意',
                        title: "用户隐私保护提示",
                        confirmButtonColor: '#1989fa',
                        message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。`,
                    }).then(() => {
                        this.$router.push({
                            path: '/guaranComEdit',
                            query: {
                                parentId: this.guaranteeId,
                                source: "0"
                            }
                        })
                    })
                }
            } else {
                this.$dialog.confirm({
                    confirmButtonText: '前往登录',
                    confirmButtonColor: '#1989fa',
                    message: '请先登录您的账号',
                }).then(() => {
                    this.$router.push({ name: 'login' })
                }).catch(() => {

                });
            }
        },
        applyfor() {
            if (this.type === 1) {
                if (this.realName == 0) {
                    this.$dialog.confirm({
                        confirmButtonText: '前往实名认证',
                        confirmButtonColor: '#1989fa',
                        message: '当前帐号没有实名认证，请完成实名认证后在进行预审',
                    }).then(() => {
                        // 进入对应账号的认证页面
                        if (this.userTypes == 2) {
                            this.$router.push({ name: 'comHome' })
                        } else {
                            this.$router.push({ name: 'preHome' })
                        }
                    }).catch(() => {

                    });
                } else {
                    let num = 0
                    Promise.all([
                        getUnList({pageNum: 1, pageSize: 1000}).then(res => {
                            console.log(res)
                            let data = res.data.rows
                            for (let row of data) {
                                if (row.productType == 1) {
                                    num++
                                }
                            }
                        })
                    ]).then(() => {
                        if (num > 0) {
                            this.$dialog.confirm({
                                confirmButtonText: '发起新的申请',
                                cancelButtonText: '查看申请记录',
                                confirmButtonColor: '#1989fa',
                                message: '已存在预审信息，是否继续申请',
                            }).then(() => {
                                this.$dialog({
                                    confirmButtonText: '确认',
                                    message: '存在申请中的业务，只能申请一次',
                                })
                            }).catch(() => {
                                this.$router.push({name: 'task', params: {active: 0}})
                            });
                            return false
                        }
                        this.$dialog.confirm({
                            confirmButtonText: '同意并继续',
                            cancelButtonText: '不同意',
                            title: "用户隐私保护提示",
                            confirmButtonColor: '#1989fa',
                            message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。`,
                        }).then(() => {
                            this.$router.push({ path: '/home/index/edit' })
                        })
                    })
                }
            } else if (this.type === 2) {
                if (this.realName == 0) {
                    this.$dialog.confirm({
                        confirmButtonText: '前往实名认证',
                        confirmButtonColor: '#1989fa',
                        message: '当前帐号没有实名认证，请完成实名认证后在进行预审',
                    }).then(() => {
                        // 进入对应账号的认证页面
                        if (this.userTypes == 2) {
                            this.$router.push({ name: 'comHome' })
                        } else {
                            this.$router.push({ name: 'preHome' })
                        }
                    }).catch(() => {

                    });
                } else {
                    this.$dialog.confirm({
                        confirmButtonText: '同意并继续',
                        cancelButtonText: '不同意',
                        title: "用户隐私保护提示",
                        confirmButtonColor: '#1989fa',
                        message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。`,
                    }).then(() => {
                        this.$router.push({ path: '/home/index/comEdit' })
                    })
                }
            } else {
                this.$dialog.confirm({
                    confirmButtonText: '前往登录',
                    confirmButtonColor: '#1989fa',
                    message: '请先登录您的账号',
                }).then(() => {
                    this.$router.push({ name: 'login' })
                }).catch(() => {

                });
            }
        },
        rongzi(row, num) {
            if (this.type == '') {
                this.$router.push({
                    path: '/financingNo',
                    query: {
                        parentId: row.id,
                        num
                    }
                })
            } else {
                this.$router.push({
                    path: '/financing',
                    query: {
                        parentId: row.id,
                        num
                    }
                })
            }

        },
        // 非融资担保记录跳转
        feirong(item, num) {
            console.log(item)
            if (this.type == '') {
                this.$router.push({
                    path: '/noFinancingNo',
                    query: {
                        parentId: item.id,
                        num
                    }
                })
            } else {
                this.$router.push({
                    path: '/noFinancing',
                    query: {
                        parentId: item.id,
                        num
                    }
                })
            }
        },
        gongyinglian() {
            if (this.type == '') {
                this.$router.push({ path: '/supplyChainNo' })
            } else {
                this.$router.push({ path: '/supplyChain' })
            }
        },
        yingjizhuandai() {
            if (this.type == '') {
                this.$router.push({ path: '/emergLoantransferNo' })
            } else {
                this.$router.push({ path: '/emergLoantransfer' })
            }

        },
        /**
         * 科技贷按钮 go pages
         * @param {Number} index 下标
         * @param {Object} item 当前数据
         */
        goLuDanPage(index, item) {
            if (!(storage.get('ACCESS_TOKEN'))) {
                this.$dialog.confirm({
                    confirmButtonText: '前往登录',
                    confirmButtonColor: '#1989fa',
                    message: '请先登录您的账号',
                }).then(() => {
                    this.$router.push({ name: 'login' })
                })
                return false
            }
            if (this.realNameType != '1') {
                this.$dialog.confirm({
                    confirmButtonText: '前往实名认证',
                    confirmButtonColor: '#1989fa',
                    message: '当前帐号没有实名认证，请完成实名认证后在进行申请',
                }).then(() => {
                    // 进入对应账号的认证页面
                    if (this.type == '2') {
                        this.$router.push({ name: 'comHome' })
                    } else {
                        this.$router.push({ name: 'preHome' })
                    }
                })
                return false
            }
            // 科技贷跳转
            if (item.jumpPath === '/ldScience') {
                if (storage.get('setUserType') == '1') {
                    this.$dialog({
                        message: '该产品仅适用于企业用户，个人用户无权申请！',
                    })
                    return false
                }
                // 0 -> 保前
                if (index == 0) {
                    switch (item.bqIsAvailable) {
                        case '0':
                            this.$dialog.confirm({
                                confirmButtonText: '同意并继续',
                                cancelButtonText: '不同意',
                                title: "用户隐私保护提示",
                                confirmButtonColor: '#1989fa',
                                message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。`,
                            }).then(() => {
                                // 跳转保前页面
                                this.$router.push({
                                    path: '/ldScience/edit',
                                    query: { prodId: item.id }
                                })
                            })
                            break;
                        case '1':
                            this.$dialog.confirm({
                                confirmButtonText: '确定',
                                confirmButtonColor: '#1989fa',
                                message: '当前无权限申请产品，请联系当地担保机构！',
                            })
                            break;
                        case '2':
                            this.$dialog.confirm({
                                confirmButtonText: '查看申请记录',
                                cancelButtonText: '取消',
                                confirmButtonColor: '#1989fa',
                                message: '该产品存在申请中的业务',
                            }).then(() => {
                                // 查看历史记录
                                this.$router.push({ name: 'task' })
                            })
                            break;
                    }
                }
                // 1 -> 保后
                if (index == 1) {
                    switch (item.bhIsAvailable) {
                        case '0':
                            this.$dialog.confirm({
                                confirmButtonText: '同意并继续',
                                cancelButtonText: '不同意',
                                title: "用户隐私保护提示",
                                confirmButtonColor: '#1989fa',
                                message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。`,
                            }).then(() => {
                                // 跳转保前页面
                                this.$router.push({
                                    path: '/ldAfter/edit',
                                    query: { prodId: item.id }
                                })
                            })
                            break;
                        case '1':
                            this.$dialog({
                                message: '"您的担保业务申请流程尚未完成，请点击 “快速申请” 按钮完成业务申请流程！"',
                            })
                            break;
                        case '2':
                            this.$dialog.confirm({
                                confirmButtonText: '查看申请记录',
                                cancelButtonText: '取消',
                                confirmButtonColor: '#1989fa',
                                message: '该产品存在申请中的业务',
                            }).then(() => {
                                // 查看历史记录
                                this.$router.push({ name: 'task' })
                            })
                            break;
                    }
                }
                return false
            }
            // 投标保跳转
            if (item.jumpPath === '/tbb') {
                if (storage.get('setUserType') == '1') {
                    this.$dialog({
                        message: '该产品仅适用于企业用户，个人用户无权申请！',
                    })
                    return false
                }

                if (index == 0) {
                    // 检测是否已经有了历史记录
                    checkApplyExist(this.page).then(res => {
                        if (res.code == 200) {
                            // 判断是否存在数据
                            if (!res.data) {
                                this.$dialog.confirm({
                                    confirmButtonText: '同意并继续',
                                    cancelButtonText: '不同意',
                                    title: "用户隐私保护提示",
                                    confirmButtonColor: '#1989fa',
                                    message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。`,
                                }).then(() => {
                                    // 跳转新增页面
                                    this.$router.push({
                                        path: '/takeBid/bidApplicate',
                                    })
                                }).catch(() => {

                                });
                                return false
                            }
                            // 如果存在数据进行提示 是否查看记录
                            else {
                                this.$dialog.confirm({
                                    confirmButtonText: '前往申请',
                                    cancelButtonText: '取消',
                                    confirmButtonColor: '#1989fa',
                                    message: '存在已发起未完成的投标保函申请，可在我的-申请中操作提交。是否重新发起保函申请？',
                                }).then(() => { // 发起新的请求
                                    this.$router.replace({
                                        path: '/takeBid/bidApplicate',
                                    })
                                }).catch(() => { // 查看历史记录
                                    return
                                })
                            }
                        } else {
                            this.$toast(res.msg)
                        }
                    })
                }
                if (index == 1) {
                    this.$router.push({
                        path: '/takeBid/letterCheck',
                    })
                }
                // this.$dialog.confirm({
                //     confirmButtonText: '确定',
                //     confirmButtonColor: '#1989fa',
                //     message: `功能尚未开通，敬请期待`,
                //     showCancelButton: false
                // })
                // return false
            }
        },
    }
}
</script>

<style lang='scss' scoped>
.cusman-bac-oner {
    background-image: url('../../assets/images/cusmanger.png');
    width: 100%;
    height: 100%;
    padding-top: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;

}

.user-bac-one {
    background-image: none;
}

.user-bac-one {
    background-image: none
}

.title {
    width: 300px;
    height: 24px;
    margin-left: 250px;
    font-weight: 600;
    font-size: 50px;
    color: #162041;
}

.titleimg {
    width: 85%;
    margin-left: 50px;
    margin-top: 25px;
}

.applyfor {
    background-image: url('../../assets/images/retangle.png');
    border-color: #ffffff;
    width: 95%;
    height: 25%;
    margin: 0 auto;
    margin-top: -22px;
    background-repeat: no-repeat;
    background-size: 100% 100%;

}

.applyforTitle {
    width: 17%;
    height: 2%;
    font-weight: 600;
    font-size: 30px;
    line-height: 2%;
    text-align: left;
    margin-left: 5%;
    padding-top: 7%;
    color: #212121;
}

.hot {
    margin-top: -4.5%;
}

.hotTitle {
    //width: 17%;
    height: 2%;
    font-weight: 600;
    font-size: 30px;
    line-height: 2%;
    text-align: left;
    margin-left: 6.3%;
    padding-top: 8%;
    padding-bottom: 1%;
    color: #212121;
}

.kjd-bg {
    width: 700px;
    height: 230px;
    padding-top: 1%;
    padding-bottom: 4%;
    // background-image: url('../../assets/images/backone.png');
    margin: 2% auto 0%;
    background-image: url('../../assets/images/backone.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    //box-shadow: 0px 3px 6px 0px rgba(190, 165, 136, 20);
    // border-radius: 30px;
}

.tbb-bg {
    width: 700px;
    height: 230px;
    padding-top: 1%;
    padding-bottom: 4%;
    // background-image: url('../../assets/images/backone.png');
    margin: -1% auto 1%;
    background-image: url('../../assets/images/bactwo.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    //box-shadow: 0px 3px 6px 0px rgba(190, 165, 136, 20);
    // border-radius: 30px;
}

.hotone {
    width: 92%;
    height: 20%;
    padding-top: 1%;
    // background-image: url('../../assets/images/backone.png');
    margin: 2% auto 1%;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .hotoneTitle {
        height: 9%;
        font-weight: 500;
        font-size: 30px;
        line-height: 9%;
        text-align: left;
        margin-left: 2%;
        margin-top: 3%;
        color: #000;
    }

    .hotoneTag {
        width: 80%;
        // height: 8%;
        word-break: all;
        font-weight: 200;
        font-size: 25px;
        // line-height: 8%;
        text-align: left;
        margin-left: 2%;
        margin-top: 5%;
        color: #898989;

    }

    .hotoneButton {
        display: inline-block;
        width: 100%;
        height: 70%;
        font-size: 26px;
        margin: 13% 20% 3% 2%;
        margin-right: 10%;
        border-radius: 50px;
        background: #F5E9DB;
        color: #8B6841;
    }

    .hotoneButton-kjd {
        background: #F5E9DB;
        color: #8B6841;
    }

    .hotoneButton-tbb {
        background: #E6BCBC;
        color: #8B4141;
    }
}

.hottwo {
    width: 92%;
    height: 20%;
    padding-top: 1%;
    // background-image: url('../../assets/images/bactwo.png');
    margin: 2% auto 1%;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .hottwoTitle {
        width: 30%;
        height: 9%;
        font-weight: 400;
        font-size: 35px;
        line-height: 9%;
        text-align: left;
        margin-left: 6%;
        margin-top: 5%;
        color: #212121;
    }

    .hottwoTag {
        width: 60%;
        height: 8%;
        font-weight: 200;
        font-size: 13px;
        line-height: 8%;
        text-align: left;
        margin-left: 6%;
        margin-top: 7%;
        color: #898989;
    }

    .hottwoButton {
        width: 28%;
        height: 30%;
        margin: 6% 0 3% 6%;
        border-radius: 50px;
        background: #E6D2BC;
    }
}

.serve {
    margin-top: -8%;
}

.serveTitle {
    //width: 26%;
    height: 2%;
    font-weight: 600;
    font-size: 30px;
    line-height: 2%;
    text-align: left;
    margin-left: 5.5%;
    margin-bottom: 1%;
    padding-top: 10%;
    padding-bottom: 0;
    color: #212121;
}

.boxer {
    margin: 4% auto;
    width: 92%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-right:10px;

    .boxer1 {
        height: 145px;
        background-image: url('../../assets/images/zonghe.png');
        background-repeat: no-repeat;
        border-radius: 20px;
        background-size: 100% 100%;
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
        margin-bottom: -100px;
        padding-left: 16px;
        padding-top:8px;
        width: 47%;
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        justify-content: space-around;
        align-items: center;

        .boxer1_img {
            box-sizing: border-box;
            text-align: center;
            width: 56px;
            height: 56px;
            margin-left: -65px;
            margin-top: 35px;
        }

        .boxer1_text {
            box-sizing: border-box;
            height: 40px;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            margin-top: 42px;
            margin-left: -113px;
            color: #212121;
        }
    }

    .boxer2 {
        height: 145px;
        background-image: url('../../assets/images/zonghe.png');
        background-repeat: no-repeat;
        border-radius: 20px;
        background-size: 100% 100%;
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
        padding-left: 25px;
        padding-top:8px;
        margin-bottom: -100px;
        width: 47%;

        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        justify-content: space-around;
        align-items: center;

        .boxer2_img {
            box-sizing: border-box;
            text-align: center;
            width: 56px;
            height: 56px;
            margin-left: -78px;
            margin-top: 35px;
        }

        .boxer2_text {
            box-sizing: border-box;
            text-align: center;
            height: 40px;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            text-align: left;
            margin-left: -115px;
            margin-top: 43px;
            color: #212121;
        }
    }

    .boxer3 {
        height: 145px;
        background-image: url('../../assets/images/zonghe.png');
        background-repeat: no-repeat;
        border-radius: 20px;
        background-size: 100% 100%;
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
        margin-top: 15%;
        padding-left: 16px;
        padding-top:8px;
        width: 47%;

        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        justify-content: space-around;
        align-items: center;

        .boxer3_img {
            box-sizing: border-box;
            text-align: center;
            width: 56px;
            height: 56px;
            margin-left: -45px;
            margin-top: 35px;
        }

        .boxer3_text {
            box-sizing: border-box;
            text-align: center;
            height: 40px;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            text-align: left;
            margin-left: -70px;
            margin-top: 43px;
            color: #212121;
        }

    }

    .boxer4 {
        height: 145px;
        background-image: url('../../assets/images/zonghe.png');
        background-repeat: no-repeat;
        border-radius: 20px;
        background-size: 100% 100%;
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
        margin-top: 15%;
        width: 47%;
        padding-left: 16px;
        padding-top:8px;

        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        justify-content: space-around;
        align-items: center;

        .boxer4_img {
            box-sizing: border-box;
            text-align: center;
            width: 56px;
            height: 56px;
            margin-left: -68px;
            margin-top: 33px;
        }

        .boxer4_text {
            box-sizing: border-box;
            text-align: center;
            height: 40px;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            text-align: left;
            margin-left: -115px;
            margin-top: 43px;
            color: #212121;
        }
    }
}

.bottom {
    background: rgba(0, 0, 0, 0.7);
    width: 88%;
    height: 6%;
    border-radius: 20px;
    margin-top: -70px;
    margin-left: 30px;

    .bottomImg {
        display: inline-block;
        widows: 28px;
        height: 28px;
        margin-left: 20px;
        margin-top: 30px;
    }

    .bottomTitle {
        position: fixed;
        display: inline-block;
        text-align: center;
        width: 250px;
        height: 50px;
        font-weight: 400;
        font-size: 25px;
        line-height: 50%;
        text-align: left;
        margin-left: 30px;
        // padding-top: -50px;
        margin-top: 38px;
        color: #ffffff;
    }

    .bottomButton {
        position: fixed;
        border-radius: 20px;
        color: #000000;
        text-align: center;
        width: 150px;
        height: 50px;
        font-weight: 600;
        font-size: 25px;
        line-height: 60%;
        text-align: left;
        margin-left: 430px;
        // padding-top: -30px;
        margin-top: 20px;
        background: #E6D2BC;

    }
}

.card-title {
    margin: 0 5%;
    font-size: 30px;
    font-weight: 600;
    padding-top: 5%;
}

.item-box {
    width: 100%;
    display: flex;
    height: 70%;
    justify-content: space-around;
    align-items: center;
    padding: 0 30px;
}

.item-left-img {
    padding: 18px 30px;
}

.item-left-img-in {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.item-line {
    width: 1px;
    height: 70%;
    border-right: 4px solid rgb(237, 237, 237);
}

.item-right-img {
    padding: 18px 30px;
}

.item-right-img-in {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.item-text {
    margin-top: 12px;
    font-size: 30px;
    font-weight: 600;
}

.item-tag {
    margin-top: 13px;
    font-size: 25px;
    font-weight: 200;
    color: #898989
}

.gt {
    font-size: 30px;
    vertical-align: middle;
    margin-left: 10px;
}
</style>