import cusManagerinfo from '../../pages/cusManagerlogin/info.vue'
import cusManagerlogin from '../../pages/cusManagerlogin/index.vue'
import cusManagerMine from '../../pages/cusManagerlogin/mine.vue'
import qrCode from '../../pages/cusManagerlogin/qrCode.vue'
import invite from '../../pages/cusManagerlogin/invite.vue'
import task from '@/pages/cusManagerlogin/task/index.vue'

/**
 * 客户经理信息
 */
export const infoRoute = {
    name: "cusManagerinfo",
    path: "/cusManagerinfo",
    meta:{
        title:'个人中心',
        keepAlive: true
    },
    component: cusManagerinfo,
    
}
/**
 * 客户经理登录界面
 */
export const cusManagerloginRouter = {
    name: "cusManagerlogin",
    path: "/cusManagerlogin",
    meta:{
        title:'登录',
        keepAlive: true
    },
    component: cusManagerlogin,
    
}


/**
 * 客户经理登录界面
 */
export const cusManagerMiner = {
    name: "cusManagerMine",
    path: "/cusManagerMine",
    meta:{
        title:'个人中心',
        keepAlive: true
    },
    component: cusManagerMine,
    
}
/**
 * 二维码
 */
export const qrCodeRoute = {
    name: "qrCode",
    path: "/qrCode",
    meta:{
        title:'产品二维码',
        keepAlive: true
    },
    component: qrCode,
    
}
/**
 * 邀请客户
 */
export const inviteRoute = {
    name: "invite",
    path: "/invite",
    meta:{
        title:'邀请客户',
        keepAlive: true
    },
    component: invite,
    
}

/**
 * 业务中心
 */
export const tasklist = {
    name: "cumTask",
    path: "/cusManagerMine/task",
    meta:{
        title:'我负责的业务',
        keepAlive: true
    },
    component: task,
}