import router from './router'
import store from './store'
import storage from 'store'

// 白名单
const whiteList = [
    '/login',
    '/registered',
    '/personal',
    '/enterprise',
    '/check', '/entCheck',
    '/homeUserText', '/homePirText',
    '/institutions', '/preliminary',
    '/notloggedhome/index', '/notloggedhome/task', '/notloggedhome/mine', '/wepage/index',
    '/cusManagerlogin', // 客户经理登录
    '/qrcodeView/index',
    '/noFinancingNo','/supplyChainNo','/emergLoantransferNo','/financingNo',
    '/user/updataPhone/prePhoneNoLogin','/user/updataPhone/child/phoneFaceLogin', '/user/updataPhone/editPhoneLogin',
    '/user/updataPhone/comEditPhoneNoLogin', '/user/updataPhone/child/comPhoneListLogin', '/user/updataPhone/child/conLicenseUplod'
]
// 首页
const home = '/home/index'
const homes = '/cusManagerMine'
// 路由拦截
router.beforeEach((to, form, next) => {
    // 本地缓存判断
    if (storage.get('ACCESS_TOKEN')) {
        if (whiteList.includes(to.path)) {
            if (to.path == '/wepage/index') {
                next()
                return false
            }
            if (storage.get('userLoginType') == 'cusm') {
                next(homes)
            } else {
                next({
                    path: home,
                    query: to.query
                })
            }
        } else {
            if (whiteList.includes(to.path)) {
                next()
            } else {
                if (storage.get('userLoginType') == 'cusm') { // 当前客户经理登录时 访问 个人/企业 页面重置到客户经理首页 /cusManagerMine
                    if (to.path.indexOf('/home') != -1) {
                        next(homes)
                    } else {
                        next()
                    }
                } else if (storage.get('userLoginType') == 'appuser') { // 当前个人登录时 访问客户经理页面重置到home首页
                    if (to.path.indexOf('cusManagerMine') != -1 || to.path.indexOf('qrCode') != -1 || to.path.indexOf('invite') != -1) {
                        next(home)
                    } else {
                        store.commit('SET_GOKJD', true)
                        next({query: to.query})
                    }
                } else {
                    next()
                }
            }
        }
        if (to.path == '/homes/task') {
            if (to.path == '/homes/task') {
                store.commit('CHANGE_TAB_ACTIVE', to.path)
                next()
            }
            if (to.path == '/homes/mines') {
                store.commit('CHANGE_TAB_ACTIVE', to.path)
                next()
            }
        } else if (to.path == '/home/index') {
            // 防止进入其他页面返回时  底部tag标签没有变化
            if (to.path == '/home/index') {
                store.commit('CHANGE_TAB_ACTIVE', to.path)
                next()
            }
            if (to.path == '/home/task') {
                store.commit('CHANGE_TAB_ACTIVE', to.path)
                next()
            }
            if (to.path == '/home/mine') {
                store.commit('CHANGE_TAB_ACTIVE', to.path)
                next()
            }
        }
    } else {
        // 添加初始化时 user type 置为 ''
        if (whiteList.includes(to.path)) {
            store.dispatch('setLoginUserType', 'not')
        }
        // 白名单
        if (whiteList.includes(to.path)) {
            next()
        } else {
            if (whiteList.includes(to.path)) {
                next()
            } else {
                store.commit('SET_GOKJD', true)
                storage.set('setKJD', true)
                next({
                    path: '/notloggedhome/index',
                    query: to.query
                })
            }
        }
    }
})