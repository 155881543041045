import ldPirText from '@/pages/ldScience/agreement/pirText.vue'
import ldPreText from '@/pages/ldScience/agreement/preText.vue'
import ldUserText from '@/pages/ldScience/agreement/userText.vue'

export const ldScience = {
    name: 'ldScience',
    path: '/ldScience/edit',
    meta:{
        title:'鲁担科技贷',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/ldScience/ldScience.vue'),
}
export const ldAfter = {
    name: 'ldAfter',
    path: '/ldAfter/edit',
    meta:{
        title:'保后评级',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/ldScience/afterInsur.vue'),
}


export const ldPir = {
    name: 'ldPirText',
    path: '/ldScience/ldPirText',
    meta:{
        title:'企业信息授权书',
        keepAlive: true
    },
    component: ldPirText
}

export const ldPre = {
    name: 'ldPreText',
    path: '/ldScience/ldPreText',
    meta:{
        title:'个人信息授权书',
        keepAlive: true
    },
    component: ldPreText
}

export const ldUser = {
    name: 'ldUserText',
    path: '/ldScience/ldUserText',
    meta:{
        title:'敏感个人信息授权书',
        keepAlive: true
    },
    component: ldUserText
}