<!--
* @Description: 客户经理-邀请客户
* @Author: zhaogeng
* @CreateDate: 2024/03/15 11:33:08
* @FileName: invite.vue
!-->
<template>
    <div class="cusman-bac-oner">
        <div class="cardForm">
            <div>
                <img src="../../assets/images/product.png" class="product">
                <div class="title">请选择邀请客户申请的产品</div>
            </div>
            <div class="box">
                <Choose></Choose> 
            </div>
        </div>
    </div>
</template>

<script>
import Choose from "@/pages/cusManagerlogin/components/Choose.vue"
export default {
    // 组件注册
    components: {
        Choose
    },
    data() {
        return {
        }
    },
    created() {

    },
    mounted() { },
    methods: {
    }
}
</script>

<style lang='scss' scoped>
.cusman-bac-oner {
    background-image: url('../../assets/images/cusmanger.png');
    width: 100%;
    padding-top: 0;
    background-repeat: no-repeat;
    background-size: cover;

}
.product{
    width: 50px;
    height: 50px;
    vertical-align: middle;
}
.title{
    font-size: 30px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}
.cardForm {
    padding: 30px 20px;
    overflow: hidden;
    width: 92%;
    height: 80vh;
    margin: 6% auto 0;
    border: 1px;
    border-radius: 20px;
    box-shadow: 0px -6px 4px 0px rgba(218, 205, 189, 0.2);
    background-color: white;
}
</style>