<!--
* @Description: 企业授权
* @Author: sunchunchun
* @CreateDate: 2022/10/08 15:29:13
* @FileName: preText.vue
!-->
<template>
    <div class="text-box">
        <span v-html="text" class="text"></span>
        <div class="box-btn">
            <van-button type="info" class="btn" round @click="onNext">下一步</van-button>
        </div>
    </div>
</template>

<script>
import { getText, readText } from "@/api/home/index"
import { mapGetters } from "vuex"
export default {
    data () {
        return {
            text: '',
            form: {},
            id: '',
            type: {}
        }
    },
    computed: {
        ...mapGetters(['phone', 'userTypes'])
    },
    created() {
        this.form = this.$route.query.form
        this.type = JSON.parse(this.$route.query.form)
    },
    mounted() {
        // 获取预审协议
        getText({type: '个人信息授权书'}).then(res => {
            this.id = res.data.id
            this.text = res.data.content
        })
    },
    methods: {
        // 跳转下一步
        onNext() {
            if (this.userTypes == 1) {
                if (this.type.busMainType!=1) {
                    readText({ids: this.id}).then(res => {
                        this.$router.push({
                            name: 'rccPir',
                            query: {
                                form: this.form,
                            }
                        })
                    })
                } else {
                    readText({ids: this.id}).then(res => {
                        this.$router.push({
                            name: 'rccUser',
                            query: {
                                form: this.form,
                            }
                        })
                    })
                }
            } else {
                readText({ids: this.id}).then(res => {
                    this.$router.push({
                        name: 'rccPir',
                        query: {
                            form: this.form,
                        }
                    })
                })
            }
        },
    }
}
</script>

<style lang='scss' scoped>
.text-box{
    padding: 30px;
    .text{
        font-size: 32px;
        line-height: 50px;
        margin-bottom: 40px;
        display: inline-block;
    }
    .box-btn{
        text-align: center;
        .btn{
            padding: 0 90px;
        }
    }
}
</style>