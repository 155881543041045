<!--
    * @Description: 业务办理
    * @Author: sunchunchun
    * @CreateDate: 2022/08/30 17:30:26
    * @LastEditTime: 2022/08/30 17:30:26
    * @FileName: task.vue
    !-->
<template>
    <div class="task-box">
        <!-- 申请中 -->
        <van-tabs color="#1989FA" v-model="active" @click="onTabClick">
            <van-tab>
                <div slot="title">
                    <van-badge v-if="noList > 0" :content="noList" max="99">
                        <div>申请中</div>
                    </van-badge>
                    <van-badge v-else>
                        <div>申请中</div>
                    </van-badge>
                </div>
                <div class="tabs" ref="tabs">
                    <van-pull-refresh v-model="pullLoading" @refresh="refresh">
                        <van-list :immediate-check="false" v-model="loading" :finished="finished" finished-text="没有更多了"
                            @load="onLoad">
                            <YsListProgress @list="getList" :data="data">
                            </YsListProgress>
                        </van-list>
                    </van-pull-refresh>
                </div>
            </van-tab>

            <!-- 已完成 -->
            <van-tab>
                <div slot="title">
                    <van-badge v-if="yesList > 0" :content="yesList" max="99">
                        <div>已完成</div>
                    </van-badge>
                    <van-badge v-else>
                        <div>已完成</div>
                    </van-badge>
                </div>
                <!-- 实名认证判断 -->
                <div class="tabs" ref="tabss">
                    <van-pull-refresh v-model="pullLoading" @refresh="refresh">
                        <van-list :immediate-check="false" v-model="loading" :finished="finished" finished-text="没有更多了"
                            @load="onLoad">
                            <div class="card" v-for="(item, index) in accData" :key="index">
                                <!-- 除开票信息外的预审模块 -->
                                <div>
                                    <YsListComplete :data="item"></YsListComplete>
                                </div>
                            </div>
                        </van-list>
                    </van-pull-refresh>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>
        
<script>
import { 
    getDoing, // 申请中
    getCompleted, // 已完成
} from "@/api/cusManagerlogin/index"

import { mapGetters } from "vuex"
import { getDicData } from "@/api/home/dicData"

import YsListProgress from "./list/YsListProgress.vue"
import YsListComplete from "./list/YsListComplete.vue"
export default {
    components: {
        YsListProgress, // 预审 进行中
        YsListComplete, // 预审 已完成
    },
    data() {
        return {
            disabledRefresh: false, // 是否启动下拉刷新
            scrollTop: 0,
            pullLoading: false, // 下拉加载状态
            loading: false, //上拉加载
            finished: false, //上拉加载完毕
            active: 0, // 判断查询进行中还是已完成列表 0 申请中  1 已完成
            data: [], // 未完成列表
            accData: [], // 预审完成列表
            noList: 0, // 接收进行中列表数量
            yesList: 0, // 接收完成列表数量
            // userType: 0, // 接收初始化类型
            page: {
                pageNum: 1,
                pageSize: 10,
            },
            statusList: [], // 普惠担保申请 业务进度状态
        }
    },
    computed: {
        ...mapGetters(['userTypes', 'realName'])
    },
    created() {
        this.getDic()
        if (this.$route.params.active) {
            this.active = this.$route.params.active
        }
        if (this.$route.query.active) {
            this.active = Number(this.$route.query.active)
        }
        // 初始化列表查询
        this.getList(this.active)
    },
    methods: {
        // 获取字典值
        getDic() {
            // 状态字典
            getDicData('inclusive_decision_result').then(res => {
                this.statusList = res.data
            })
        },
        onLoad() {
            this.getList(this.active)
        },
        // 下拉加载
        refresh() {
            this.data = []
            this.accData = []
            this.page.pageNum = 1
            this.getList(this.active)
        },
        // 切换tab
        onTabClick(val) {
            this.page.pageNum = 1
            this.data = []
            this.accData = []
            this.getList(val)
        },
        // 初始化
        getList(active, pageNum) {
            // 重新授权后才触发
            if (pageNum && pageNum != undefined && pageNum != null && pageNum != '') {
                this.data = []
                this.page.pageNum = pageNum
            }
            if (active === 0) {
                // 获取申请中的列表
                getDoing(this.page).then(res => {
                    if (res.code == 200) {
                        this.page.pageNum++
                        this.finished = false
                        this.loading = false
                        let data = res.data.rows
                        if (data) {
                            // 判断是直通 还是 预审
                            this.noList = res.data.total
                            for (let row of data) {
                                if (row.productType == 7) { // 保前
                                    if (row.state == 1) row.title = '预审未提交'
                                    if (row.state == 2) row.title = '预审进行中'
                                    if (row.state == 3) row.title = '预审已取消'
                                    if (row.state == 4) row.title = '评级已完成'
                                    if (row.state == 5) row.title = '预审通过，待信用评级'
                                    if (row.state == 6) row.title = '信用评级中'
                                }
                                row.date = this.$moment(row.creatTime).format('YYYY-MM-DD HH:mm:ss')
                            }
                        } else {
                            this.noList = 0
                        }
                        this.data.push(...data)
                        this.pullLoading = false
                        // 防止 循环加载
                        if (data.length < 10 || !data) {
                            this.finished = true
                            return
                        }
                    } else {
                        this.pullLoading = false
                        this.$toast.fail(res.msg)
                    }
                })
                // 初始化时获取已完成数量
                getCompleted({ pageNum: 1, pageSize: 10 }).then(res => {
                    if (res.code == 200) {
                        this.yesList = res.data.total
                    }
                })
            } else {
                // 获取已完成的列表
                getCompleted(this.page).then(res => {
                    if (res.code == 200) {
                        this.finished = false
                        this.loading = false
                        this.page.pageNum++
                        let data = res.data.rows
                        if (data) {
                            // 判断是直通 还是 预审
                            this.yesList = res.data.total
                            for (let row of data) {
                                if (row.productType == 7) {
                                    if (row.state == 1) row.title = '预审未提交'
                                    if (row.state == 2) row.title = '预审进行中'
                                    if (row.state == 3) row.title = '预审已取消'
                                    if (row.state == 4) row.title = '预审已完成'
                                    if (row.authorisationStatus) {
                                        if (row.state == 4 && row.authorisationStatus == 1) row.title = '评级失败'
                                    } else {
                                        if (row.state == 4 && row.decisionResult !== "1") row.title = "评级已完成"
                                        if (row.state == 4 && row.decisionResult == '1') row.title = "不符合受理条件"
                                    }
                                    if (row.state == 9) row.title = "预审异常"
                                }
                                row.date = this.$moment(row.creatTime).format('YYYY-MM-DD HH:mm:ss')
                            }
                        } else {
                            this.yesList = 0
                        }
                        this.accData.push(...data)
                        this.pullLoading = false
                        // 防止 循环加载
                        if (data.length < 10 || !data) {
                            this.finished = true
                            return
                        }
                    } else {
                        this.pullLoading = false
                        this.$toast.fail(res.msg)
                    }
                })
                getDoing(this.page).then(res => {
                    this.noList = res.data.total
                })
            }
        },
    }
}
</script>
        
<style lang='scss' scoped>
/deep/ .van-tab__text--ellipsis {
    overflow: visible;
}

.task-box {

    // background-image: linear-gradient(to bottom , #f6fbff, #D5E9F7);
    .card {
        font-size: 26px;
        box-shadow: 0px 2px 9px 0px rgba(218, 205, 189, 0.5);

        .title {
            margin-bottom: 20px;
            color: #888888;
        }

        background: #fff;
        margin: 10px 0 20px 0;
        padding: 25px 30px;
        border-radius: 20px;
    }
}

/deep/ .van-tabs__content {
    height: 90%;
}

/deep/ .van-tab__pane {
    height: 90vh;
}

.tabs {
    padding: 0 20px 0 20px !important;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 10px;
}

/deep/ .van-divider {
    margin: 10px 0;
}

/deep/ .van-tabs__nav {
    background: #fff;
}

.tabs::-webkit-scrollbar {
    display: none;
}

/deep/ .van-pull-refresh {
    height: calc(100vh - 100px) !important;
    overflow: auto !important;
}

.tabs {
    // height: auto !important;
}

.van-pull-refresh {
    height: 100% !important;
}</style>