<!--
* @Description: 开票信息 card
* @Author: sunchunchun
* @CreateDate: 2023/07/05 15:14:08
* @FileName: TicketCard.vue
!-->
<template>
	<div >
		<div>
			<span style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">省农信联社业务</span>
			<van-divider />
		</div>
		<div>
			<div class="title">债权人</div>
			<div class="text-content">{{ item.creditorHeadoffi }}</div>
			<van-divider />
		</div>
		<div>
			<div class="title">借款人</div>
			<div class="text-content">{{ item.certName }}</div>
			<van-divider />
		</div>
		<div>
			<div class="title">担保机构</div>
			<div class="text-content">{{ item.origGuarName }}</div>
			<van-divider />
		</div>
		<div>
			<div class="title">贷款额度(万元)</div>
			<div class="text-content">{{ item.loanAmt }}</div>
			<van-divider />
		</div>
		<div>
			<div class="title">委托担保合同号</div>
			<div class="text-content">{{ item.securContNo }}</div>
			<van-divider />
		</div>
		<div>
			<div class="title">业务进度</div>
            <span v-if="item.businessStatus == '6'">已放款</span>
            <span v-if="item.businessStatus == '8'">已解保</span>
			<van-divider />
		</div>
		<div v-if="item.invoiceStatus=='0' || item.invoiceStatus==null">
            <div class="btn-box" >
                <div @click="onOpenTicket(item)" class="footer-btn font-family-all" ><span>申请开票</span></div>
            </div>
		</div>
	</div>
</template>

<script>
export default {
    name: "TicketCard",
    props: {
        data: {
            type: Object
        }
    },
	data() {
		return {
            item: '',
        };
	},
	created() {
        this.item = this.data
    },
	methods: {
        // 开票
        onOpenTicket (item) {
            this.$router.push({
                path: '/home/task/ticket',
                query: {id: item.gbgCode}
            })
        },
    }
};
</script>

<style lang='scss' scoped>
    .title{
        margin-bottom: 20px;
        color: #888888;
    }
    .text-content{
        width: 100%;
        word-break: break-word;
        white-space: pre-line;
    }
    .btn-box {
    width: 100%;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: space-around;
    .footer-btn {
        font-size: 26px;
        height: 66px;
        width: 40%;
        color: #fff;
        background-color:#BEA588;
        border-radius: 40px;
        /** 立即申请 样式 */
        span {
            font-size: 26px;
            font-family: none;
            width: 200px;
            position: absolute;
            top: 14px;
            left: 50%;
            margin-left: -100px;
        }
    }
}
</style>