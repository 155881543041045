import request from "@/request/index"


/**
 * 查询企业信息接口
 */
export function queryComUserInfo() {
    return request({
        url: '/frxcx/queryComUserInfo',
        method: 'get',
    })
}
/**
 * 暂存接口
 */
export function temporaryStorage(data) {
    return request({
        url: '/frxcx/temporaryStorage',
        method: 'post',
        data
    })
}

/**
 * 阅读协议接口
 */
export function bookRead(data) {
    return request({
        url: '/frxcx/read',
        method: 'post',
        data
    })
}

/**
 * 提交保函申请
 */
export function commitGuarInfo(data) {
    return request({
        url: '/frxcx/commitGuarInfo',
        method: 'post',
        data
    })
}
/**
 * 申请阶段查询
 */
export function getStage(data) {
    return request({
        url: '/frxcx/getStage',
        method: 'get',
        params: data
    })
}

/**
 * 保函验真接口
 */
export function selectFrVerifyResult(data) {
    return request({
        url: '/frxcx/selectFrVerifyResult',
        method: 'post',
        data
    })
}
/**
 * 申请中列表接口
 */
export function inApplication() {
    return request({
        url: '/frxcx/inApplication',
        method: 'get',
    })
}
/**
 * 已完成列表接口
 */
export function completed() {
    return request({
        url: '/frxcx/completed',
        method: 'get',
    })
}
/**
 * 订单详情查询
 */
export function getApplyDetail(data) {
    return request({
        url: '/frxcx/getApplyDetail',
        method: 'get',
        params: data
    })
}
/**
 * 订单信息查询
 */
export function getAcceptNoDetail(data) {
    return request({
        url: `/frxcx/acceptNo/${data}`,
        method: 'get',
    })
}
/**
 * 下载保函
 */
export function downloadPdf(data) {
    return request({
        url: '/frxcx/downloadPdf',
        method: 'get',
        responseType: 'blob',
        params: data
    })
}
/**
 * 查看是否有进行中
 */
export function checkApplyExist() {
    return request({
        url: '/frxcx/checkApplyExist',
        method: 'get',
    })
}
/**
 * 查询支付信息
 */
export function getPayInfo(data) {
    return request({
        url: '/frxcx/getPayInfo',
        method: 'get',
        params: data
    })
}
/**
* 查是否收费接口
*/
export function checkOpenPay() {
    return request({
        url: '/frxcx/checkOpenPay',
        method: 'get',
    })
}
/**
* 查订单是否收费接口
*/
export function checkOpenPayByAcceptNo(data) {
    return request({
        url: '/frxcx/checkOpenPayByAcceptNo',
        method: 'get',
        params: data
    })
}


/**
* 查是否收费接口
*/
export function getComInfoByYushen(tenderer) {
    return request({
        url: `/frxcx/getComInfoByYushen?tenderer=${tenderer}`,
        method: 'get',
    })
}

