<!--
    * @Description: 业务办理
    * @Author: sunchunchun
    * @CreateDate: 2022/08/30 17:30:26
    * @LastEditTime: 2022/08/30 17:30:26
    * @FileName: task.vue
    !-->
<template>
    <div class="task-box">
        <!-- 申请中 -->
        <van-tabs color="#1989FA" v-model="active" @click="onTabClick">
            <van-tab>
                <div v-if="userType == 1" slot="title">
                    <van-badge v-if="noList > 0" :content="noList" max="99">
                        <div>申请中</div>
                    </van-badge>
                    <van-badge v-else>
                        <div>申请中</div>
                    </van-badge>
                </div>
                <div v-else slot="title">
                    <div>申请中</div>
                </div>
                <!-- 实名认证判断 -->
                <van-empty v-if="userType == 0" description="请先进行实名认证" />
                <template v-else>
                    <div class="tabs" ref="tabs">
                        <!-- <van-pull-refresh v-model="pullLoading" @refresh="refresh"></van-pull-refresh> -->
                        <div class="content-box">
                            <div class="content-top" @click="topClose('todo1')">
                                <div class="title">担保业务</div>
                                <!-- <div class="icon-right" :class="toggle1 ? 'todown' : 'toright'"></div> -->
                                <div class="rtext" v-if="toggle1">收起</div>
                                <div class="rtext" v-else>展开</div>
                            </div>
                            <div class="content-bottom" :class="toggle1 ? ['long', 'can-scroll'] : 'short'"
                                v-if="doningnum1 > 0">
                                <van-list :immediate-check="false" v-model="loading" :finished="finished"
                                    finished-text="没有更多了" @load="onLoad">
                                    <YsListProgress @list="getList" :data="data" @progress="onSup" @onCancel="onCancel">
                                    </YsListProgress>
                                </van-list>
                            </div>
                            <div class="emptybox" v-else>
                                <img class="emptyimg" src="../../assets/images/newImg/empty.jpg" alt="">
                                <div class="emptytext">暂无业务</div>
                            </div>
                        </div>
                        <div class="content-box">
                            <div class="content-top" @click="topClose('todo2')">
                                <div class="title">保函业务</div>
                                <!-- <div class="icon-right" :class="toggle2 ? 'todown' : 'toright'"></div> -->
                                <div class="rtext" v-if="toggle2">收起</div>
                                <div class="rtext" v-else>展开</div>
                            </div>
                            <div class="content-bottom" :class="toggle2 ? ['long', 'can-scroll'] : 'short'"
                                v-if="doningnum2 > 0">
                                <van-list :immediate-check="false" v-model="loading" :finished="finished"
                                    finished-text="没有更多了" @load="onLoad">
                                    <YsListFeirong :data="appAllList">
                                    </YsListFeirong>
                                </van-list>
                            </div>
                            <div class="emptybox" v-else>
                                <img class="emptyimg" src="../../assets/images/newImg/empty.jpg" alt="">
                                <div class="emptytext">暂无业务</div>
                            </div>
                        </div>
                    </div>
                </template>

            </van-tab>

            <!-- 已完成 -->
            <van-tab>
                <div v-if="userType == 1" slot="title">
                    <van-badge v-if="yesList > 0" :content="yesList" max="99">
                        <div>已完成</div>
                    </van-badge>
                    <van-badge v-else>
                        <div>已完成</div>
                    </van-badge>
                </div>
                <div v-else slot="title">
                    <div>已完成</div>
                </div>
                <!-- 实名认证判断 -->
                <van-empty v-if="userType == 0" description="请先进行实名认证" />
                <div class="tabs" v-else ref="tabss">
                    <div class="content-box">
                        <div class="content-top" @click="topClose('com1')">
                            <div class="title">担保业务</div>
                            <!-- <div class="icon-right" :class="toggle1 ? 'todown' : 'toright'"></div> -->
                            <div class="rtext" v-if="toggle3">收起</div>
                            <div class="rtext" v-else>展开</div>
                        </div>
                        <div class="content-bottom" :class="toggle3 ? ['long', 'can-scroll'] : 'short'"
                            v-if="completenum1 > 0">
                            <van-list :immediate-check="false" v-model="loading" :finished="finished"
                                finished-text="没有更多了" @load="onLoad">
                                <div class="card" v-for="(item, index) in accData" :key="index">
                                    <!-- 开票信息 -->
                                    <div v-if="item.busType == 2">
                                        <TicketCard :data="item"></TicketCard>
                                    </div>
                                    <!-- 莱商银行缴费 -->
                                    <div v-if="item.busType == 3">
                                        <!-- 个人 待缴费 订单 -->
                                        <PreCard :data="item" v-if="userTypes == 1"></PreCard>
                                        <!-- 企业 待缴费 订单 -->
                                        <ComCard :data="item" v-if="userTypes == 2"></ComCard>
                                    </div>
                                    <!-- 除开票信息外的预审模块 -->
                                    <div v-if="item.busType == 1">
                                        <YsListComplete :data="item" @onCancel="onCancel"></YsListComplete>
                                    </div>
                                </div>
                            </van-list>
                        </div>
                        <div class="emptybox" v-else>
                            <img class="emptyimg" src="../../assets/images/newImg/empty.jpg" alt="">
                            <div class="emptytext">暂无业务</div>
                        </div>
                    </div>
                    <div class="content-box">
                        <div class="content-top" @click="topClose('com2')">
                            <div class="title">保函业务</div>
                            <!-- <div class="icon-right" :class="toggle2 ? 'todown' : 'toright'"></div> -->
                            <div class="rtext" v-if="toggle4">收起</div>
                            <div class="rtext" v-else>展开</div>
                        </div>
                        <div class="content-bottom" :class="toggle4 ? ['long', 'can-scroll'] : 'short'"
                            v-if="completenum2 > 0">
                            <van-list :immediate-check="false" v-model="loading" :finished="finished"
                                finished-text="没有更多了" @load="onLoad">
                                <div class="card" v-for="(item, index) in comAllList" :key="index">
                                    <div class="" v-if="item.acceptNo">
                                        <bidComplete :data="item"></bidComplete>
                                    </div>
                                </div>
                            </van-list>
                        </div>
                        <div class="emptybox" v-else>
                            <img class="emptyimg" src="../../assets/images/newImg/empty.jpg" alt="">
                            <div class="emptytext">暂无业务</div>
                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>
    
<script>
import { getUnList, getAccList, setCancel, zhBankCancel } from "@/api/home/index"
import { mapGetters } from "vuex"
import { getInfo } from "@/api/home/index"
import { getDicData } from "@/api/home/dicData"
import TicketCard from "./openTicket/TicketCard.vue"
import PreCard from "./LsCard/PreCard.vue"
import ComCard from "./LsCard/ComCard.vue"
import YsListProgress from "./YsList/YsListProgress.vue"
import YsListFeirong from "./YsList/YsListFeirong.vue"
import YsListComplete from "./YsList/YsListComplete.vue"
import bidComplete from "@/pages/task/YsList/bidComplete.vue"
import { inApplication, completed } from "@/api/feiRong/index";
import { resolve } from "path"
export default {
    components: {
        YsListProgress, // 预审 进行中
        YsListFeirong,// 非融 进行中
        YsListComplete, // 预审 已完成
        TicketCard,
        PreCard, // 个人待缴费 订单
        ComCard, // 企业待缴费订单
        bidComplete //非融完成列表
    },
    data() {
        return {
            disabledRefresh: false, // 是否启动下拉刷新
            scrollTop: 0,
            pullLoading: false, // 下拉加载状态
            loading: false, //上拉加载
            finished: false, //上拉加载完毕
            active: 0, // 判断查询进行中还是已完成列表 0 申请中  1 已完成
            data: [], // 未完成列表
            accData: [], // 预审完成列表
            noList: 0, // 接收进行中列表数量
            yesList: 0, // 接收完成列表数量
            userType: 0, // 接收初始化类型
            toggle1: false,
            toggle2: false,
            toggle3: false,
            toggle4: false,
            page: {
                pageNum: 1,
                pageSize: 10,
            },
            statusList: [], // 普惠担保申请 业务进度状态
            appAllList: [], //申请中全部列表
            comAllList: [], //已完成全部列表
            doningnum1: 0,
            doningnum2: 0,
            completenum1: 0,
            completenum2: 0
        }
    },
    computed: {
        ...mapGetters(['userTypes', 'realName'])
    },
    created() {
        console.log('14234234',1)
        this.getDic()
        if (this.$route.params.active) {
            console.log(Number(this.$route.params.active))
            this.active = Number(this.$route.params.active)
        }
        if (this.$route.query.active) {
            this.active = Number(this.$route.query.active)
        }
        // 获取个人信息  判断是否认证  认证完成直接调用初始化列表查询
        getInfo().then(res => {
            this.userType = res.data.ysOpenUser.realName
            if (res.data.ysOpenUser.realName == 1) {
                this.getList(this.active)
            }
        })
    },
    methods: {
        // 获取字典值
        getDic() {
            // 状态字典
            getDicData('inclusive_decision_result').then(res => {
                this.statusList = res.data
            })
        },
        onLoad() {
            this.getList(this.active)
        },
        // 下拉加载
        refresh() {
            this.data = []
            this.accData = []
            this.page.pageNum = 1
            this.getList(this.active)
        },
        // 切换tab
        onTabClick(val) {
            // 判断是否实名认证
            this.page.pageNum = 1
            if (this.realName == 1) {
                this.data = []
                this.accData = []
                this.getList(val)
            }
        },
        // 初始化
        getList(active, pageNum) {
            // 重新授权后才触发
            if (pageNum && pageNum != undefined && pageNum != null && pageNum != '') {
                this.data = []
                this.page.pageNum = pageNum
            }
            let doningnum1 = 0
            let doningnum2 = 0
            let completenum1 = 0
            let completenum2 = 0
            if (active === 0) {
                // let num1 = 0
                // let num2 = 0
                Promise.all([
                    // 获取申请中的列表
                    getUnList(this.page).then(res => {
                        if (res.code == 200) {
                            this.page.pageNum++
                            this.finished = false
                            this.loading = false
                            let data = res.data.rows
                            if (data) {
                                // 判断是直通 还是 预审
                                doningnum1 = res.data.total
                                this.doningnum1 = res.data.total
                                for (let row of data) {
                                    if (row.productType == 1) { // 担保预审
                                        if (row.state == 1) row.title = '预审未提交'
                                        if (row.state == 2) row.title = '预审进行中'
                                        if (row.state == 3) row.title = '预审已取消'
                                        if (row.state == 4) row.title = '预审已完成'
                                    } else if (row.productType == 7) { // 保前
                                        if (row.state == 1) row.title = '预审未提交'
                                        if (row.state == 2) row.title = '预审进行中'
                                        if (row.state == 3) row.title = '预审已取消'
                                        if (row.state == 4) row.title = '评级已完成'
                                        if (row.state == 5) row.title = '预审通过，待信用评级'
                                        if (row.state == 6) row.title = '信用评级中'
                                    } else if (row.productType == 8) { // 保后
                                        if (row.state == 1) row.title = '预审未提交'
                                        if (row.state == 2) row.title = '预审进行中'
                                        if (row.state == 3) row.title = '预审已取消'
                                        if (row.state == 4) row.title = '已完成'
                                        // 信用评级使用state
                                        if (row.state == 8) row.title = '待信用评级'
                                        if (row.state == 6) row.title = '信用评级中'
                                    } else if (row.productType == 2) { // 客户直通
                                        if (row.state == 1) row.title = '直通未提交'
                                        if (row.state == 2) row.title = '直通进行中'
                                        if (row.state == 3) row.title = '直通已取消'
                                        if (row.state == 4) row.title = '直通已完成'
                                        if (row.state == 5) row.title = '直通审核拒绝'
                                    } else if (row.productType == 4) { // 
                                        if (row.state == 1) row.title = '未提交'
                                        if (row.state == 2) row.title = '进行中'
                                        if (row.state == 4) row.title = '符合受理条件'
                                        if (row.state == 5) row.title = '不符合受理条件'
                                    }
                                    row.date = this.$moment(row.creatTime).format('YYYY-MM-DD HH:mm:ss')
                                }
                            } else {
                                this.noList = 0
                            }
                            this.data.push(...data)
                            this.pullLoading = false
                            // 防止 循环加载
                            if (data.length < 10 || !data) {
                                this.finished = true
                                return
                            }
                        } else {
                            this.pullLoading = false
                            this.$toast.fail(res.msg)
                        }
                    }),
                    // 非融获取申请中的列表
                    inApplication().then(res => {
                        if (res.code == 200) {
                            doningnum2 = res.data.length
                            this.doningnum2 = res.data.length
                            this.appAllList = res.data.sort((this.compare('creatTime')))
                        }
                    }),

                ]).then(res => {
                    this.noList = doningnum1 + doningnum2
                })
                Promise.all([
                    // 初始化时获取已完成数量
                    getAccList({ pageNum: 1, pageSize: 10 }).then(res => {
                        if (res.code == 200) {
                            completenum1 = res.data.total
                        }
                    }),
                    completed().then(res => {
                        if (res.code == 200) {
                            completenum2 = res.data.length
                        }
                    })
                ]).then(() => {
                    this.yesList = completenum1 + completenum2
                })


            } else {
                Promise.all([
                    // 获取已完成的列表
                    getAccList(this.page).then(res => {
                        if (res.code == 200) {
                            this.finished = false
                            this.loading = false
                            this.page.pageNum++
                            let data = res.data.rows
                            if (data) {
                                // 判断是直通 还是 预审
                                completenum1 = res.data.total
                                this.completenum1 = res.data.total
                                for (let row of data) {
                                    if (row.productType == 1) {  // 预审
                                        if (row.state == 1) row.title = '预审未提交'
                                        if (row.state == 2) row.title = '预审进行中'
                                        if (row.state == 3) row.title = '预审已取消'
                                        if (row.state == 4) row.title = '预审已完成'
                                        // 关闭信用评级回显展示
                                        // if (row.state == 4 && row.decisionResult !== "1") row.title = "评级已完成"
                                        // if (row.state == 4 && row.decisionResult == '1') row.title = "不符合受理条件"
                                        if (row.state == 9) row.title = "预审异常"
                                    } else if (row.productType == 7) { // 保前
                                        if (row.state == 1) row.title = '预审未提交'
                                        if (row.state == 2) row.title = '预审进行中'
                                        if (row.state == 3) row.title = '预审已取消'
                                        if (row.state == 4) row.title = '预审已完成'
                                        if (row.authorisationStatus) {
                                            if (row.state == 4 && row.authorisationStatus == 1) row.title = '评级失败'
                                        } else {
                                            if (row.state == 4 && row.decisionResult !== "1") row.title = "评级已完成"
                                            if (row.state == 4 && row.decisionResult == '1') row.title = "不符合受理条件"
                                        }
                                        if (row.state == 9) row.title = "预审异常"
                                    } else if (row.productType == 8) { // 保后
                                        if (row.state == 1) row.title = '预审未提交'
                                        if (row.state == 2) row.title = '预审进行中'
                                        if (row.state == 3) row.title = '预审已取消'
                                        if (row.state == 4) row.title = '已完成'
                                        // 信用评级使用state
                                        if (row.state == 8) row.title = '待信用评级'
                                        if (row.state == 6) row.title = '信用评级中'
                                        if (row.state == 4 && row.authorisationStatus == 1) row.title = '评级失败'
                                    } else if (row.productType == 2) { // 客户直通
                                        if (row.state == 1) row.title = '直通未提交'
                                        if (row.state == 2) row.title = '直通进行中'
                                        if (row.state == 3) row.title = '直通已取消'
                                        if (row.state == 4) row.title = '直通已完成'
                                        if (row.state == 5) row.title = '直通审核拒绝'
                                    } else if (row.productType == 4) {
                                        if (row.state == 1) row.title = '未提交'
                                        if (row.state == 2) row.title = '进行中'
                                        if (row.state == 4) row.title = '符合受理条件'
                                        if (row.state == 5) row.title = '不符合受理条件'
                                    } else if (row.productType == 5 || row.productType == 6 || row.productType == 9 || row.productType == 0 || row.productType == 'qingdao_bank' || row.productType == 'qilu_bank') { // 省联社预审  莱商银行预审  工商银行
                                        if (row.state == 1) row.title = '预审中'
                                        if (row.state == 2) row.title = '符合受理条件'
                                        if (row.state == 3) row.title = '不符合受理条件'
                                        if (row.state == 4) row.title = '预审异常'
                                    }
                                    row.date = this.$moment(row.creatTime).format('YYYY-MM-DD HH:mm:ss')
                                }
                            } else {
                                this.yesList = 0
                            }
                            this.accData.push(...data)
                            this.pullLoading = false
                            // 防止 循环加载
                            if (data.length < 10 || !data) {
                                this.finished = true
                                return
                            }
                        } else {
                            this.pullLoading = false
                            this.$toast.fail(res.msg)
                        }
                    }),
                    // getUnList(this.page).then(res => {
                    //     this.noList = res.data.total
                    // })
                    // 非融获取已完成的列表
                    completed().then(res => {
                        if (res.code == 200) {
                            completenum2 = res.data.length
                            this.completenum2 = res.data.length
                            this.comAllList = res.data.sort((this.compare('creatTime')))
                        }
                    })
                ]).then(() => {
                    this.yesList = completenum1 + completenum2
                })
                Promise.all([
                    // 初始化时获取已完成数量
                    getUnList({ pageNum: 1, pageSize: 10 }).then(res => {
                        if (res.code == 200) {
                            doningnum1 = res.data.total
                        }
                    }),
                    inApplication().then(res => {
                        if (res.code == 200) {
                            doningnum2 = res.data.length
                        }
                    })
                ]).then(() => {
                    this.noList = doningnum1 + doningnum2
                })
            }
        },
        //切换长短
        topClose(type) {
            if (type == 'todo1') {
                this.toggle1 = !this.toggle1
            } else if (type == 'todo2') {
                this.toggle2 = !this.toggle2
            } else if (type == 'com1') {
                this.toggle3 = !this.toggle3
            } else if (type == 'com2') {
                this.toggle4 = !this.toggle4
            }

        },
        // 取消
        onCancel(id, type) {
            this.$dialog.confirm({
                confirmButtonText: '确认',
                cancelButtonText: "取消",
                confirmButtonColor: '#1989fa',
                message: '是否取消预审申请',
            }).then(() => {
                if (type != '4') {
                    setCancel({ id, type }).then(res => {
                        if (res.code == 200) {
                            this.data = []
                            this.page.pageNum = 1
                            this.getList(this.active)
                        } else {
                            this.$toast(res.msg)
                        }
                    })
                } else {
                    zhBankCancel({ id }).then(res => {
                        if (res.code == 1000) {
                            this.data = []
                            this.page.pageNum = 1
                            this.getList(this.active)
                        } else {
                            this.$toast(res.msg)
                        }
                    })
                }
            }).catch(() => {
            })
        },
        // 补充资料
        onSup(item) {
            if (item.productType == 4) {
                this.$router.push({
                    name: 'luedit',
                    query: { code: item.id }
                })
                return false
            }
            if (item.productType == 7) {
                this.$router.push({
                    name: 'ldScience',
                    query: { id: item.id }
                })
                return false
            }
            if (item.productType == 1) {
                if (this.userTypes == 1) {
                    this.$router.push({
                        name: 'edit',
                        query: { id: item.id }
                    })
                } else {
                    this.$router.push({
                        name: 'comEdit',
                        query: { id: item.id }
                    })
                }
            } else {
                if (this.userTypes == 1) {
                    this.$router.push({
                        path: '/cusDirect/request/edit',
                        query: { id: item.id }
                    })
                } else {
                    this.$router.push({
                        path: '/cusDirect/request/comEdit',
                        query: { id: item.id }
                    })
                }
            }

        },
        //排序
        compare(pro) {
            return function (a, b) {
                let value1 = new Date(a[pro]).getTime()
                let value2 = new Date(b[pro]).getTime()
                return value2 - value1
            }
        },
    }
}
</script>
    
<style lang='scss' scoped>
/deep/ .van-tab__text--ellipsis {
    overflow: visible;
}

.task-box {

    // background-image: linear-gradient(to bottom , #f6fbff, #D5E9F7);
    .card {
        font-size: 26px;
        box-shadow: 0px 2px 9px 0px rgba(218, 205, 189, 0.5);

        .title {
            margin-bottom: 20px;
            color: #888888;
        }

        background: #fff;
        margin: 10px 0 20px 0;
        padding: 25px 30px;
        border-radius: 20px;
    }
}

.content-box {
    overflow: hidden;
    box-shadow: 0 4px 18px 0 rgba(218, 205, 189, 0.5), inset 2px 4px 0px 0px #fff;
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    margin-top: 20px;

    .emptybox {
        text-align: center;
        vertical-align: middle;
        height: 39vh;
        padding-top: 8vh;

        .emptyimg {
            width: 368px;
            height: 254px;
        }

        .emptytext {
            color: #898989;
            font-size: 24px;
        }
    }

    .content-top {
        background-color: #f7f7f7;
        height: 96px;
        line-height: 96px;
        padding: 0 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-size: 34px;
            font-weight: 700;
        }

        .rtext {
            font-size: 26px;
        }

        .icon-right {
            width: 16px;
            height: 16px;
            border-top: 4px solid #8d8d8d;
            border-right: 4px solid #8d8d8d;
        }

        .toright {
            transform: rotate(45deg);
        }

        .todown {
            transform: rotate(135deg);
        }
    }

    .content-bottom {
        overflow-y: hidden;
        height: 62vh;
        // touch-action: none;
    }

    .can-scroll {
        overflow-y: scroll;
    }

    .long {
        height: 80vh;
    }
}

/deep/ .van-tabs__content {
    height: 90%;
}

/deep/ .van-tab__pane {
    height: 100vh;
}

.tabs {
    padding: 0 20px 150px 20px !important;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

/deep/ .van-divider {
    margin: 10px 0;
}

/deep/ .van-tabs__nav {
    background: #fff;
}

.tabs::-webkit-scrollbar {
    display: none;
}

/deep/ .van-pull-refresh {
    height: calc(100vh - 100px) !important;
    overflow: auto !important;
}

.tabs {
    // height: auto !important;
}

.van-pull-refresh {
    height: 100% !important;
}
</style>