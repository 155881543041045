import request from "../../request/index"

/**
 * 省市区选择
 * @param { Object } data 
 * @returns 
 */
export function getArea (data) {
    return request({
        url: '/open/area/get',
        method: 'get',
        params: data
    })
}
/**
 * 获取当前帐号用户信息
 */
 export function getInfo () {
    return request({
        url: '/open/user/getInfo',
        method: 'get'
    })
}

/**
 * 获取字典
 * @param { String } data 
 * @returns 
 */
 export function getDicData (data) {
    return request({
        url: `/system/dict/data/type/${data}`,
        method: 'get'
    })
}

/**
 * 根据经营区域查询担保公司
 * @param { String } data 
 * @returns 
 */
 export function getProList (data) {
    return request({
        url: `/open/deptAreaMapper/get/?id=${data}`,
        method: 'get'
    })
}