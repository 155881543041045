<!--
* @Description: 齐鲁银行 新增
* @Author: sunchunchun
* @CreateDate: 2022/09/01 08:59:53
* @FileName: edit.vue
!-->
<template>
    <div class="form-box">
        <div class="form-boxs">
            <div class="form-title">基本信息填写</div>
            <van-form ref="form" style="padding: 0 10px;background: #fff;" validate-first>
                <!-- 企业名称 -->
                <van-field
                    label="企业名称"
                    v-model="form.custName"
                    type="textarea"
                    placeholder="请输入企业名称"
                    disabled
                    required
                />
                <!-- 统一社会信用代码 -->
                <van-field
                    label="统一社会信用代码"
                    v-model="form.creditCode"
                    placeholder="请输入统一社会信用代码"
                    disabled
                    required
                />
                <!-- 贷款银行 -->
                <van-field
                    readonly
                    class="none"
                    clickable
                    name="guarComp"
                    v-model="form.loanBank"
                    label="合作银行"
                    placeholder="请选择合作银行"
                    @click="onBankClick"
                    required
                    :rules="[{ required: true, message: '请选择合作银行' }]"
                />
                <van-popup v-model="showBank" position="bottom">
                    <van-picker
                        value-key="dictLabel"
                        show-toolbar
                        :columns="bankColumns"
                        @confirm="onBankCnfirm"
                        @cancel="showBank = false"
                    />
                </van-popup>
            </van-form>
        </div>
        <div class="agreement">
            <span @click="onRading"><span>《个人信息授权》</span><span v-if="userTypes==2">《企业信息授权》</span><span>《敏感个人信息授权》</span></span>
            <span :class="isAgreement?'text-rad-t':'text-rad-f'">{{ agreementText }}</span>
        </div>
        <div style="margin: 20px 16px 0 16px;padding-bottom: 20px;">
            <van-button round block type="info" @click="onFailed" :loading="loading">提交</van-button>
        </div>
    </div>
</template>

<script>
import { getInfo, saveCompanyQl } from "@/api/home/index"
import { getDicData } from "@/api/home/dicData"
import { mapGetters } from "vuex"
export default {
    data () {
        return {
            loading: false,
            bankColumns: [], // 贷款银行list
            showBank: false, // 银行展示状态
            agreementText: '未阅读', // 协议默认text
            isAgreement: false, // 是否阅读class样式判断
            form: {
                goodsId: '',
                companyName: '', // 公司名称
                creditCode: '', // 信用代码
                loanBank: '齐鲁银行', // 银行
                bankId: '0',
            },
        }
    },
    computed: {
        ...mapGetters(['phone', 'userTypes'])
    },
    created() {
        if (this.$route.query.goodsId) {
            this.form.goodsId = this.$route.query.goodsId
        }
        if (this.$route.query && this.$route.query.form != '') {
            // 判断当前阅读记录是否已阅读
            if (Boolean(this.$route.query.flag)) {
                this.isAgreement = Boolean(this.$route.query.flag)
                this.agreementText = '已阅读'
                this.form = JSON.parse(this.$route.query.form)
                this.$nextTick(_ => {
                    this.setFlag()
                })
            }
        }
        // 获取字典值
        this.getDic() 
    },
    methods: {
        setFlag() {
            this.isAgreement = true
            this.agreementText = '已阅读'
        },
        // 获取字典值
        getDic() {
            // 个人信息
            getInfo().then(res => {
                let data = res.data
                this.form.creditCode = data.ysEnterpriseInformation.creditCode // 信用代码
                this.form.custName = data.ysEnterpriseInformation.companyName // 公司名称
            })
            // 银行列表
            getDicData('sys_bank_type').then(res => {
                this.bankColumns = res.data
            })
        },
        // 表单提交
        onFailed() {
            this.$refs.form.validate().then(_ => {
                if (!this.isAgreement) {
                    this.$toast("请点击并阅读下方协议")
                    return
                }
                this.addForm()
            }).catch(_ => {

            })
        },
        // 新增
        addForm() {
            this.loading = true
            // 添加一条数据
            saveCompanyQl(this.form).then(res => {
                if (res.code == 200) {
                    this.$toast('新增成功')
                    const toast = this.$toast.loading({
                        duration: 3000,
                        forbidClick: true,
                        message: '受理中...'
                    })
                    setTimeout(_ => {
                        toast.clear()
                        this.loading = false
                        this.$store.commit('CHANGE_TAB_ACTIVE', '/home/task')
                        this.$router.replace({name: 'task', params: {active: 1}})
                    }, 3000)
                } else {
                    this.$toast(res.msg)
                    this.loading = false
                }
            }, error => {
                this.loading = false
                this.$toast('新增失败')
            })
        },
        onBankClick() {
            this.showBank = true
        },
        // 银行选择
        onBankCnfirm(val) {
            this.showBank = false
            this.form.loanBank = val.dictLabel
            this.form.bankId = val.dictValue
        },
        // 阅读协议
        onRading() {
            this.$router.push({
                name: 'qlPre',
                query: {
                    form: JSON.stringify(this.form)
                }
            })
        },
    }
}
</script>

<style lang='scss' scoped>
/deep/
input.van-field__control::-webkit-input-placeholder {
    color: #b3b3b3 !important;
}
/deep/
textarea.van-field__control::-webkit-input-placeholder {
    color: #b3b3b3 !important;
}
.form-box{
    text-align: center;
    min-height: 98vh;
    // background-image: linear-gradient(to bottom , #fff, #D5E9F7);
    .form-title{
        width: 100%;
        font-size: 30px;
        padding: 20px 0;
        background: #D1ECF5;
        color: #3D658E;
        overflow: hidden;
    }
    .form-boxs{
        width: 90vw;
        height: 100%;
        border-radius: 20px;
        box-shadow: 0 0 25px rgba(130, 130, 133, 0.43);
        overflow: hidden;
        margin-left: 5vw;
        margin-top: 30px;
    }
    .agreement{
        margin-top: 20px;
        font-size: 28px;
        color: #1D6EAD;
        width: 100%;
        text-align: center;
        .text-rad-f{
            color: #808080;
        }
        .text-rad-t{
            color: #00C01F;
        }
    }
}
.checkbox{
    margin-bottom: 28px;
}
/deep/
.van-field__control{
    color: #323233 !important;
}
</style>