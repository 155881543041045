<!--
* @Description: 个人农信社新增
* @Author: sunchunchun
* @CreateDate: 2023/05/30 18:43:02
* @FileName: perEdit.vue
!-->
<template>
    <div class="cardForm" style="width:94%;margin-left:3%;border-radius: 8px 8px 8px 8px;">
        <div style="display:flex">
            <img style="width:29px;height:29px;margin-left:15px;margin-top:12px"
                src="../../assets/images/personinfo.png">
            <div style="width: 100%;font-size: 17px;padding: 20px 0;background: none;color: #000;
    overflow: hidden;margin-top:-1%;margin-left:3%;">基本信息填写</div>
        </div>
        <van-form ref="form" style="padding: 0 10px;background: #fff;" validate-first>
            <!-- 姓名 -->
            <van-field v-model="form.name" name="姓名" label="姓名" prop="name" placeholder="请输入姓名" disabled required />
            <!-- 身份证号码 -->
            <van-field v-model="form.code" name="身份证号码" label="身份证号码" prop="code" placeholder="请输入身份证号码" disabled
                required />
            <!-- 联系电话 -->
            <van-field v-model="form.contactPhone" name="联系电话" label="联系电话" prop="contactPhone" placeholder="请输入联系电话" disabled required />
            <van-field class="none" readonly clickable name="mainType" v-model="form.mainType" prop="mainType" label="个人主体类型"
                placeholder="请选择个人主体类型" @click="mainType = true" required
                :rules="[{ required: true, message: '请选择个人主体类型' }]" />
            <van-popup v-model="mainType" position="bottom">
                <van-picker show-toolbar :columns="mainTypeList" @confirm="onmainType" @cancel="mainType = false" />
            </van-popup>
            <!-- 经营主体名称 -->
            <van-field right-icon="" v-if="form.busMainType == '2' || form.busMainType == '3'" maxlength="30" class="none"
                prop="busMainName" v-model="form.busMainName" name="busMainName" label="经营主体名称" placeholder="请输入经营主体名称"
                :required="form.busMainType == '2' || form.busMainType == '3' ? true : false"
                :rules="[{ required: form.busMainType == '2' || form.busMainType == '3' ? true : false, message: '请输入经营主体名称' }]">
                <template #button>
                    <van-icon style="font-size:20px;" name="question-o" @click="onViewAlert"></van-icon>
                </template>
            </van-field>
            <!-- 合作银行 -->
            <van-field class="none" readonly clickable name="guarComp" v-model="form.bank" prop="bank" label="合作银行"
                placeholder="请选择合作银行" @click="showBank = true" required
                :rules="[{ required: true, message: '请选择合作银行' }]" />
            <van-popup v-model="showBank" position="bottom">
                <van-picker show-toolbar :columns="bankColumns" @confirm="onBankCnfirm" @cancel="showBank = false" />
            </van-popup>
        </van-form>
        <div style="margin-top: 20px;font-size: 15px;color: #BEA588;width: 100%;text-align: center;">
            <span @click="onRading"><span>《个人信息授权书》</span><span
                    v-if="form.busMainType =='2'||form.busMainType =='3' ">《企业信息授权书》</span><span>《个人敏感信息授权书》</span></span>
            <span :style="isAgreement ? 'color: #8D8D8D' : 'color: #D10909'">{{ agreementText }}</span>
        </div>
        <div style="margin: 20px 16px 0 16px;padding-bottom: 20px;">
            <van-button round block type="info" @click="onFailed"
                style="background:#BEA588;border:1px solid #BEA588;font-size: 16px;width:318px;margin:0 auto">提交</van-button>
        </div>
    </div>
</template>

<script>
import { getInfo } from "@/api/home/index"
import { getBanklist, doPolicy } from "@/api/guarantee/guarantee"
import { getDicData } from "@/api/home/dicData"
import { mapGetters } from "vuex"
import { isCompany } from "@/api/user/index"
export default {
    name: "qlBankEdit",
    data() {
        return {
            agreementText: '未阅读', // 协议默认text
            isAgreement: false, // 是否阅读class样式判断
            form: {
                name: '', // 姓名
                code: '', // 身份证号
                contactPhone: '', // 联系电话
                busMainType: '',
                busMainName: '',
                bank: '',
                mainType: '',
                submitPath: '',
                parentId: ''
            },
            labelForm: {
                bank: '',
                busMainType: '',
                mainType: '',
            },
            mainTypeList: [],
            bankColumns: [], // 贷款银行list
            showBank: false,
            // mainTypeColumns: [], // 贷款银行list
            mainType: false,
            parentId: ''
        }
    },
    computed: {
        ...mapGetters(['phone', 'userTypes'])
    },
    created() {
        if (this.$route.query.source == '0') {
            this.parentId = this.$route.query.parentId
            this.form.parentId = this.$route.query.parentId
        } else {
            const former = JSON.parse(this.$route.query.form)
            this.parentId = former.parentId
            this.form.parentId = former.parentId
        }
        if (this.$route.query && this.$route.query.form != '') {
            // 判断当前阅读记录是否已阅读
            if (Boolean(this.$route.query.flag)) {
                this.isAgreement = Boolean(this.$route.query.flag)
                this.agreementText = '已阅读'
                this.form = JSON.parse(this.$route.query.form)
                this.$nextTick(_ => {
                    this.setFlag()
                })
            }
        }
        // 获取字典值
        this.getDic()
        this.onGetbankList()
    },
    methods: {
        //查询合作银行
        onGetbankList() {
            getBanklist({ parentId: this.parentId, pageSize: 100, pageNum: 1, miniShow: 1 }).then(res => {
                for (let row of res.rows) {
                    row.text = row.name
                    row.keyId = row.dictValue
                }
                this.bankColumns = res.rows
            })
        },
        // 查看经营主体提示
        onViewAlert() {
            this.$dialog.alert({
                message: '个人主体类型为小微企业主、个体工商户时，填写用款小微企业或个体工商户全称！'
            })
        },
        setFlag() {
            this.isAgreement = true
            this.agreementText = '已阅读'
        },
        // 个人主体类型
        onMainType(val) {
            this.isAgreement = false
            this.agreementText = '未阅读'
            if (val == 1) {
                this.form.busMainName = ''
            }
        },
        // 获取字典值
        getDic() {
            // 个人信息
            getInfo().then(res => {
                let data = res.data
                this.form.contactPhone = data.ysOpenUser.phoneNumber
                if (data.ysLegalpersonInformation) {
                    this.form.name = data.ysLegalpersonInformation.name
                }
                if (data.ysLegalpersonInformation) {
                    this.form.code = data.ysLegalpersonInformation.certNo
                }
                this.form.type = this.userTypes
            })
            // 个人主体类型
            getDicData('per_type').then(res => {
                this.mainTypeList = []
                // 指定工信部行业类别list回显参数
                for (let row of res.data) {
                    row.text = row.dictLabel
                    row.keyId = row.dictValue
                }
                this.mainTypeList = res.data
            })
        },
        // 表单提交
        onFailed() {
            this.$refs.form.validate().then(_ => {
                if (!this.isAgreement) {
                    this.$toast("请点击并阅读下方协议")
                    return
                }
                // 个人主体类型 为 小微企业主或个体工商户的时候检测经营主体名称是否存在
                if (this.form.busMainType == '2' || this.form.busMainType == '3') {
                    // 判断当前经营主体是否存在
                    isCompany({ name: this.form.busMainName }).then(res => {
                        if (res.code == 200) {
                            this.addForm()
                        } else {
                            this.$toast("经营主体名称不存在,请核实确认！")
                        }
                    })
                } else {
                    this.addForm()
                }
            }).catch(() => {
                this.$toast("信息未填写完整！")
            })
        },
        // 新增
        addForm() {
            // 添加一条数据
            doPolicy(this.form).then(res => {
                if (res.code == 200) {
                    this.$toast('新增成功')
                    const toast = this.$toast.loading({
                        duration: 3000,
                        forbidClick: true,
                        message: '受理中...'
                    })
                    setTimeout(_ => {
                        toast.clear()
                        this.$store.commit('CHANGE_TAB_ACTIVE', '/home/task')
                        this.$router.push({ name: 'task', params: { active: 1 } })
                    }, 3000)
                } else {
                    this.$toast(res.msg)
                }
            }, error => {
                this.$toast('新增失败')
            })
        },
        // 阅读协议
        onRading() {
            console.log(JSON.stringify(this.form))
            this.$router.push({
                name: 'guaranteePre',
                query: {
                    form: JSON.stringify(this.form)
                }
            })
        },
        // 银行选择后
        onBankCnfirm(val) {
            this.showBank = false
            // 回显参数
            this.form.bank = val.name
            this.form.submitPath = val.submitPath
            // form 提交code
            this.labelForm.bank = val.dictValue
        },
        onmainType(val) {
            this.mainType = false
            // 回显参数
            this.form.busMainType = val.dictValue
            this.form.mainType = val.dictLabel
            // form 提交code
            this.labelForm.busMainType = val.dictValue
            console.log(this.form.busMainType)
        }

    }
}
</script>

<style lang='scss' scoped>
/deep/ input.van-field__control::-webkit-input-placeholder {
    color: #b3b3b3 !important;
}

/deep/ textarea.van-field__control::-webkit-input-placeholder {
    color: #b3b3b3 !important;
}

.form-box {
    margin-top: 10%;
    text-align: center;
    min-height: 98vh;

    // background-image: linear-gradient(to bottom , #fff, #D5E9F7);
    .formTitle {
        width: 100%;
        font-size: 30px;
        padding: 20px 0;
        background: none;
        color: #000;
        overflow: hidden;
        margin-top: 5%;
        margin-left: 5%;
    }

    .form-boxs {
        width: 90vw;
        height: 100%;
        border-radius: 20px;
        box-shadow: 0 0 25px rgba(130, 130, 133, 0.43);
        overflow: hidden;
        margin-left: 5vw;
        margin-top: 30px;
    }

    .agreement {
        margin-top: 20px;
        font-size: 28px;
        color: #BEA588;
        width: 100%;
        text-align: center;

        .text-rad-f {
            color: #808080;
        }

        .text-rad-t {
            color: #00C01F;
        }
    }
}

.checkbox {
    margin-bottom: 28px;
}

/deep/ .van-field__control {
    color: #323233 !important;
}


.input-duble {
    display: flex;
    justify-content: space-around;
}
</style>