import emergLoantransfer from '../../pages/jinrongServe/emergLoantransfer.vue'
import emergLoantransferNo from '../../pages/jinrongServe/emergLoantransferNo.vue'
import supplyChain from '../../pages/jinrongServe/supplyChain.vue'
import supplyChainNo from '../../pages/jinrongServe/supplyChainNo.vue'
import financing from '../../pages/jinrongServe/financing.vue'
import financingNo from '../../pages/jinrongServe/financingNo.vue'
import noFinancing from '../../pages/jinrongServe/noFinancing.vue'
import noFinancingNo from '../../pages/jinrongServe/noFinancingNo.vue'
/**
 * 融资服务产品
 */
export const financingRoute = {
    name: "financing",
    path: "/financing",
    meta:{
        title:'担保业务',
        keepAlive: true
    },
    component: financing,
    
}
/**
 * 融资服务产品 未登录
 */
export const financingNoRoute = {
    name: "financingNo",
    path: "/financingNo",
    meta:{
        title:'担保业务',
        keepAlive: true
    },
    component: financingNo,
    
}

/**
 * 非融资
 */
export const noFinancingRoute = {
    name: "noFinancing",
    path: "/noFinancing",
    meta:{
        title:'保函业务',
        keepAlive: true
    },
    component: noFinancing,
    
}
/**
 * 非融资 未登录
 */
export const noFinancingNoRoute = {
    name: "noFinancingNo",
    path: "/noFinancingNo",
    meta:{
        title:'保函业务',
        keepAlive: true
    },
    component: noFinancingNo,
    
}

/**
 * 应急转贷
 */
export const emergLoantransferRoute = {
    name: "emergLoantransfer",
    path: "/emergLoantransfer",
    meta:{
        title:'应急转贷',
        keepAlive: true
    },
    component: emergLoantransfer,
    
}


export const emergLoantransferNoRoute = {
    name: "emergLoantransferNo",
    path: "/emergLoantransferNo",
    meta:{
        title:'应急转贷',
        keepAlive: true
    },
    component: emergLoantransferNo,
    
}
/**
 * 供应链
 */
export const supplyChainroute = {
    name: "supplyChain",
    path: "/supplyChain",
    meta:{
        title:'供应链金融',
        keepAlive: true
    },
    component: supplyChain,
    
}

 
/**
 * 供应链
 */
export const supplyChainNoroute = {
    name: "supplyChainNo",
    path: "/supplyChainNo",
    meta:{
        title:'供应链金融',
        keepAlive: true
    },
    component: supplyChainNo,
    
}