import perEdit from '../../pages/guarantee/perEdit.vue'
import comEdit from '../../pages/guarantee/comEdit.vue'
import pirText from '../../pages/guarantee/agreement/pirText.vue'
import preText from '../../pages/guarantee/agreement/preText.vue'
import userText from '../../pages/guarantee/agreement/userText.vue'
/**
/**
 * 个人登录
 */
export const perEditRoute = {
    name: "guaranteeperEdit",
    path: "/guaranPerEdit",
    meta:{
        title:'个人',
        keepAlive: true
    },
    component: perEdit,
    
}
/**
/**
 * 企业
 */
export const comEditRoute = {
    name: "guaranteecomEdit",
    path: "/guaranComEdit",
    meta:{
        title:'担保预审',
        keepAlive: true
    },
    component: comEdit,
    
}

export const guaranteePirRoute = {
    name: 'guaranteePir',
    path: 'guaranteePir',
    meta:{
        title:'企业信息授权书',
        keepAlive: true
    },
    component: pirText
}

export const guaranteePreRoute = {
    name: 'guaranteePre',
    path: 'guaranteePre',
    meta:{
        title:'个人信息授权书',
        keepAlive: true
    },
    component: preText
}

export const guaranteeUserRoute = {
    name: 'guaranteeUser',
    path: 'guaranteeUser',
    meta:{
        title:'敏感个人信息授权书',
        keepAlive: true
    },
    component: userText
}