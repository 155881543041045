<!--
* @Description: 
* @Author: sunchunchun
* @CreateDate: 2022/09/21 18:59:12
* @FileName: home.vue
!-->
<template>
    <div class="home-section">
        <!-- 登录后 -->
        <div class="tab-page cant-tab-page" v-if="viewType == 'appuser'">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </div>
        <van-tabbar v-if="viewType == 'appuser'" class="tab-bar" v-model="$store.state.home.active" @change="tabChange">
            <van-tabbar-item name="/home/index">
                <span>首页</span>
                <template #icon="props">
                    <van-image class="van-img-size" v-if="props.active"
                        :src="require('../../assets/images/banner-dbjt.png')" style="background-size: cover;" />
                    <van-image class="van-img-size" v-else :src="require('../../assets/images/banner-dbjt-not.png')"
                        style="background-size: cover;" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="/home/mine">
                <span>我的</span>
                <template #icon="props">
                    <van-image class="van-img-size" v-if="props.active" :src="require('../../assets/images/banner-me.png')"
                        style="background-size: cover;" />
                    <van-image class="van-img-size" v-else :src="require('../../assets/images/banner-me-not.png')"
                        style="background-size: cover;" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
        <!-- 内部登录 -->
        <div class="tab-page cant-tab-page" v-if="viewType == 'custuser'">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </div>
        <van-tabbar v-if="viewType == 'custuser'" class="tab-bar" v-model="$store.state.home.active" @change="tabChange">
            <van-tabbar-item name="/homes/task">
                <span>业务状态</span>
                <template #icon="props">
                    <van-icon v-if="props.active" name="apps-o" />
                    <van-icon v-else name="apps-o" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="/homes/mines">
                <span>我的</span>
                <template #icon="props">
                    <van-icon v-if="props.active" name="user-o" />
                    <van-icon v-else name="user-o" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
        <!-- 登录前 -->
        <div class="tab-page cant-tab-page" v-if="viewType == 'not'">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </div>
        <van-tabbar v-if="viewType == 'not'" class="tab-bar" v-model="$store.state.home.active" @change="tabChange">
            <van-tabbar-item name="/notloggedhome/index">
                <span>首页</span>
                <template #icon="props">
                    <van-image class="van-img-size" v-if="props.active"
                        :src="require('../../assets/images/banner-dbjt.png')" />
                    <van-image class="van-img-size" v-else :src="require('../../assets/images/banner-dbjt-not.png')" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="/notloggedhome/mine">
                <span>我的</span>
                <template #icon="props">
                    <van-image class="van-img-size" v-if="props.active"
                        :src="require('../../assets/images/banner-me.png')" />
                    <van-image class="van-img-size" v-else :src="require('../../assets/images/banner-me-not.png')" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
        <div :class="show ? 'bottom' : 'show-login'" v-show="goLogin == '0' && tokens == undefined">
            <img class="bottomImg" @click="onCloseLogin()" src="../../assets/images/close.png">
            <P class="bottomTitle">登录即享更多服务！</p>
            <van-button class="bottomButton" @click="onGoLogin()">去登录</van-button>
        </div>

    </div>
</template>

<script>
import { mapGetters } from "vuex"
import storage from 'store'
import { judgeCodeValidity } from "@/api/cusManagerlogin/index"
import { getUnList } from "@/api/home/index"
export default {
    name: '',
    computed: {
        ...mapGetters(['userLoginType', 'token', 'goLogin', 'goKjd'])
    },
    components: {},
    data() {
        return {
            id: '', // 扫描二维码后的接收参数
            goosId: '',
            text: '',
            viewType: '',
            show: true,
            tokens: '',
            userTypes: '',
        }
    },
    activated() {
        this.tokens = storage.get('ACCESS_TOKEN')
        // 去登录按钮  刷新后还存在未登录的情况重置显示 按钮 赋值
        if (this.token != '' && this.token != null && this.token != undefined) {
            this.$store.dispatch('setGoLogin', '1')
        } else {

        }
    },
    created() {
        this.userTypes = storage.get('setUserType')
        // 登录后的类型
        if (this.userLoginType) {
            this.viewType = this.userLoginType
        } else {
            this.viewType = storage.get('userLoginType')
        }
        console.log(storage.get('setKJD'), "storage.get('setKJD')")
        // ---------------------------------------------------------------------------
        // goKjd  检测是否已经进入过科技贷的新增页面  如果进入过则为设置为false
        // 检测是否存在地址栏参数，如果存在再去深层检测有无"q"的参数，进行扫描二维码后的操作
        if (storage.get('setKJD')) {
            var query = this.$route.query
            if (query && query.q != '' && query.q != null && query.q != undefined) {
                this.text = query.q
                this.id = this.getQueryString(query.q, 'id') // get codeId
                this.goosId = this.getQueryString(query.q, 'goosId') // get codeId
                this.$nextTick(() => {
                    this.getQRCodeMinx(this.id, this.goosId || query.goosId) // 二维码是否有效
                })
            }
            if (query.codeId && query.goosId) {
                this.$nextTick(() => {
                    this.getQRCodeMinx(query.codeId, query.goosId) // 二维码是否有效
                })
            }
        }
        // ---------------------------------------------------------------------------
    },
    methods: {
        /**
         * 如果通过扫描二维码进入执行当前二维码是否有效
         */
        getQRCodeMinx(codeId, goosId) {
            console.log(codeId, goosId)
            // 检测二维码有效性
            judgeCodeValidity({ codeId }).then(res => {
                if (res && res.code == 200) {
                    if (res.data == 1) {
                        // 登录情况直接跳转科技贷页面
                        if ((this.token != '' && this.token != null && this.token != undefined) || (this.tokens != undefined && this.tokens != '')) {
                            if (this.userTypes == '2') {
                                if (storage.get('REAL_NAME') == 0) {
                                    this.$dialog.confirm({
                                        confirmButtonText: '前往实名认证',
                                        confirmButtonColor: '#1989fa',
                                        message: '当前帐号没有实名认证，请完成实名认证后在进行预审',
                                    }).then(() => {
                                        // 进入对应账号的认证页面
                                        if (this.userTypes == 2) {
                                            this.$router.push({ name: 'comHome' })
                                        } else {
                                            this.$router.push({ name: 'preHome' })
                                        }
                                    }).catch(() => {
                                        this.$store.dispatch('setKJD', false)
                                        storage.set('setKJD', false)

                                    });
                                } else {
                                    // 检测是否已经有了历史记录
                                    getUnList(this.page).then(res => {
                                        if (res.code == 200) {
                                            // 判断是否存在数据
                                            let len = 0
                                            for (let row of res.data.rows) {
                                                if (row.productType == 7) {
                                                    len++
                                                }
                                            }
                                            if (len == 0) {
                                                if (this.userTypes == '1') {
                                                    this.$dialog({
                                                        message: '该产品仅适用于企业用户，个人用户无权申请！',
                                                    })
                                                    return false
                                                }
                                                this.$dialog.confirm({
                                                    confirmButtonText: '同意并继续',
                                                    cancelButtonText: '不同意',
                                                    title: "用户隐私保护提示",
                                                    confirmButtonColor: '#1989fa',
                                                    message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。`,
                                                }).then(() => {
                                                    // 跳转新增页面
                                                    this.$router.push({
                                                        path: '/ldScience/edit',
                                                        query: {
                                                            codeId,
                                                            goosId: goosId,
                                                            prodId: goosId,
                                                        }
                                                    })
                                                }).catch(() => {
                                                    this.$store.dispatch('setKJD', false)
                                                    storage.set('setKJD', false)
                                                });
                                                return false
                                            }
                                            // 如果存在数据进行提示 是否查看记录
                                            if (len > 0) {
                                                this.$dialog.confirm({
                                                    confirmButtonText: '查看申请记录',
                                                    cancelButtonText: '取消',
                                                    confirmButtonColor: '#1989fa',
                                                    message: '该产品存在申请中的业务',
                                                }).then(() => { // 查看历史记录
                                                    this.$router.push({ name: 'task' })
                                                }).catch(() => {
                                                    this.$store.dispatch('setKJD', false)
                                                    storage.set('setKJD', false)
                                                })
                                            }
                                        } else {
                                            this.$toast(res.msg)
                                        }
                                    })
                                }
                                // this.$router.push({
                                //     path: "/ldScience/edit",
                                //     query: {
                                //         codeId,
                                //         goosId: goosId,
                                //         prodId: goosId,
                                //     }
                                // })
                            } else {
                                this.$dialog({
                                    message: '该产品仅面向企业用户！',
                                }).then(() => {
                                    this.$store.dispatch('setKJD', false)
                                    storage.set('setKJD', false)
                                    // 确认逻辑
                                })
                            }
                            // 未登录进login
                        } else {
                            // 防止回退
                            this.$router.replace({
                                path: "/login",
                                query: {
                                    codeId: codeId,
                                    goosId: goosId,
                                }
                            })
                        }
                    } else {
                        // 二维码失效
                        this.$dialog({
                            message: '二维码失效，请重新扫描！',
                        }).then(() => {
                            // 确认逻辑
                            this.$store.dispatch('setKJD', false)
                            storage.set('setKJD', false)
                        })
                    }
                } else {
                    this.$toast.fail('系统异常请联系管理员')
                }
            }).catch(() => {
                this.$toast.fail('系统异常请联系管理员')
            })
        },
        getStr(url, key) {
            let urls = url
            let urlStr = new URLSearchParams(urls)
            return urlStr.get(key)
        },
        /**
         * 获取当前地址内的参数
         * @param {String} url url
         * @param {String} name 获取url内参数的key
         */
        getQueryString(url, name) {
            let query_string = url
            let obj = {}
            if (query_string.indexOf('?') == -1) return null // 没有参数直接返回
            let index = query_string.indexOf('?') // 当前位数
            let dataStr = query_string.substr(index + 1) // 参数str
            let dataArr = dataStr.split('&') // 拆分 rreturn arr
            dataArr.forEach(item => {
                if (item.indexOf('=') == -1) {
                    obj[item] = undefined
                } else {
                    let innerArrIndex = item.indexOf('=')
                    let key = item.substring(0, innerArrIndex)
                    let value = item.substr(innerArrIndex + 1)
                    obj[key] = value
                }
            })
            return obj[name]
        },
        // 根据每一次点击底部 tag 进行高亮展示
        tabChange(active) {
            this.$store.commit('CHANGE_TAB_ACTIVE', active)
            this.$router.push({
                path: active
            })
        },
        /**
         * 去登录
         */
        onGoLogin() {
            this.$router.push('/login')
        },
        /**
         * 关闭去登录
         */
        onCloseLogin() {
            this.show = false
            this.$store.dispatch('setGoLogin', '1')
        },
    }
}
</script>
<style scoped lang="scss">
.home-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .tab-page {
        // background-image: linear-gradient(to bottom , #fff, #D5E9F7);

        &.cant-tab-page {
            height: calc(100% - 50px);
            overflow: hidden;
        }
    }

    .tab-bar {
        position: relative;
        padding-bottom: 25px;
    }
}

.show-login {
    display: none;
}

.bottom {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    width: 88%;
    height: 80px;
    bottom: 125px;
    z-index: 1000;
    border-radius: 20px;
    left: 50%;
    margin-left: -44%;

    .bottomImg {
        display: inline-block;
        widows: 28px;
        height: 28px;
        margin-left: 20px;
        margin-top: 25px;
    }

    .bottomTitle {
        position: fixed;
        display: inline-block;
        text-align: center;
        width: 250px;
        height: 50px;
        font-weight: 400;
        font-size: 25px;
        text-align: left;
        color: #ffffff;
        line-height: 34px;
        margin-left: 30px;
    }

    .bottomButton {
        position: fixed;
        border-radius: 28px;
        color: #000000;
        text-align: center;
        width: 150px;
        height: 50px;
        font-weight: 400;
        font-size: 25px;
        line-height: 60%;
        text-align: left;
        margin-left: 430px;
        border-color: #E6D2BC;
        // padding-top: -30px;
        margin-top: 15px;
        background: #E6D2BC;

    }
}

.van-img-size {
    width: 20px;
    height: 20px;
}
</style>