<!--
* @Description: 企业农信社新增
* @Author: sunchunchun
* @CreateDate: 2023/05/30 18:42:48
* @FileName: comEdit.vue
!-->
<template>
    <div class="cardForm" style="width:94%;margin-left:3%;border-radius: 8px 8px 8px 8px;">
        <div style="display:flex">
            <img style="width:29px;height:29px;margin-left:15px;margin-top:12px" src="../../assets/images/personinfo.png">
            <div style="width: 100%;font-size: 17px;padding: 20px 0;background: none;color: #000;overflow: hidden;margin-top:-1%;margin-left:3%;">基本信息填写</div>
        </div>
            <van-form ref="form" style="padding: 0 10px;background: #fff;" validate-first>
                <!-- 企业名称 -->
                <van-field
                    label="企业名称"
                    v-model="form.name"
                    type="textarea"
                    placeholder="请输入企业名称"
                    disabled
                    required
                />
                <!-- 统一社会信用代码 -->
                <van-field
                    label="统一社会信用代码"
                    v-model="form.code"
                    placeholder="请输入统一社会信用代码"
                    disabled
                    required
                />
                <!-- 合作银行 -->
            <van-field class="none" readonly clickable name="guarComp" v-model="form.bank" label="合作银行"
                placeholder="请选择合作银行" @click="showBank = true" required :rules="[{ required: true, message: '请选择合作银行' }]" />
            <van-popup v-model="showBank" position="bottom">
                <van-picker show-toolbar :columns="bankColumns" @confirm="onBankCnfirm" @cancel="showBank = false" />
            </van-popup>
            </van-form>
            <div style="margin-top: 20px;font-size: 15px;color: #BEA588;width: 100%;text-align: center;">
            <span @click="onRading"><span>《个人信息授权书》</span><span v-if="userTypes==2">《企业信息授权书》</span><span>《个人敏感信息授权书》</span></span>
            <span :style="isAgreement?'color: #8D8D8D':'color: #D10909'">{{ agreementText }}</span>
        </div>
        <div style="margin: 20px 16px 0 16px;padding-bottom: 20px;">
            <van-button round block type="info" style="background:#BEA588;border:1px solid #BEA588;font-size: 16px;width:318px;margin:0 auto" @click="onFailed">提交</van-button>
        </div>
    </div>
</template>

<script>
import { getInfo } from "@/api/home/index"
import { getBanklist,doPolicy } from "@/api/guarantee/guarantee"
import { mapGetters } from "vuex"
export default {
    data () {
        return {
            agreementText: '未阅读', // 协议默认text
            isAgreement: false, // 是否阅读class样式判断
            form: {
                companyName: '', // 公司名称
                code: '', // 信用代码
                bank:'',
                submitPath:'',
                parentId:''
            },
            bankColumns: [], // 贷款银行list
            showBank:false,
            labelForm: {
                bank: '',
            },
            parentId:''
        }
    },
    computed: {
        ...mapGetters(['phone', 'userTypes'])
    },
    created() {
        if(this.$route.query.source=='0'){
            this.parentId=this.$route.query.parentId
            this.form.parentId=this.$route.query.parentId
        }else{
            const former=JSON.parse(this.$route.query.form)
            this.parentId=former.parentId
            this.form.parentId=former.parentId
        }
        if (this.$route.query.goodsId) {
            this.form.goodsId = this.$route.query.goodsId
        }
        if (this.$route.query && this.$route.query.form != '') {
            // 判断当前阅读记录是否已阅读
            if (Boolean(this.$route.query.flag)) {
                this.isAgreement = Boolean(this.$route.query.flag)
                this.agreementText = '已阅读'
                this.form = JSON.parse(this.$route.query.form)
                this.$nextTick(_ => {
                    this.setFlag()
                })
            }
        }
        // 获取字典值
        this.getDic() 
        this.onGetbankList()
    },
    methods: {
        //查询合作银行
        onGetbankList(){
            getBanklist({parentId:this.parentId,pageSize: 100,pageNum: 1,miniShow: 1}).then(res =>{
                for (let row of res.rows) {
                    row.text = row.name
                    row.keyId = row.dictValue
                }
                this.bankColumns = res.rows
            })
        },
        setFlag() {
            this.isAgreement = true
            this.agreementText = '已阅读'
        },
        // 获取字典值
        getDic() {
            // 个人信息
            getInfo().then(res => {
                let data = res.data
                if(data.ysEnterpriseInformation){
                    this.form.code = data.ysEnterpriseInformation.creditCode // 信用代码
                this.form.name = data.ysEnterpriseInformation.companyName // 公司名称
                this.form.type = this.userTypes
                }
            })
        },
        // 表单提交
        onFailed() {
            this.$refs.form.validate().then(_ => {
                if (!this.isAgreement) {
                    this.$toast("请点击并阅读下方协议")
                    return
                }
                this.addForm()
            }).catch(_ => {
                this.$toast("信息未填写完整！")
            })
        },
        // 新增
        addForm() {
            // 添加一条数据
            doPolicy(this.form).then(res => {
                if (res.code == 200) {
                    this.$toast('新增成功')
                    const toast = this.$toast.loading({
                        duration: 3000,
                        forbidClick: true,
                        message: '受理中...'
                    })
                    setTimeout(_ => {
                        toast.clear()
                        this.$store.commit('CHANGE_TAB_ACTIVE', '/home/task')
                        this.$router.push({name: 'task', params: {active: 1}})
                    }, 3000)
                } else {
                    this.$toast(res.msg)
                }
            }, error => {
                this.$toast('新增失败')
            })
        },
        // 阅读协议
        onRading() {
            this.$router.push({
                name: 'guaranteePre',
                query: {
                    form: JSON.stringify(this.form)
                }
            })
        },
        // 点击触发展开银行列表选择
        onBankClick() {
            if (this.form.guarComp.length == 0) {
                this.$toast('请选择意向担保公司')
                return
            }
            this.showBank = true
        },
        // 银行选择后
        onBankCnfirm(val) {
            this.showBank = false
            // 回显参数
            this.form.bank = val.name
            this.form.submitPath = val.submitPath
            // form 提交code
            this.labelForm.bank = val.dictValue
        },
    }
}
</script>

<style lang='scss' scoped>
/deep/
input.van-field__control::-webkit-input-placeholder {
    color: #b3b3b3 !important;
}
/deep/
textarea.van-field__control::-webkit-input-placeholder {
    color: #b3b3b3 !important;
}
.form-box{
    margin-top:10%;
    text-align: center;
    min-height: 98vh;
    // background-image: linear-gradient(to bottom , #fff, #D5E9F7);
    .form-title{
        width: 100%;
        font-size: 30px;
        padding: 20px 0;
        background: #fff;
        color: #8B6841;
        overflow: hidden;
    }
    .form-boxs{
        width: 90vw;
        height: 100%;
        border-radius: 20px;
        box-shadow: 0 0 25px rgba(130, 130, 133, 0.43);
        overflow: hidden;
        margin-left: 5vw;
        margin-top: 30px;
    }
    .agreement{
        margin-top: 20px;
        font-size: 28px;
        color: #BEA588;
        width: 100%;
        text-align: center;
        .text-rad-f{
            color: #808080;
        }
        .text-rad-t{
            color: #00C01F;
        }
    }
}
.checkbox{
    margin-bottom: 28px;
}
/deep/
.van-field__control{
    color: #323233 !important;
}
</style>