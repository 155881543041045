/**
 * 齐鲁银行路由列表
 */
import Home from '../../pages/home/home.vue'
import qlbank from '../../pages/qlBank/index.vue'

const qlBankRouter = {
    name: 'home',
    path: '/home',
    component: Home,
    children: [
        // 鲁岗贷首页
        {
            name: 'qlbank',
            meta:{
                title:'首页',
                keepAlive: true
            },
            path: 'qlbank',
            component: qlbank
        }
    ]
}


export default qlBankRouter