<!--
* @Description: 信息填写
* @Author: zhaogeng
* @CreateDate: 2024/03/14 19:23:13
* @FileName: info.vue
!-->
<template>
    <div class="cusman-bac-oner">
        <div class="cusman-bac-in">
            <img src="../../assets/images/cusminer.png" class="img-logo" style="width: 70px;height: 70px;margin:6px 152px -3px 151px">
            <div class="name">{{ info.managerName }}</div>
            <div class="tag">客户经理</div>
            <div class="cardForm" style="width:92%;margin-left:4%;margin-top:3%;">
                <div class="footer ">
                    <ul class="ul">
                        <li class="li" >
                            <span class="infos label" style="font-size: 14px;">手机号：</span>
                            <span class="infos" style="font-size: 14px;">{{ info.phoneNumber }}</span>
                        </li>
                        <li class="li">
                            <span class="infos label" style="font-size: 14px;">身份证号：</span>
                            <span class="infos" style="font-size: 14px;">{{ info.certNo }}</span>
                        </li>
                        <li class="li" >
                            <span class="infos label" style="font-size: 14px;">所属机构：</span>
                            <span class="infos" style="font-size: 14px;">{{ info.deptName }}</span>
                        </li>
                    </ul>
                </div>
                <van-button @click="onLogOut" class="ant-btn" style="font-size:14px">退出登录</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { 
    cusInfo, // 个人信息
} from "@/api/cusManagerlogin/index"
export default {
    // 组件注册
    components: {},
    data() {
        return {
            info: {},
        }
    },
    created() {
        cusInfo().then(res => {
            let data = res.data
            console.log(data)
            this.info = data
        })
    },
    mounted() { },
    methods: {
        // 退出
        onLogOut() {
            this.$dialog.confirm({
                confirmButtonText: '确认',
                cancelButtonText: "取消",
                message: '是否要退出账号',
            }).then(() => {
                this.$store.dispatch('Logout').then(() => {
                    window.location.reload()
                });
            }).catch(() => {
            });
        },
    }
}
</script>

<style lang='scss' scoped>
.cusman-bac-oner {
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/images/cusmanger.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.cusman-bac-in{
    padding-top: 176px;
    width: 100%;
    height: 100vh;
    text-align: center;
}
// .img-logo{
//     width: 150px;
//     height: 150px;
// }

.user-bac-one {
    background-image: none
}

.topimg {
    width: 150px;
    height: 150px;
    margin-left: 305px;
    margin-top: 77px;
}

.name{
    height: 24px;
    font-weight: 500;
    font-size: 40px;
    color: #212121;
    margin-top: 32px;
}
.tag{
    width: 160px;
    height: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 26px;
    color: #8B6841;
    background: #EFE8DC;
    border:1px solid rgba(255,255,255,0.5);
    border-radius: 25px;;
    margin-top: 46px;
    margin-left:297px;
    padding-top:2px;
}

.cardForm {
    width: 100%;
    //height: auto;
    margin-top: 30%;
    border: 1px;
    border-radius: 20px;
    box-shadow: 0px -6px 4px 0px rgba(218, 205, 189, 0.2);
    background-color: white;
}
.footer{
        padding: 20px 40px;
        font-weight:400;
        .ul{
            margin: 0;
            padding: 0;
            .li{
                text-align: left;
                padding: 20px 0;
                font-size: 30px;
                line-height: 30px;
                margin: 20px 0 0 0;
                border-bottom: 1px solid rgb(225, 225, 225);
                .infos{
                    display: inline-block;
                }
                .label{
                    width: 30%;
                }
                .img-icon{
                    width: 50px;
                    height: 50px;
                    margin-right: 10px;
                    vertical-align: middle;
                }
                .left-icon{
                    margin-right: 40px;
                }
                .icon{
                    float: right;
                    font-weight:400;
                }
            }
        }
    }
.ant-btn{
    width:600px;
    height: 80px;
    border-radius: 50px;
    background: #BEA588;
    margin-top: 50px;
    font-size: 30px;
    color:#ffffff;
}
</style>