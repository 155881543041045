import store from '../store/index'

/**
 * 倒计时
 * @param { Number } num
 * 
 */
export function setTime (num) {
    if (num) {
        let sumNum = Number(num)
        let time = setInterval(() => {
            sumNum -= 1
            if (sumNum == 0) {
                clearInterval(time)
                return 0
            }
            return sumNum
        }, 1000);
    } else {
        return false
    }
}
/**
 * 手机号脱敏
 * @param { Number } num
 */
 export function setPhoneEncr (num) {
    var len = num.length
    if (len && len > 4) {
        return num.substring(0, 3) + '****' + num.substring(len - 4, len)
    }
}
/**
 * 身份证号脱敏
 * @param { Number } num
 */
 export function idCard (num) {
    if (num) {
        let id = num + ''
        return id.replace(/^(.{6})(?:\d+)(.{4})$/,  "\$1****\$2")
    }
}
/**
 * 保留两位小数
 * @param { Number } num
 */
 export function numTow (num) {
    if (num) {
        var reg=  /^(([1-9]{1}\d*)|(0{1}))(\.\d{2})$/;
        return reg.test(num)
    }
}

/**
 * 获取屏幕关闭了几秒
 * @param { Number } num 关闭前还剩余多少秒
 * @param { Number } type 1 = 个人登录(perLoginTime)  2 = 企业登录(entLoginTime)  3 = 个人注册(perRegTime)  4 = 企业注册(entRegTime)
 */
export function getTimeSum (num, type) {
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    if (isiOS) {
        // ios
        let start
        let end
        let num = store.state.login.perLoginTime
        document.addEventListener("visibilitychange", () => {
            if (document.hidden) {
                // 屏幕关闭
                start = new Date().getTime()
            } else {
                setTimeout(() => {
                    // 屏幕显示
                    end = new Date().getTime()
                    return num - Math.round((end  - start)  / 1000)
                })
            }
        })
    } else {
        // andriod
        return false
    }
}
/**
 * 判断是否为空
 * @param { String } value
 */
export function validateNull (value) {
    if (typeof value == 'boolean') {
        return false
    }
    if (typeof value == 'number') {
        return false
    }
    if (value instanceof Array) {
        if (value.length == 0) return true
    } else if (value instanceof Object) {
        if (JSON.stringify(value) == '{}') return true
    } else {
        if (value == 'null' || value == null || value == 'undefined' || value == undefined || value == '') {
            return true
        } else {
            return false
        }
    }
    return false
}
/**
 * 判断身份证是否正确
 * @param { String } code
 */
export function detectionIdCard(code) {
    let list = [];
    let result = true;
    let msg = '';
    var city = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外",
    }
    if (!validateNull(code)) {
        if (code.length == 18) {
            if (!code || !/(^\d{18}$)|(\d{17}(\d|X|x)$)/.test(code)) {
                msg = '证件号码格式错误！';
            } else if (!city[code.substr(0, 2)]) {
                msg = '地址编码错误！';
            } else {
                // 18位身份证需要验证最后一位校验位
                code = code.split('');
                // 加权因子
                var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
                // 校验位
                var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2, 'x'];
                var sum = 0;
                var ai = 0;
                var wi = 0;
                for (var i = 0; i< 17; i++) {
                    ai = code[i];
                    wi = factor[i];
                    sum += ai * wi;
                }
                if (parity[sum % 11] != code[17]) {
                    msg = '证件号码校验位错误';
                } else {
                    result = false;
                }
            }
        } else {
            msg = "证件号码长度不为18位";
        }
    } else {
        msg = "证件号码不能为空";
    }
    list.push(result);
    list.push(msg);
    return list;
}


/**
 * 图片加水印
 * @param { File } file 上传的file文件
 * 
 */
export default function imgWatermark (file) {
    if (file) {
        const blob = new Blob ([file], {type: file.type})
        const url = URL.createObjectURL(blob)
        const img = new Image()
        img.src = url
        img.onload = function () {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0)
            ctx.fillStyle = ''
        }
    } else {
        console.log()
    }
}

/**
 * 保留两位小数 没有则补0
 * @param {Number} num
 * @returns
 */
export function keepStr(num) {
    if (num) {
        let nums = num
        if (typeof(num) == 'String') {
            nums = Number(num)
        }
        let f = Math.round(nums * 100) / 100
        let negative = f < 0
        //负数特殊处理
        if(negative){
            f *= (-1)
        }
        let s = f.toString();
        let rs = s.indexOf('.')
        if (rs < 0) {
            rs = s.length
            s += '.'
        }
        while (s.length <= rs + 2) {
            s += 0
        }
        //负数特殊处理
        if(negative){
            return '-' + s
        }
        if (nums == 0) {
            return '0.00'
        }
        if (nums == '0.0') {
            return '0.00'
        }
        // 调用千位分隔符
        return s
    } else {
        return '0.00'
    }
}

/**
 * 手机号是否正确
 * @param {String} phone 手机号
 * @returns 
 */
export function phoneFun(phone) {
    if (phone) {
        let text = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if (!text.test(phone)) {
            return false
        } else {
            return true
        }
    }
}