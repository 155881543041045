import request from "@/request/index"


/**
 * 企业信用评级获取url
 */
 export function getUrl (data) {
    return request({
        url: '/open/baiwang/getUrl',
        method: 'get',
        params: data
    })
}

/**
 * 企业信用评级 取消授权
 */
export function cancelAuth (data) {
    return request({
        url: '/open/companyRate/cancelAuth',
        method: 'post',
        data
    })
}

/**
 * 企业信用评级 重新认证
 */
export function reAuth (data) {
    return request({
        url: '/open/baiwang/reAuth',
        method: 'post',
        data
    })
}
