<!--
* @Description: 莱商与银行支付card
* @Author: sunchunchun
* @CreateDate: 2023/11/29 15:05:23
* @FileName: index.vue
!-->
<template>
    <div>
		<div>
			<span style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">莱商银行业务(缴费订单)</span>
            <span
                v-for="(row, index) in status"
                :key="index">
                <van-button
                    v-if="item.payStatus == row.label"
                    plain
                    :type="row.color"
                    size="small"
                    style="float:right;height: 20px">
                    {{ row.value }}
                </van-button>
            </span>
			<van-divider />
		</div>
		<div>
			<div class="title">订单时间</div>
			<div class="text-content">{{ getTime(item.createTime) }}&nbsp;</div>
			<van-divider />
		</div>
		<div>
			<div class="title">订单编号</div>
			<div class="text-content">{{ item. orderNo }}&nbsp;</div>
			<van-divider />
		</div>
		<div>
			<div class="title">担保机构</div>
			<div class="text-content">{{ item.orderOrigGuarName }}&nbsp;</div>
			<van-divider />
		</div>
		<div>
			<div class="title">应缴担保费金额(元)</div>
			<div class="text-content">{{ item.guaranteeFee }}&nbsp;</div>
			<van-divider />
		</div>
		<div>
			<div class="title">已缴担保费金额(元)</div>
			<div class="text-content">{{ getNums(item.trnAmt) }}&nbsp;</div>
			<van-divider />
		</div>
		<div>
			<div class="title">待缴担保费金额(元)</div>
			<div class="text-content">{{ getNums(item.unpayAmt) }}&nbsp;</div>
			<van-divider />
		</div>
        
        <div>
            <div class="btn-box">
                <div @click="onPushAmount(item)" class="footer-btn font-family-all"><span>保费缴纳</span></div>
                <div @click="onPushView(item)" class="footer-btn font-family-all"><span>查看订单详情</span></div>
            </div>
        </div>
	</div>
</template>

<script>
import { keepStr } from "@/utils/validate"
export default {
    name: "PreCard",
    props: {
        data: Object
    },
    data() {
        return {
            status: [
                {
                    label: 'N',
                    value: '待缴费',
                    color: 'danger'
                }, {
                    label: 'Y',
                    value: '已⽀付',
                    color: 'primary'
                }, {
                    label: 'R',
                    value: '已退款',
                    color: 'danger'
                }, {
                    label: 'B',
                    value: '部分退款',
                    color: 'danger'
                }, {
                    label: 'D',
                    value: '关单状态',
                    color: 'danger'
                }, {
                    label: 'Q',
                    value: '⼿⼯销账',
                    color: 'danger'
                }, {
                    label: 'I',
                    value: '⼿⼯部分销账',
                    color: 'danger'
                }, {
                    label: 'S',
                    value: '⽀付中（部分⽀付）',
                    color: 'danger'
                }
            ],
            // item data
            item: {
                payStatus: "",
                createTime: "",
                orderN: "",
                orderOrigGuarName: "",
                guaranteeFee: "",
                trnAmt: "",
                unpayAmt: "",
            }
        }
    },
    created() {
        this.item = this.data
    },
    methods: {
        // 补充小数位的00
        getNums(row) {
            return keepStr(row)
        },
        getTime(time) {
            if (time) {
                return this.$moment(time).format('YYYY-MM-DD HH:mm:ss')
            } else {
                return ""
            }
        },
        // 保费缴纳
        onPushAmount(row) {
            
        },
        // 查看详情
        onPushView(row) {
            this.$router.replace({
                path: "/lsBank/PreView",
                query: { id: row.orderId }
            })
        },
    }
}
</script>

<style lang='scss' scoped>
    .title{
        margin-bottom: 20px;
        color: #888888;
    }
    .text-content{
        width: 100%;
        word-break: break-word;
        white-space: pre-line;
    }
    .btn-submit-css{
        display: flex;
        justify-content: space-around;
    }


.btn-box {
    width: 100%;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: space-around;
    .footer-btn {
        font-size: 26px;
        height: 66px;
        width: 40%;
        color: #fff;
        background-image: url('../../../assets/images/newImg/button-homex.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
        /** 立即申请 样式 */
        span {
            font-size: 26px;
            font-family: none;
            width: 200px;
            position: absolute;
            top: 14px;
            left: 50%;
            margin-left: -100px;
        }
    }
}
</style>