import pirText from '../../pages/lsBank/agreement/pirText.vue'
import preText from '../../pages/lsBank/agreement/preText.vue'
import userText from '../../pages/lsBank/agreement/userText.vue'

export const ls = {
    name: 'lsBankEdit',
    path: '/home/edit/lsBank',
    meta:{
        title:'莱商银行业务',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/lsBank/edit.vue'),
}

export const lsCom = {
    name: 'lsBankComEdit',
    path: '/home/comEdit/lsBank',
    meta:{
        title:'莱商银行业务',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/lsBank/comEdit.vue'),
}


export const lsPir = {
    name: 'lsPir',
    path: '/lsBank/lsPir',
    meta:{
        title:'企业信息授权书',
        keepAlive: true
    },
    component: pirText
}

export const lsPre = {
    name: 'lsPre',
    path: '/lsBank/lsPre',
    meta:{
        title:'个人信息授权书',
        keepAlive: true
    },
    component: preText
}

export const lsUser = {
    name: 'lsUser',
    path: '/lsBank/lsUser',
    meta:{
        title:'敏感个人信息授权书',
        keepAlive: true
    },
    component: userText
}