<!--
* @Description: 客户经理-我的二维码
* @Author: zhaogeng
* @CreateDate: 2024/03/15 11:33:08
* @FileName: qrCode.vue
!-->
<template>
    <div class="cusman-bac-oner">
        <div class="cardForm">
            <div class="title" style="font-size: 22px;margin-top: 34px">{{ proname}}</div>
            <div class="text" style="font-size: 16px;margin: 5px 0 15px 0">请客户使用微信扫描二维码</div>
            <div class="border" ref="border" style="width: 251px; height: 251px;">
                <QRCode :lng="lng" ref="qrcode"></QRCode>
            </div>
            <div class="bottom" @click="refresh()">
                <img src="../../assets/images/refresh.png" class="bottomImg">
                <span style="font-size: 14px" class="bottomText">刷新二维码</span>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from "@/pages/cusManagerlogin/components/QRCode"
export default {
    // 组件注册
    components: {
        QRCode
    },
    data() {
        return {
            lng: 0,
            proname:undefined
        }
    },
    created() {
        this.proname = this.$route.query.name
    },
    mounted() {
        this.lng = this.$refs.border.offsetHeight
        console.log(this.lng)
    },
    methods: {
        refresh(){
            this.$refs.qrcode.onLoad()
        }
    }
}
</script>

<style lang='scss' scoped>
.cusman-bac-oner {
    background-image: url('../../assets/images/cusmanger.png');
    width: 100%;
    height: 100%;
    padding-top: 30px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.cardForm {
    width: 92%;
    margin: 12% auto 0;
    border: 1px;
    border-radius: 20px;
    box-shadow: 0px -6px 4px 0px rgba(218, 205, 189, 0.2);
    background-color: white;
    overflow: hidden;
}
.title {
    font-size: 60px;
    font-weight: 600;
    width: 100%;
    color: #333333;
    text-align: center;
}
.text {
    font-size: 40px;
    font-weight: 400;
    width: 100%;
    color: #333333;
    text-align: center;
}
.border {
    width: 251px;
    height: 251px;
    border: 1px solid rgba(190, 165, 136, 0.3);
    border-radius: 30px;
    background: #FFFFFF;
    overflow: hidden;
    margin: 30px auto;
    .img {
        width: 100%;
        height: 100%;
    }
}
.bottom{
    width: 100%;
    text-align: center;
    overflow: hidden;
    img{
        vertical-align: middle;
        margin-right: 10px;
    }
    span{
        vertical-align: middle;
    }
}
.bottomImg{
    width: 50px;
    height: 50px;
}
</style>