<!--
* @Description: 用户协议
* @Author: sunchunchun
* @CreateDate: 2022/10/08 15:29:36
* @FileName: userText.vue
!-->
<template>
    <div class="text-box">
        <span v-html="text" class="text"></span>
        <div class="box-btn">
            <van-button type="info" class="btn" round @click="onNext">协议授权</van-button>
        </div>
    </div>
</template>

<script>
import { getText, readText } from "@/api/home/index"
import { mapGetters } from "vuex"
export default {
    data () {
        return {
            text: '',
            form: {},
            id: ''
        }
    },
    computed: {
        ...mapGetters(['userTypes'])
    },
    created() {
        // this.form = JSON.parse(JSON.stringify(this.$route.query.form))
        this.form = this.$route.query.form
        // 获取预审协议
        getText({type: '敏感个人信息授权书'}).then(res => {
            this.id = res.data.id
            this.text = res.data.content
        })
    },
    methods: {
        // 跳转下一步
        onNext() {
            // 记录阅读接口
            readText({ids: this.id}).then(res => {
                if (this.userTypes == 1) {
                    this.$router.push({
                        path: '/home/edit/rzBank',
                        query: {
                            form: this.form,
                            flag: true
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/home/comEdit/rzBank',
                        query: {
                            form: this.form,
                            flag: true
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang='scss' scoped>
.text-box{
    padding: 30px;
    .text{
        font-size: 32px;
        line-height: 50px;
        margin-bottom: 40px;
        display: inline-block;
    }
    .box-btn{
        text-align: center;
        .btn{
            padding: 0 90px;
        }
    }
}
</style>