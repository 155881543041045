import pirText from '../../pages/rzBank/agreement/pirText.vue'
import preText from '../../pages/rzBank/agreement/preText.vue'
import userText from '../../pages/rzBank/agreement/userText.vue'

export const rzBank = {
    name: 'rzBankEdit',
    path: '/home/edit/rzBank',
    meta:{
        title:'工商银行业务',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/rzBank/edit.vue'),
}

export const rzBankCom = {
    name: 'rzBankComEdit',
    path: '/home/comEdit/rzBank',
    meta:{
        title:'工商银行业务',
        keepAlive: true
    },
    component: () => import ( /* webpackChunkName: "page" */ '@/pages/rzBank/comEdit.vue'),
}


export const rzBankPir = {
    name: 'rzBankPir',
    path: '/rzBank/rzBankPir',
    meta:{
        title:'企业信息授权书',
        keepAlive: true
    },
    component: pirText
}

export const rzBankPre = {
    name: 'rzBankPre',
    path: '/rzBank/rzBankPre',
    meta:{
        title:'个人信息授权书',
        keepAlive: true
    },
    component: preText
}

export const rzBankUser = {
    name: 'rzBankUser',
    path: '/rzBank/rzBankUser',
    meta:{
        title:'敏感个人信息授权书',
        keepAlive: true
    },
    component: userText
}