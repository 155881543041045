<!--
* @Description: 下拉刷新
* @Author: sunchunchun
* @CreateDate: 2022/08/30 18:00:24
* @LastEditTime: 2022/08/30 18:00:24
* @FileName: Reefresh.vue
!-->
<template>
    <div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <div :class="flag?'refresh-box refresh-padding':'refresh-box'">
                <slot></slot>
            </div>
        </van-pull-refresh>
    </div>
</template>

<script>
export default {
    props: {
        flag: {
            type: Boolean,
            default: false
        }
    },
    name: 'card',
    data () {
        return {
            isLoading: false
        }
    },
    created() {

    },
    methods: {
        // 下拉刷新
        onRefresh() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        },
    }
}
</script>

<style lang='scss' scoped>
.refresh-box{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #EBF2FA;
}
.refresh-padding{
    padding: 30px 30px 20px 30px;
}
</style>