<!--
* @Description: 扫描二维码后展示
* @Author: sunchunchun
* @CreateDate: 2024/03/20 08:52:45
* @FileName: qrcodeView.vue
!-->
<template>
    <div>
        {{ query }}
    </div>
</template>

<script>
export default {
    data () {
        return {
            query: ''
        }
    },
    created() {
        let query = this.$route.query
        this.query = JSON.stringify(this.$route.query)
        this.onLoad()
    },
    methods: {
        /**
         * 获取当前扫描二维码后 二维码是否正确
         */
        onLoad() {
            const toast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: '请稍后...'
            })
            toast.clear()
        },
    }
}
</script>

<style lang='scss' scoped>

</style>