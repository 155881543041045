<!--
* @Description: 预审card  申请中
* @Author: sunchunchun
* @CreateDate: 2023/12/15 15:56:08
* @FileName: YsList.vue
!-->
<template>
    <div>
        <div class="card" v-for="(row, index) in item" :key="index">
            <div>
                <span style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">鲁担科技贷</span>
                <van-divider />
            </div>
            <div>
                <div class="title">借款人</div>
                <div>{{ row.name }}</div>
                <van-divider />
            </div>
            <div>
                <div class="title">申请时间</div>
                <div>{{ row.date }}</div>
                <van-divider />
            </div>
            <div>
                <div class="title">申请编号</div>
                <div>{{ row.id }}</div>
                <van-divider />
            </div>
            <div>
                <div class="title">业务进度</div>
                <div>
                    <span>{{ row.title }}</span>
                </div>
                <van-divider />
            </div>
            <!-- 错误状态说明 -->
            <div v-if="row.authorisationStatus == 1">
                <div class="err-text" v-for="item in dictList">
                    <span v-if="row.authorisationErrorReason == item.value">{{ item.name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "YsListProgress",
    props: {
        data: Array
    },
    watch: {
        data: {
            handler(newV) {
                this.item = newV
            },
            deep: true,
        }
    },
    data() {
        return {
            item: [],
            dictList: [
                { name: '授权失败，请重新授权或联系工作人员', value: '1' },
                { name: '获取数据失败，请重新授权或联系工作人员', value: '2' },
                { name: '信息采集失败，若请重新授权或联系工作人员', value: '3' },
            ]
        }
    },
    created() {
        this.item = this.data
    }
}
</script>

<style lang='scss' scoped>
.card {
    font-size: 26px;
    box-shadow: 0px 2px 9px 0px rgba(218,205,189,0.5) ;
    .title {
        margin-bottom: 20px;
        color: #888888;
    }

    background: #fff;
    margin: 10px 0 20px 0;
    padding: 25px 30px;
    border-radius: 20px;
    backdrop-filter: blur(3px);
}
.err-text{
    color: rgb(255, 72, 72);
}
</style>