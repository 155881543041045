import { render, staticRenderFns } from "./emergLoantransfer.vue?vue&type=template&id=8ac471dc&scoped=true"
import script from "./emergLoantransfer.vue?vue&type=script&lang=js"
export * from "./emergLoantransfer.vue?vue&type=script&lang=js"
import style0 from "./emergLoantransfer.vue?vue&type=style&index=0&id=8ac471dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ac471dc",
  null
  
)

export default component.exports