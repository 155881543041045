<!--
* @Description: 隐私授权
* @Author: sunchunchun
* @CreateDate: 2022/10/08 15:29:04
* @FileName: pirText.vue
!-->
<template>
    <div class="cardForm" style="width:94%;margin-left:3%;border-radius: 8px 8px 8px 8px;height:auto;padding:0 10px">
        <span v-html="text" class="text"></span>
        <div class="box-btn">
            <van-button color="#BEA588" type="info" class="btn" round @click="onNext">下一步</van-button>
        </div>
    </div>
</template>

<script>
import { getText, readText } from "@/api/home/index"
export default {
    data () {
        return {
            text: '',
            form: {},
            id: ''
        }
    },
    created() {
        this.form = this.$route.query.form
        // 获取预审协议
        getText({type: '企业信息授权书'}).then(res => {
            this.id = res.data.id
            this.text = res.data.content
        })
    },
    methods: {
        // 跳转下一步
        onNext() {
            readText({ids: this.id}).then(res => {
                this.$router.push({
                    name: 'guaranteeUser',
                    query: {
                        form: this.form,
                    }
                })
            })
        },
    }
}
</script>

<style lang='scss' scoped>

    .text{
        font-size: 32px;
        line-height: 50px;
        margin-bottom: 40px;
        display: inline-block;
    }
    .box-btn{
        text-align: center;
        .btn{
            padding: 0 90px;
        }
    }

</style>