
import storage from 'store'

const state = {
    goKjd: true,
    goLogin: "0", // 0->未登录  1->已登录
    userLoginType: '', // 当前登录的用户类型 appuser和人企业登录   cusm 客户经理登录
    token: null, // 内部登录 token
    perLoginTime: 0, // 个人登录倒计时
    perRegTime: 0, // 个人注册倒计时
    entRegTime: 0, // 企业注册倒计时
    entLoginTime: 0, // 企业登录倒计时
    cusmLoginTime: 0, // 客户经理登录倒计时
    comEditPhone: 0, // 手机号修改
    // 人脸识别时的身份证信息等
    faceData: {
        name: '',
        certNo: '',
        validPeriod: '',
        idCardPhoto1Addr: '',
        idCardPhoto2Addr: '',
        householdRegAddr: '',
        regOrgan: '',
        periodEnd: '',
        birthday: '',
    },
    // 个人信息
    dataInfo: {
        loginPhone: '',
        loginReading: false,
        regName: '',
        regRadio: false,
    },
    // 注册时缓存信息
    dataReg: {
        type: '1',
        preRadio: '',
        prePhone: '',
        preCode: '',

        comRadio: '',
        comName: '',
        comPhone: '',
        comCode: '',
    },
    userTypes: '', // 账号类型
    phone: '', // 登录手机号
    realName: '', // 是否实名认证
    comName: '', // 公司名称

    comLices: 1, // 营业执照
    comCards: 0, // 身份认证
    comFaces: 0, // 人脸识别

    preCards: 1, // 个人身份证识别
    preFaces: 0, // 个人人脸识别

    comEditFaces: 1, // 公司修改 人脸识别
    comEditLices: 0, // 公司修改 营业执照


    phoneFaces: 1, // 修改公司手机号时的认证状态码
    phoneLices: 0, // 修改公司手机号时的认证状态码
}

const mutations = {
    // 科技贷是否执行获取q参数
    SET_GOKJD(state, data) {
        state.goKjd = data
    },
    // 获取当前用户类型
    SET_GO_LOGIN(state, data) {
        state.goLogin = data
    },
    // 获取当前用户类型
    SET_LOGIN_USER(state, data) {
        state.userLoginType = data
    },
    // 缓存 认证时ocr识别的身份证信息，为认证接口存储参数
    SET_UNDATA_FACE(state, data) {
        state.faceData = data
    },
    // 注册时缓存信息
    SET_REG(state, data) {
        state.dataReg = data
    },
    // 存储 企业手机号变更秒数存储
    SET_COM_PHONE(state, data) {
        state.comEditPhone = data
    },
    // 存储认证到哪一步
    SET_COM(state, data) {
        state.comLices = data.data1,
        state.comCards = data.data2,
        state.comFaces = data.data3,
        state.preCards = data.data4,
        state.preFaces = data.data5
    },
    
    // 存储认证到哪一步
    SET_COM_PHONE_NUM(state, data) {
        state.phoneFaces = data.data1,
        state.phoneLices = data.data2
    },
    // 存储公司名称修改认证到哪一步
    SET_COM_EDIT(state, data) {
        state.comEditFaces = data.data1,
        state.comEditLices = data.data2
    },
    // 存储TOKEN
    SET_TOKEN(state, data) {
        state.token = data
    },
    // 删除TOKEN
    CLEAR_TOKEN(state) {
        state.token = null
    },
    // 个人登录
    PER_LOGIN_SUM_TIME(state, data) {
        state.perLoginTime = data
    },
    // 企业登录
    ENT_LOGIN_SUM_TIME(state, data) {
        state.entLoginTime = data
    },
    // 企业登录
    CUSM_LOGIN_SUM_TIME(state, data) {
        state.cusmLoginTime = data
    },
    // 个人注册
    PER_REG_SUM_TIME(state, data) {
        state.perRegTime = data
    },
    // 企业注册
    ENT_REG_SUM_TIME(state, data) {
        state.entRegTime = data
    },
    // 个人 存储登录基本信息(手机号)
    CLEAR_INFO_PHONE(state, data) {
        state.dataInfo.loginPhone = data
    },
    // 个人 存储登录基本信息(是否阅读协议)
    CLEAR_INFO_READING(state, data) {
        state.dataInfo.loginReading = data
    },
    // 企业 存储登录基本信息(公司名称)
    CLEAR_INFO_REG_NAME(state, data) {
        state.dataInfo.regName = data
    },
    // 企业 存储登录基本信息(是否阅读协议)
    CLEAR_INFO_REG_RADIO(state, data) {
        state.dataInfo.regRadio = data
    },

    // 用户类型
    USERTYPE(state, userTypes) {
        state.userTypes = userTypes
    },
    // 用户手机号
    PHONE_NUMBER(state, phone) {
        state.phone = phone
    },
    // 是否实名认证
    REAL_NAME(state, data) {
        state.realName = data
    },
    // 公司名称
    COM_NAME(state, data) {
        state.comName = data
    },
    // 机构类型
    SET_CUST_USER_TYPE(state, data) {
        state.custUserType = data
    },
    // 机构名称
    SET_CUST_USER_NICK_NAME(state, data) {
        state.custUserNickName = data
    },
    // 机构部门
    SET_CUST_USER_DEPT(state, data) {
        state.custUserDept = data
    }
}

const actions = {
    // 科技贷
    setKJD({ commit, state }, data) {
        commit('SET_GOKJD', data)
    },
    // 设置当前账号是否登录
    setGoLogin({ commit, state }, data) {
        storage.set('goLogin', data)
        commit('SET_GO_LOGIN', data)
    },
    // 设置当前用户登录类型
    setLoginUserType({ commit, state }, data) {
        storage.set('userLoginType', data)
        commit('SET_LOGIN_USER', data)
    },
    // 设置机构用户类型
    setCustUserType({ commit, state }, data) {
        storage.set('custUserType', data)
        commit('SET_CUST_USER_TYPE', data)
    },
    // 设置机构用户昵称
    setCustUserNickName({ commit, state }, data) {
        storage.set('custUserNickName', data)
        commit('SET_CUST_USER_NICK_NAME', data)
    },
    // 设置机构用户机构
    setCustUserDept({ commit, state }, data) {
        storage.set('custUserDept', data)
        commit('SET_CUST_USER_DEPT', data)
    },
    // 获取倒计时
    comEditPhoneFun ({ commit, state }, num) {
        let sumTime = num
        let time
        if (sumTime) {
            clearInterval(time)
            time = setInterval(() => {
                sumTime -= 1
                commit('SET_COM_PHONE', sumTime)
                console.log('企业修改', sumTime)
                if (sumTime == 0) {
                    clearInterval(time)
                }
            }, 1000);
        }
    },
    setCom({ commit, state }, data) {
        commit('SET_COM', {data1: data.comLices, data2: data.comCards, data3: data.comFaces, data4: data.preCards, data5: data.preFaces})
    },
    setComPhoneNum({ commit, state }, data) {
        commit('SET_COM_PHONE_NUM', {data1: data.phoneFaces, data2: data.phoneLices})
    },
    setComEdit({ commit, state }, data) {
        console.error(data)
        commit('SET_COM_EDIT', {data1: data.comEditFaces, data2: data.comEditLices})
    },
    // 存储当前用户信息 公司名称
    setComName({ commit, state }, data) {
        commit('COM_NAME', data)
    },
    // 清除当前用户信息 公司名称
    clearComName({ commit, state }, data) {
        commit('COM_NAME', '')
    },
    // 存储当前用户信息 用户类型
    setUserType({ commit, state }, data) {
        commit('USERTYPE', data)
    },
    // 清除当前用户信息 用户类型
    clearUserType({ commit, state }, data) {
        commit('USERTYPE', '')
    },
    // 存储当前用户信息 手机号
    setPhoneNumber({ commit, state }, data) {
        commit('PHONE_NUMBER', data)
    },
    // 清除当前用户信息 手机号
    clearPhoneNumber({ commit, state }, data) {
        commit('PHONE_NUMBER', '')
    },
    // 存储当前用户信息 是否实名
    setRealName({ commit, state }, data) {
        commit('REAL_NAME', data)
    },
    // 清除当前用户信息 是否实名
    clearRealName({ commit, state }, data) {
        commit('REAL_NAME', '')
    },
    // 登录
    Login({ commit }, userInfo) {
        if (userInfo) {
            storage.set('ACCESS_TOKEN', userInfo) // 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', userInfo)
        } else {
            commit('SET_TOKEN', '')
            storage.set('ACCESS_TOKEN', '')
        }
    },
    // 登出
    Logout({ commit, state }) {
        commit('SET_GO_LOGIN', '0')
        commit('SET_LOGIN_USER', '')
        commit('SET_GOKJD', true)
        storage.remove('ACCESS_TOKEN')
        commit('SET_TOKEN', '')
        commit('CLEAR_TOKEN')
        commit('CHANGE_TAB_ACTIVE', '/notloggedhome/index')
        commit('CLEAR_INFO_PHONE', '')
        commit('CLEAR_INFO_READING', '')
        commit('CLEAR_INFO_REG_NAME', '')
        commit('CLEAR_INFO_REG_RADIO', '')
        commit('USERTYPE', '')
        commit('PHONE_NUMBER', '')
        commit('REAL_NAME', '')
        commit('COM_NAME', '')
        commit('SET_COM_PHONE', 0)
        commit('SET_COM', 1, 0, 0)
        commit('SET_COM_EDIT', 1, 0)
        commit('SET_COM', {data1: 1, data2: 0, data3: 0, data4: 1, data5: 0})
        commit('SET_COM_EDIT', {data1: 1, data2: 0})
        commit('SET_COM_PHONE_NUM', {data1: 1, data2: 0})
        commit('PER_LOGIN_SUM_TIME', 0) // 清空倒计时
        commit('ENT_LOGIN_SUM_TIME', 0) // 企业清空倒计时
        commit('CUSM_LOGIN_SUM_TIME', 0) // 客户经理倒计时

        commit('SET_UNDATA_FACE', {})
    },
    // 缓存认证时ocr识别身份证的信息
    setDataFace({ commit, state }, data) {
        commit('SET_UNDATA_FACE', data)
    },
    // 存储注册时缓存的信息
    setRegData({ commit, state }, data) {
        commit('SET_REG', data)
    },
    // 存储手机号
    setPhone({ commit, state }, data) {
        commit('CLEAR_INFO_PHONE', data)
    },
    // 清除手机号
    clearPhone({ commit, state }, data) {
        commit('CLEAR_INFO_PHONE', '')
    },
    // 存储是否阅读
    setReading({ commit, state }, data) {
        commit('CLEAR_INFO_READING', data)
    },
    // 清除是否阅读
    clearReading({ commit, state }, data) {
        commit('CLEAR_INFO_READING', false)
    },
    
    // 企业名称存储
    setRegName({ commit, state }, data) {
        commit('CLEAR_INFO_REG_NAME', data)
    },
    // 清除手机号
    clearRegName({ commit, state }, data) {
        commit('CLEAR_INFO_REG_NAME', '')
    },
    // 企业 存储是否阅读
    setRegReadio({ commit, state }, data) {
        commit('CLEAR_INFO_REG_RADIO', data)
    },
    // 企业 清除是否阅读
    clearRegReadio({ commit, state }, data) {
        commit('CLEAR_INFO_REG_RADIO', false)
    },
    // 个人登录获取倒计时
    perLoginTimeFun ({ commit, state }, num) {
        let sumTime = num
        if (sumTime) {
            // clearInterval(time)
            let time = setInterval(() => {
                sumTime -= 1
                console.log(sumTime)
                commit('PER_LOGIN_SUM_TIME', sumTime)
                // console.log('个人登录', sumTime)
                if (sumTime == 0) {
                    clearInterval(time)
                }
            }, 1000);
        }
    },
    // 企业登录获取倒计时
    entLoginTimeFun ({ commit, state }, num) {
        let sumTime = num
        if (sumTime) {
            let time = setInterval(() => {
                sumTime -= 1
                commit('ENT_LOGIN_SUM_TIME', sumTime)
                // console.log('企业登录', sumTime)
                if (sumTime == 0) {
                    clearInterval(time)
                }
            }, 1000);
        }
    },
    // 客户经理登录获取倒计时
    cusmLoginTimeFun ({ commit, state }, num) {
        let sumTime = num
        if (sumTime) {
            let time = setInterval(() => {
                sumTime -= 1
                commit('CUSM_LOGIN_SUM_TIME', sumTime)
                if (sumTime == 0) {
                    clearInterval(time)
                }
            }, 1000);
        }
    },
    // 个人注册获取倒计时
    perRegTimeFun ({ commit, state }, num) {
        let sumTime = num
        if (sumTime) {
            let time = setInterval(() => {
                sumTime -= 1
                commit('PER_REG_SUM_TIME', sumTime)
                console.log('个人注册', sumTime)
                if (sumTime == 0) {
                    clearInterval(time)
                }
            }, 1000);
        }
    },
    // 企业注册获取倒计时
    entRegTimeFun ({ commit, state }, num) {
        let sumTime = num
        if (sumTime) {
            let time = setInterval(() => {
                sumTime -= 1
                commit('ENT_REG_SUM_TIME', sumTime)
                console.log('企业注册', sumTime)
                if (sumTime == 0) {
                    clearInterval(time)
                }
            }, 1000);
        }
    }
}

export default {
    state,
    mutations,
    actions
}