<!--
* @Description: 企业授权
* @Author: sunchunchun
* @CreateDate: 2022/10/08 15:29:13
* @FileName: preText.vue
!-->
<template>
    <div class="cardForm" style="width:94%;margin-left:3%;border-radius: 8px 8px 8px 8px;height:auto;padding:0 10px">
        <span v-html="text" class="text"></span>
        <div class="box-btn">
            <van-button color="#BEA588" type="info" class="btn" round @click="onNext">下一步</van-button>
        </div>
    </div>
</template>

<script>
import { getText, readText,getV2 } from "@/api/home/index"
export default {
    data () {
        return {
            text: '',
            form: {},
            labelForm: {},
            flag: false,
            id: '',
            type: '',
        }
    },
    created() {
        this.form = this.$route.query.form
        this.guarComp = this.$route.query.guarComp
        this.type = this.$route.query.type
        this.labelForm = this.$route.query.labelForm
        this.flag = this.$route.query.flag
    },
    mounted() {
        // 获取预审协议
        // getText({type: '个人信息授权书'}).then(res => {
        //     this.id = res.data.id
        //     this.text = res.data.content
        // })
        getV2({type: '个人信息授权书',guarComp:this.guarComp }).then(res => {
            this.id = res.data.id
            this.text = res.data.content
        })
    },
    methods: {
        // 跳转下一步
        onNext() {
            readText({ids: this.id,guarComp:this.guarComp}).then(res => {
                let arr = []
                arr.push(res.data)
                this.$router.replace({
                    name: 'ldPirText',
                    query: {
                        form: this.form,
                        guarComp:this.guarComp,
                        ids: JSON.stringify(arr),
                        type: this.type,
                        labelForm: this.labelForm
                    }
                })
            })
        },
    }
}
</script>

<style lang='scss' scoped>
    .text{
        font-size: 32px;
        line-height: 50px;
        margin-bottom: 40px;
        display: inline-block;
    }
    .box-btn{
        text-align: center;
        .btn{
            padding: 0 90px;
            margin-bottom:30px;
        }
    }
</style>