const state = {
    active:"/notloggedhome/index", //当前tab名称
    models: true,
    comLiceData: {},
    comCardData: {},
    overdueFace: 1,
    overdueCard: 0,
    isBig: false,
}

const mutations = {
    CHANGE_TAB_ACTIVE(state,active){
        state.active = active
    },
    SET_MODEL_DILOG(state, models) {
        state.models = models
    },
    SET_COM_LICE_DATA(state, data) {
        state.comLiceData = JSON.parse(JSON.stringify(data))
    },
    SET_COM_CARD_DATA(state, data) {
        state.comCardData = JSON.parse(JSON.stringify(data))
    },
    SET_NUM(state, data) {
        state.overdueFace = data.data1
        state.overdueCard = data.data2
    },
    SET_ISBIG(state, flag) {
        state.isBig = flag
        state.isBig = flag
    }
}

const actions = {
    setComLiceData({ commit, state }, data) {
        commit('SET_COM_LICE_DATA', data)
    },
    setComCardData({ commit, state }, data) {
        commit('SET_COM_CARD_DATA', data)
    },
    setNum({ commit, state }, data) {
        commit('SET_NUM', {data1: data.face, data2: data.card})
    },
    // 存储是否是大屏登录
    setBigFun({ commit, state }, flag) {
        commit('SET_ISBIG', flag)
    }
}

export default {
    state,
    mutations,
    actions
}