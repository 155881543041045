<!--
* @Description: 已完成
* @Author: sunchunchun
* @CreateDate: 2023/12/15 16:10:02
* @FileName: YsListComplete.vue
!-->
<template>
    <div>
        <div>
            <span v-if="item.productType == 1"
                style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">担保申请</span>
            <span v-if="item.productType == 2"
                style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">普惠区域担保业务</span>
            <span v-if="item.productType == 3"
                style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">普惠担保申请</span>
            <span v-if="item.productType == 4"
                style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">鲁岗贷业务</span>
            <span v-if="item.productType == 5"
                style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">省农信联社业务</span>
            <span v-if="item.productType == 6"
                style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">莱商银行业务</span>
            <span v-if="item.productType == 7"
                style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">鲁担科技贷</span>
            <span v-if="item.productType == 8"
                style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">鲁担科技贷(保后管理)</span>
            <span v-if="item.productType == 9"
                style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">工商银行业务</span>
            <span v-if="item.productType == 0" style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">日照银行业务</span>
            <span v-if="item.productType == 'qingdao_bank'" style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">青岛银行业务</span>
            <span v-if="item.productType == 'qilu_bank'" style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">齐鲁银行业务</span>
            <van-divider />
        </div>
        <div>
            <div class="title">借款人</div>
            <div>{{ item.name }}</div>
            <van-divider />
        </div>
        <div>
            <div class="title">申请时间</div>
            <div>{{ item.date }}</div>
            <van-divider />
        </div>
        <div>
            <div class="title">申请编号</div>
            <div>{{ item.id }}</div>
            <van-divider />
        </div>
        <div v-if="item.productType != 3">
            <div class="title">业务进度</div>
            <div>
                <span>{{ item.title }}</span>
                <span v-if="item.state == 1" style="color: #69ADF2;float: right;margin-right: 30px;"
                    @click="onCancel(item)">取消</span>
            </div>
            <van-divider />
        </div>
        <div v-if="item.productType == 3">
            <div class="title">业务进度</div>
            <div>
                <span v-for="(row, index) in statusList" :key="index">
                    <span v-if="row.dictValue == item.state">{{ row.dictLabel }}</span>
                </span>
            </div>
            <van-divider />
        </div>
        <!-- 错误状态说明 -->
        <div v-if="item.state == 4 && item.authorisationStatus == 1">
            <div class="err-text">
                <span v-if="item.authorisationErrorReason == 4">业务异常，请联系担保公司处理</span>
                <span v-if="item.authorisationErrorReason == 5">业务失败，请联系担保公司重新获取二维码</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "YsListComplete",
    props: {
        data: Object
    },
    watch: {
        data: {
            handler(newV) {
                this.item = newV
            },
            deep: true,
        }
    },
    data() {
        return {
            item: {},
        }
    },
    created() {
        this.item = this.data
    },
    methods: {
        onCancel(id, type) {
            this.$emit('onCancel', id, type)
        }
    }
}
</script>

<style lang='scss' scoped>
.card {
    font-size: 26px;
    background: #fff;
    margin: 10px 0 20px 0;
    padding: 25px 30px;
    border-radius: 20px;
    box-shadow: 0px 2px 9px 0px rgba(218,205,189,0.5) inset 1px 2px 0px 0px #FFFFFF;
    backdrop-filter: blur(3px);
    .title {
        margin-bottom: 20px;
        color: #888888;
    }
}
.err-text {
    color: rgb(255, 72, 72);
}
</style>