<!--
* @Description: 
* @Author: sunchunchun
* @CreateDate: 2023/04/19 16:51:43
* @FileName: index.vue
!-->
<template>
   <div>
        <!-- 全局下拉容器 card -->
        <div class="home-box">
            <!-- banger -->
            <div class="swiper-banger">
                <van-swipe class="my-swipe" :autoplay="4000" indicator-color="white">
                    <van-swipe-item>
                        <img class="banger-img" src="../../assets/images/banger.png" alt="banger"/>
                    </van-swipe-item>
                    <van-swipe-item>
                        <img class="banger-img" src="../../assets/images/banger2.png" alt="banger"/>
                    </van-swipe-item>
                </van-swipe>
                
            </div>
            <!-- 分界线 -->
            <div v-for="item in dataList" :key="item.id">
                <div class="child-footer" style="margin-top: 20px;">
                    <img class="item-img-title" src="../../assets/images/newImg/mode-title.png" alt="">
                    <p class="footer-title font-family-all">
                        <img class="icon-left" src="../../assets/images/newImg/icon-left.png" alt="">
                        <img class="icon-right" src="../../assets/images/newImg/icon-right.png" alt="">
                        <span>{{ item.nameTop }}</span>
                        <span>{{ item.nameBottom }}</span>
                    </p>
                    <div class="btn-box" v-if="item.jumpPath != '/ldScience'">
                        <div @click="onPush(item)" class="footer-btn font-family-all"><span>立即申请</span></div>
                        <div class="footer-btn-ty"></div>
                    </div>
                    <div class="btn-box-ld" v-if="item.jumpPath == '/ldScience'">
                        <div @click="onPush(item)" class="footer-btn-left font-family-all"><span>立即申请</span></div>
                        <div @click="onBhPush(item)" class="footer-btn-right font-family-all"><span>保后管理</span></div>
                        <div class="footer-btn-ty-left"></div>
                        <div class="footer-btn-ty-right"></div>
                    </div>
                    <div class="font-content" style="font-size: 10px;">
                        <p style="margin: 0;color: #D34507;" class="font-family-all">注：</p>
                        <div>
                            <p style="margin: 0;font-family: CONTENT !important;" class="font-family-all">{{ item.descInfo }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getGoodsList } from "@/api/home/index"
import { mapGetters } from "vuex"
import { verifyDirectStatus } from "@/api/product/index"
import { getUnList } from "@/api/home/index"

export default {
    // 组件注册
    components: {},
    data () {
        return {
            pages: {
                pageSize: 100,
                pageNum: 1,
                miniShow: 1,
                parentId: '',
            },
            dataList: []
        }
    },
    computed: {
        ...mapGetters(['userTypes', 'phone', 'realName'])
    },
    created() {
        this.pages.parentId = this.$route.query.id
        getGoodsList(this.pages).then(res => {
            let data = res.rows
            for (let row of data) {
                if (row.name.length > 5) {
                    let str = row.name
                    row.nameTop = str.slice(0, 8)
                    row.nameBottom = str.slice(8)
                } else {
                    row.nameTop = row.name
                    row.nameBottom = ''
                }
            }
            this.$nextTick(_ => {
                this.dataList = data
            })
        })
    },
    methods: {
        onPush(row) {
            if (this.realName == 0) {
                this.$dialog.confirm({
                    confirmButtonText: '前往实名认证',
                    confirmButtonColor: '#1989fa',
                    message: '当前帐号没有实名认证，请完成实名认证后在进行预审',
                }).then(() => {
                    // 进入对应账号的认证页面
                    if (this.userTypes == 2) {
                        this.$router.push({name: 'comHome'})
                    } else {
                        this.$router.push({name: 'preHome'})
                    }
                }).catch(() => {
                    return false
                })
                return false
            }

            // 批量担保业务申请
            if (row.jumpPath == 1) {
                this.$dialog.confirm({
                    allowHtml: true,
                    confirmButtonText: '同意并继续',
                    cancelButtonText: '不同意',
                    title: "用户隐私保护提示",
                    confirmButtonColor: '#1989fa',
                    message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。获取您的手机号和个人信息，我们将按照法律法规要求向您提供服务，保护您的个人信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息》</span>、<span style="color: #000;font-weight: 600;">《授权企业信息》</span>、<span style="color: #000;font-weight: 600;">《敏感个人信息授权》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人信息的保护措施`,
                }).then(() => {
                    // 进入对应账号的认证页面
                    if (this.userTypes == 2) {
                        this.$router.replace({name: 'qlBankComEdit', query: {goodsId: row.id}})
                    } else {
                        this.$router.replace({name: 'qlBankEdit', query: {goodsId: row.id}})
                    }
                }).catch(() => {
                })
            }

            // 普惠区域担保业务（菏泽地区）
            if (row.jumpPath == 0) {
                // realName为0 进行提示实名认真 否则 进行新增申请信息
                if (this.realName == 0) {
                    this.$dialog.confirm({
                        confirmButtonText: '前往实名认证',
                        confirmButtonColor: '#1989fa',
                        message: '当前帐号没有实名认证，请完成实名认证后在进行预审',
                    }).then(() => {
                        // 进入对应账号的认证页面
                        if (this.userTypes == 2) {
                            this.$router.push({name: 'comHome'})
                        } else {
                            this.$router.push({name: 'preHome'})
                        }
                    }).catch(() => {
                        
                    });
                } else {
                    // 检测是否已经有了历史记录
                    verifyDirectStatus().then(res => {
                        if (res.code == 200) {
                            let msg = res.msg
                            if (msg == 3 ) {
                                // 存在已放款的业务3
                                this.$dialog.alert({
                                    message:'存在已放款的业务，只能申请一次'
                                })
                            } else if (msg == 2) {
                                // 存在审核中的业务2
                                this.$dialog.alert({
                                    message:'存在审核中的业务，暂时无法申请'
                                })
                            } else if (msg == 1) {
                                // 存在申请中的业务1
                                this.$dialog.alert({
                                    message:'存在申请中的业务，暂时无法申请'
                                })
                            } else {
                                this.$dialog.confirm({
                                    confirmButtonText: '同意并继续',
                                    cancelButtonText: '不同意',
                                    title: "用户隐私保护提示",
                                    confirmButtonColor: '#1989fa',
                                    message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。获取您的手机号和个人信息，我们将按照法律法规要求向您提供服务，保护您的个人信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息》</span>、<span style="color: #000;font-weight: 600;">《授权企业信息》</span>、<span style="color: #000;font-weight: 600;">《敏感个人信息授权》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人信息的保护措施`,
                                }).then(() => {
                                    if (this.userTypes == 1) {
                                            this.$router.push({
                                                path: '/cusDirect/request/edit', query: {goodsId: row.id}
                                            })
                                        } else {
                                            this.$router.push({
                                                path: '/cusDirect/request/comEdit', query: {goodsId: row.id}
                                            })
                                        }
                                }).catch(() => {
                                })
                            }
                        } else {
                            this.$toast(res.msg)
                        }
                    })
                }
            }

            // 鲁担科技贷
            if (row.jumpPath == '/ldScience') {
                // realName为0 进行提示实名认真 否则 进行新增申请信息
                if (this.realName == 0) {
                    this.$dialog.confirm({
                        confirmButtonText: '前往实名认证',
                        confirmButtonColor: '#1989fa',
                        message: '当前帐号没有实名认证，请完成实名认证后在进行预审',
                    }).then(() => {
                        // 进入对应账号的认证页面
                        if (this.userTypes == 2) {
                            this.$router.push({name: 'comHome'})
                        } else {
                            this.$router.push({name: 'preHome'})
                        }
                    }).catch(() => {
                        
                    });
                } else {
                    // 检测是否已经有了历史记录
                    getUnList(this.page).then(res => {
                        if (res.code == 200) {
                            // 判断是否存在数据
                            let len = 0
                            for (let row of res.data.rows) {
                                if (row.productType == 7) {
                                    len++
                                }
                            }
                            if (len == 0) {
                                this.$dialog.confirm({
                                    confirmButtonText: '同意并继续',
                                    cancelButtonText: '不同意',
                                    title: "用户隐私保护提示",
                                    confirmButtonColor: '#1989fa',
                                    message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。获取您的手机号和个人信息，我们将按照法律法规要求向您提供服务，保护您的个人信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息》</span>、<span style="color: #000;font-weight: 600;">《授权企业信息》</span>、<span style="color: #000;font-weight: 600;">《敏感个人信息授权》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人信息的保护措施`,
                                }).then(() => {
                                    // 跳转新增页面
                                    this.$router.push({
                                        path: '/ldScience/edit',
                                        query: {prodId: row.id}
                                    })
                                }).catch(() => {
                                    
                                });
                                return false
                            }
                            // 如果存在数据进行提示 是否查看记录
                            if (len > 0) {
                                this.$dialog.confirm({
                                    confirmButtonText: '发起新的申请',
                                    cancelButtonText: '查看申请记录',
                                    confirmButtonColor: '#1989fa',
                                    message: '已存在预审信息，是否继续申请',
                                }).then(() => { // 发起新的请求
                                    this.$dialog.alert({
                                        message:'存在申请中的业务，只能申请一次'
                                    })
                                }).catch(() => { // 查看历史记录
                                    this.$store.commit('CHANGE_TAB_ACTIVE', '/home/task')
                                    this.$router.push({name: 'task'})
                                })
                            } else {
                                // 跳转新增页面
                                this.$router.push({
                                    path: '/ldScience/edit',
                                    query: { prodId: row.id }
                                })
                            }
                        } else {
                            this.$toast(res.msg)
                        }
                    })
                }
            }
        },
        /**
         * 鲁担科技贷  保后
         */
        onBhPush(row) {
            // realName为0 进行提示实名认真 否则 进行新增申请信息
            if (this.realName == 0) {
                    this.$dialog.confirm({
                        confirmButtonText: '前往实名认证',
                        confirmButtonColor: '#1989fa',
                        message: '当前帐号没有实名认证，请完成实名认证后在进行预审',
                    }).then(() => {
                        // 进入对应账号的认证页面
                        if (this.userTypes == 2) {
                            this.$router.push({name: 'comHome'})
                        } else {
                            this.$router.push({name: 'preHome'})
                        }
                    }).catch(() => {
                        
                    });
                } else {
                    // 检测是否已经有了历史记录
                    getUnList(this.page).then(res => {
                        if (res.code == 200) {
                            // 判断是否存在数据
                            let len = 0
                            for (let row of res.data.rows) {
                                if (row.productType == 9) {
                                    len++
                                }
                            }
                            if (len == 0) {
                                this.$dialog.confirm({
                                    confirmButtonText: '同意并继续',
                                    cancelButtonText: '不同意',
                                    title: "用户隐私保护提示",
                                    confirmButtonColor: '#1989fa',
                                    message: `使用该业务需要获取您的手机号和个人/企业信息，我们将按照法律法规要求向您提供服务，保护您的个人/企业信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息授权书》</span>、<span style="color: #000;font-weight: 600;">《企业信息授权书》</span>、<span style="color: #000;font-weight: 600;">《个人敏感信息授权书》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人/企业信息的保护措施。获取您的手机号和个人信息，我们将按照法律法规要求向您提供服务，保护您的个人信息。\n\n在使用服务前，请您阅读<span style="color: #000;font-weight: 600;">《个人信息》</span>、<span style="color: #000;font-weight: 600;">《授权企业信息》</span>、<span style="color: #000;font-weight: 600;">《敏感个人信息授权》</span>协议并同意，了解我们对申请相关权限以及收集使用您信息的情况，以及对个人信息的保护措施`,
                                }).then(() => {
                                    // 跳转新增页面
                                    this.$router.push({
                                        path: '/ldAfter/edit',
                                        query: {prodId: row.id}
                                    })
                                }).catch(() => {
                                    
                                });
                                return false
                            }
                            // 如果存在数据进行提示 是否查看记录
                            if (len > 0) {
                                this.$dialog.confirm({
                                    confirmButtonText: '发起新的申请',
                                    cancelButtonText: '查看申请记录',
                                    confirmButtonColor: '#1989fa',
                                    message: '已存在预审信息，是否继续申请',
                                }).then(() => { // 发起新的请求
                                    this.$dialog.alert({
                                        message:'存在申请中的业务，只能申请一次'
                                    })
                                }).catch(() => { // 查看历史记录
                                    this.$store.commit('CHANGE_TAB_ACTIVE', '/home/task')
                                    this.$router.push({name: 'task'})
                                })
                            } else {
                                // 跳转新增页面
                                this.$router.push({
                                    path: '/ldAfter/edit',
                                    query: { prodId: row.id }
                                })
                            }
                        } else {
                            this.$toast(res.msg)
                        }
                    })
                }
        },

    }
}
</script>

<style lang='scss' scoped>
/deep/
.swiper-banger{
    height: auto !important;
}
.home-box{
    padding: 30px 30px 190px 30px;
    height: 100vh;
    overflow-y: scroll;
}
/** 顶部banger样式 */
.swiper-banger{
    width: 100%;
    height: 230px !important;
    overflow: hidden;
    border-radius: 10px;
    .banger-img{
        width: 100%;
        height: 100%;
    }
}
.home-box::-webkit-scrollbar {
    display: none;
}
</style>