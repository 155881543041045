import request from "@/request/index"

/**
 * 合作银行
 */
export function getBanklist (data) {
    return request({
        url: '/open/goods/list',
        method: 'get',
        params: data
    })
}
export function doPolicy (data) {
    return request({
        url: '/open/'+ data.submitPath,
        method: 'post',
        data
    })
}