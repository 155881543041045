<!--
* @Description: 客户经理--邀请客户选择产品
* @Author: zhaogeng
* @CreateDate: 2024/03/18 10:03:06
* @FileName: Choose.vue
!-->
<template>
    <div>
        <div class="top" v-for="(item, index) in goosList" :key="index">
            <div class="topTitle">{{ item.name }}</div>
            <van-button class="topButton" @click="qrCode(item)">邀请客户</van-button>
        </div>
    </div>
</template>

<script>
import { getGoodsList } from "@/api/cusManagerlogin/index"
export default {
    // 组件注册
    components: {},
    data() {
        return {
            goosList: [],
            page: {
                pageSize: 200,
                pageNum: 1
            },
        }
    },
    created() {
        this.onLoad()
    },
    methods: {
        /**
         * 初始化
         */
        onLoad() {
            getGoodsList(this.page).then(res => {
                console.log(res)
                let list = res.data
                this.goosList = list.rows
            })
        },
        qrCode(row) {
            this.$router.push({
                path: '/qrCode',
                query: {
                    id: row.id,
                    name:row.name
                }
            })
        }
    }
}
</script>

<style lang='scss' scoped>
.van-button--normal {
    padding: 0;
}

.cardForm {
    width: 100%;
    height: 70%;
    margin-top: 2%;
    margin-left: 11.5%;
    border: 1px solid #E6D2BC;
    border-radius: 20px;
    box-shadow: 0px -2px 10px 0px rgba(237, 226, 212, 0.7), inset 1px 2px 0px 0px #FFFFFF;
    background-color: white;
    backdrop-filter: blur(3px);
}

.top {
    border-radius: 20px;
    border: 1px solid #E6D2BC;
    box-shadow: 0px -2px 10px 0px rgba(237, 226, 212, 0.7), inset 1px 2px 0px 0px #FFFFFF;
    background-color: white;
    backdrop-filter: blur(3px);
    display: flex;
    padding: 0%;
    margin: 30px 0;
    height: 120px;
    justify-content: space-between;
}

.topTitle {
    font-weight: 400;
    font-size: 30px;
    color: #212121;
    padding:37px 30px;
}

.topButton {
    border-radius: 27px;
    width: 25%;
    height: 56px;
    //background: #E6D2BC;
    font-size: 25px;
    font-weight: 400;
    color: #8B6841;
    border: 1px #E6D2BC solid;
    margin-top:30px;
    margin-right:22px;
}</style>