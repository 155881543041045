// 底部tab-bar
import Home from '../pages/home/home.vue'
// 首页页面
// import Index from '../pages/index/index.vue'
import Index from '../pages/home/homer.vue'
// 我的
import Mine from '../pages/mine/mine.vue'
// 业务状态
import Task from '../pages/task/task.vue'
// 404
import Page404 from '../pages/page404/index.vue'
// login
import Login from '../pages/user/login.vue'
// 扫描二维码后 展示
import qrcodeView from "@/pages/user/qrcodeView.vue"

// 未登录时修改手机号
import {prePhoneNoLogin, phoneFaceLogin, editPhoneLogin, comEditPhoneNoLogin, comPhoneListLogin, conLicenseUplod} from './modules/loginPhone'

import {perEditRoute,comEditRoute,guaranteePirRoute,guaranteePreRoute,guaranteeUserRoute} from './modules/guarantee'//我要申请

import {infoRoute,cusManagerloginRouter,cusManagerMiner,qrCodeRoute,inviteRoute, tasklist } from './modules/cusManager' // 客户经理

// 首页-金融服务
import {financingRoute,financingNoRoute,noFinancingRoute,noFinancingNoRoute,supplyChainroute,supplyChainNoroute,emergLoantransferRoute,emergLoantransferNoRoute} from './modules/serve'

// 客户直通路由
// 齐鲁bank path
import qlBankRouter from "./modules/qlBankRouter"
import { qlBankEditAdd, qlBankEditComAdd, pir, pre, user } from "./modules/qlBankEdit"
import { rcc, rccCom, rccPir, rccPre, rccUser } from "./modules/rcc"
import { ls, lsCom, lsPir, lsPre, lsUser } from "./modules/lsBank"
import { icbc, icbcCom, icbcPir, icbcPre, icbcUser } from "./modules/icbc"
import { rzBank, rzBankCom, rzBankPir, rzBankPre, rzBankUser } from "./modules/rzBank"
import { lsViews, lsSuccess, lsError, lsPrePush, lsComViews } from "./modules/lsViews"
import { comCredit } from "./modules/comCredit"
import { ticket } from "./modules/ticket"
import { ldScience, ldAfter, ldPir, ldPre, ldUser } from "./modules/ldScience"
import { bidApplicate, bidProBook, bidcompanyProbook, letterDownload, letterCheck, resultView, bidResult, bidProduct, payBank, payDetail } from "./modules/feiRong"
const routes = [
    prePhoneNoLogin,
    phoneFaceLogin,


    
    editPhoneLogin,



    comEditPhoneNoLogin,
    comPhoneListLogin,
    conLicenseUplod,

    // 开票申请
    ticket,
    //客户经理登录
    cusManagerloginRouter,
    //客户经理个人中心
    cusManagerMiner,
    //客户经理信息修改
    infoRoute,
    //客户经理--二维码
    qrCodeRoute,
    //客户经理--邀请客户
    inviteRoute,
    tasklist, // 客户经理--业务list
    //我要申请
    perEditRoute,
    comEditRoute,
    guaranteePirRoute,
    guaranteePreRoute,
    guaranteeUserRoute,

    //登陆后金融服务路由
    financingRoute,
    noFinancingRoute,
    supplyChainroute,
    emergLoantransferRoute,

    //登录器金融服务白名单
    financingNoRoute,
    noFinancingNoRoute,
    supplyChainNoroute,
    emergLoantransferNoRoute,

    // 客户直通router
    qlBankRouter,
    qlBankEditAdd,
    qlBankEditComAdd,
    pir,
    pre,
    user,
    rcc,
    rccCom,rccPir, rccPre, rccUser,
    ls,lsCom, lsPir, lsPre,lsUser, // 莱商银行模块新增
    lsViews, lsSuccess, lsError, lsPrePush, lsComViews, // 莱商银行支付
    comCredit, // 企业信息采集成功页面
    ldScience, ldAfter, ldPir, ldPre, ldUser, // 鲁担科创贷
    icbc, icbcCom, icbcPir, icbcPre, icbcUser, // 工商银行入口
    rzBank, rzBankCom, rzBankPir, rzBankPre, rzBankUser, // 日照银行入口
    bidApplicate, bidProBook, bidcompanyProbook,letterDownload, letterCheck, resultView, bidResult, bidProduct, payBank, payDetail,// 非融相关页面
    {
        name: 'qrcodeView',
        path: '/qrcodeView/index',
        component: qrcodeView,
    },
    {
        name: 'notloggedhome',
        path: '/notloggedhome',
        component: Home,
        children: [
            {
                name: 'notloggedhome',
                path: 'index',
                meta:{
                    title:'首页',
                    keepAlive: true
                },
                component: () => import ( /* webpackChunkName: "page" */ '@/pages/home/homer.vue'),
            },
            {
                name: 'notloggedhome',
                path: 'task',
                meta:{
                    title:'业务状态',
                    keepAlive: true
                },
                component: () => import ( /* webpackChunkName: "page" */ '@/pages/notLogged/task.vue'),
            },
            {
                name: 'notloggedhome',
                path: 'mine',
                meta:{
                    title:'个人中心',
                    keepAlive: true
                },
                component: () => import ( /* webpackChunkName: "page" */ '@/pages/notLogged/mine.vue'),
            }
        ]
    },
    // 大屏项目页面
    {
        name: "preliminary",
        path: "/preliminary",
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/bigViewPage/preliminary.vue'),
    },
    // 协议
    {
        name: "homePirText",
        path: "/homePirText",
        meta: {
            title: "隐私协议",
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/user/agreement/pirText.vue'),
    },{
        name: "homeUserText",
        path: "/homeUserText",
        meta: {
            title: "服务协议",
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/user/agreement/userText.vue'),
    },
    {
        name: "login",
        path: "/login",
        component: Login,
        meta: {
            title: "登录",
            keepAlive: true
        },
    }, {
        name: 'personal',
        path: '/personal',
        meta:{
            title:'登录',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/user/personal.vue'),
    }, 
     {
        name: 'enterprise',
        path: '/enterprise',
        meta:{
            title:'登录',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/user/enterprise.vue'),
    }, {
        name: 'registered',
        path: '/registered',
        meta:{
            title:'注册',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/user/registered.vue'),
    }, {
        name: 'check',
        path: '/check',
        meta:{
            title:'登录',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/user/check.vue'),
    },  {
        name: 'entCheck',
        path: '/entCheck',
        meta:{
            title:'登录',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/user/entCheck.vue'),
    }, {
        name: 'home',
        path: '/home',
        component: Home,
        children: [
            {
                name: 'index',
                meta:{
                    title:'首页',
                    keepAlive: true
                },
                path: 'index',
                component: Index
            },  {
                name: 'mine',
                path: 'mine',
                meta:{
                    title:'个人中心',
                    keepAlive: true
                },
                component: Mine
            }
        ]
    }, {
        name: 'task',
        path: '/task',
        meta:{
            title:'业务状态', 
            keepAlive: true
        },
        component: Task
    }, {
        name: 'edit',
        path: '/home/index/edit',
        meta:{
            title:'担保申请',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/index/edit.vue'),
    }, {
        name: 'comEdit',
        path: '/home/index/comEdit',
        meta:{
            title:'担保申请',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/index/comEdit.vue'),
    }, {
        name: 'feedback',
        path: '/feedback',
        meta:{
            title:'问题反馈',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/mine/feedback.vue'),
    }, 
    // 基础信息
    {
        name: 'info',
        path: '/home/mine/info',
        meta:{
            title:'基础信息',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/mine/personal/info.vue'),
    }, {
        name: 'cominfo',
        path: '/home/mine/comInfo',
        meta:{
            title:'基础信息',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/mine/company/info.vue'),
    }, {
        name: 'approveInfo',
        path: '/home/mine/approveInfo',
        meta:{
            title:'基础信息',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/mine/personal/approveInfo.vue'),
    }, {
        name: 'comapproveInfo',
        path: '/home/mine/comApproveInfo',
        meta:{
            title:'基础信息',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/mine/company/approveInfo.vue'),
    }, 
    
    
    // 协议
    {
        name: 'preText',
        path: '/home/agreement/preText',
        meta:{
            title:'预审协议',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/index/agreement/preText.vue'),
    }, {
        name: 'pirText',
        path: '/home/agreement/pirText',
        meta:{
            title:'隐私授权',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/index/agreement/pirText.vue'),
    }, {
        name: 'userText',
        path: '/home/agreement/userText',
        meta:{
            title:'用户协议',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/index/agreement/userText.vue'),
    },

    // 修改 公司名称
    {
        name: 'comEditInfo',
        path: '/comEditInfo/index',
        meta:{
            title:'修改企业信息',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/index.vue'),
    },{
        name: 'comEditFace',
        path: '/comEditFace/index',
        meta:{
            title:'修改企业信息',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/face.vue'),
    },{
        name: 'comEditLicesInfo',
        path: '/comEditLicesInfo/index',
        meta:{
            title:'修改企业信息',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/info/liceInfo.vue'),
    },{
        name: 'comEditOcr',
        path: '/comEditOcr/index',
        meta:{
            title:'修改企业信息',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/license.vue'),
    },



    // 企业 修改手机号
    {
        name: 'phoneYes',
        path: '/phoneYes/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/phoneYes.vue'),
    },{
        name: 'newPhone',
        path: '/newPhone/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/newPhone.vue'),
    },
    // 企业修改手机号 手机号不可用时
    {
        name: 'noPhone',
        path: '/noPhone/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/phoneNo.vue'),
    },{
        name: 'phoneList',
        path: '/phoneList/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/phoneList.vue'),
    },{
        name: 'phoneFace',
        path: '/phoneFace/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/phoneFace.vue'),
    },{
        name: 'phoneLiceUpload',
        path: '/phoneLiceUpload/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/info/phoneLiceUpload.vue'),
    },{
        name: 'phoneLiceInfo',
        path: '/phoneLiceInfo/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/info/phoneLiceInfo.vue'),
    },{
        name: 'phoneuploadocr',
        path: '/phoneuploadocr/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/phone/lice.vue'),
    },{
        name: 'phoneinfoocr',
        path: '/phoneinfoocr/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/phone/info.vue'),
    },
    // 手机号存在进行人脸识别
    {
        name: 'phoneFaceYes',
        path: '/phoneFaceYes/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/comEdit/phoneFaceYes.vue'),
    },



    // 修改个人 手机号且手机号不可用时
    {
        name: 'prePhoneNo',
        path: '/prePhoneNo/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/preEdit/phoneNo.vue'),
    },{
        name: 'prePhoneList',
        path: '/prePhoneList/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/preEdit/list.vue'),
    },{
        name: 'prePhoneInfo',
        path: '/prePhoneInfo/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/preEdit/info.vue'),
    },{
        name: 'preCardUpload',
        path: '/preCardUpload/index',
        meta:{
            title:'修改手机号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/preEdit/card.vue'),
    },
















    // 个人认证
    {
        // 个人认证首页
        name: 'preHome',
        path: '/preHome/index',
        meta:{
            title:'实名认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/certification/preHome/index.vue'),
    },{
        // 身份证上传
        name: 'preHomeCard',
        path: '/preHomeCard/index',
        meta:{
            title:'实名认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/certification/preHome/components/card.vue'),
    },{
        // 身份证info
        name: 'preHomeCardinfo',
        path: '/preHomeCardinfo/index',
        meta:{
            title:'实名认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/certification/preHome/info/cardInfo.vue'),
    },{
        // 人脸识别页面
        name: 'preHomeface',
        path: '/preHomeface/index',
        meta:{
            title:'实名认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/certification/preHome/components/face.vue'),
    },




    // 企业认证
    {
        // 营业执照
        name: 'comHome',
        path: '/comHome/index',
        meta:{
            title:'实名认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/certification/comHome/index.vue'),
    },{
        // 公司信息
        name: 'comInfoPage',
        path: '/comInfoPage/index',
        meta:{
            title:'实名认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/certification/comHome/info.vue'),
    },{
        // 营业执照
        name: 'licePage',
        path: '/licePage/index',
        meta:{
            title:'实名认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/certification/comHome/components/lice.vue'),
    },{
        // 营业执照信息确认
        name: 'liceInfos',
        path: '/liceInfo/index',
        meta:{
            title:'实名认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/certification/comHome/info/liceinfo.vue'),
    },{
        // 身份证上传
        name: 'cardupload',
        path: '/cardupload/index',
        meta:{
            title:'实名认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/certification/comHome/components/card.vue'),
    },{
        // 身份证确认
        name: 'cardinfos',
        path: '/cardinfos/index',
        meta:{
            title:'实名认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/certification/comHome/info/cardInfo.vue'),
    },{
        // 人脸识别
        name: 'faceupload',
        path: '/faceupload/index',
        meta:{
            title:'实名认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/certification/comHome/components/face.vue'),
    },



    // ----------------------------------

    // 身份证信息过期后 重新上传列表
    {
        // 重新认证列表
        name: 'overdueList',
        path: '/overdueList/index',
        meta:{
            title:'身份信息认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/index/overdue/index.vue'),
    },{
        // 人脸识别
        name: 'overdueFace',
        path: '/overdueFace/index',
        meta:{
            title:'身份信息认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/index/overdue/face.vue'),
    },{
        // 身份认证
        name: 'overdueCard',
        path: '/overdueCard/index',
        meta:{
            title:'身份信息认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/index/overdue/card.vue'),
    },{
        // 身份认证
        name: 'overdueCardInfo',
        path: '/overdueCardInfo/index',
        meta:{
            title:'身份信息认证',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/index/overdue/cardInfo.vue'),
    },
// ---------------------------------------

    // 注销账号页面
    {
        // 注销账号
        name: 'cancellation',
        path: '/cancellation/index',
        meta:{
            title:'注销账号',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/mine/cancellation/index.vue'),
    },
    // 关于我们
    {
        name: 'wepage',
        path: '/wepage/index',
        meta:{
            title:'关于我们',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/mine/wepage/index.vue'),
    },
    // 我的担保公司
    {
        name: 'mecom',
        path: '/mecom/index',
        meta:{
            title:'我的担保公司',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/mine/mecom/index.vue'),
    }, {
        name: 'edit',
        path: '/cusDirect/request/edit',
        meta:{
            title:'普惠区域担保业务',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/cusDirect/request/edit.vue'),
    }, {
        name: 'comEdit',
        path: '/cusDirect/request/comEdit',
        meta:{
            title:'普惠区域担保业务',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/cusDirect/request/comEdit.vue'),
    }, {
        name: 'requestContent',
        path: '/cusDirect/govAudit/contentCom',
        meta:{
            title:'企业申请详情',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/cusDirect/govAudit/contentCom.vue'),
    },  {
        name: 'requestContent',
        path: '/cusDirect/govAudit/contentPerson',
        meta:{
            title:'个人申请详情',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/cusDirect/govAudit/contentPerson.vue'),
    },  {
        name: 'govAudit',
        path: '/cusDirect/bankCheck/list',
        meta:{
            title:'银行查看',
            keepAlive: true
        },
        component: () => import ( /* webpackChunkName: "page" */ '@/pages/cusDirect/bankCheck/list.vue'),
    }, 




    {
        path: '/',
        redirect: '/home/index'
    }, {
        path: '*',
        component: Page404 //404页面
    },
]

export default routes