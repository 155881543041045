import request from "@/request/index"


/**
 * 快速申请--担保预审
 */
 export function getGoosList (data) {
    return request({
        url: '/open/goods/list',
        method: 'get',
        params: data
    })
}
/**
 * 快速申请--担保预审
 */
 export function buttonShow (data) {
    return request({
        url: '/open/invitedgoods/isAvailable?goodsId='+data,
        method: 'get',
    })
}