import request from "@/request/index"


/**
 * 获取当前帐号用户信息
 */
 export function getInfo () {
    return request({
        url: '/open/user/getInfo',
        method: 'get'
    })
}

/**
 * 获取协议
 * @param { String } data 1、预审 2、隐私 3、用户
 */
 export function getText (data) {
    return request({
        url: '/open/agreement/get',
        method: 'get',
        params: data
    })
}
 export function getV2 (data) {
    return request({
        url: '/open/agreement/getV2',
        method: 'get',
        params: data
    })
}
/**
 * 阅读协议
 * @param { String } data ids
 */
 export function readText (data) {
    return request({
        url: '/open/agreement/read',
        method: 'get',
        params: data
    })
}
/**
 * 添加一条空的预审记录
 */
 export function setRecord (data) {
    return request({
        url: '/open/decision/add',
        method: 'post',
        data
    })
}
/**
 * 添加一条预审记录
 */
 export function setRecordList (data) {
    return request({
        url: '/open/decision/openDoPolicy',
        method: 'post',
        data
    })
}
/**
 * 查询预审完成列表
 */
 export function getAccList (data) {
    return request({
        url: '/open/decision/getCompleted',
        method: 'get',
        params: data
    })
}
/**
 * 查询预审未完成列表
 */
 export function getUnList (data) {
    return request({
        url: '/open/decision/getDoing',
        method: 'get',
        params: data
    })
}
/**
 * 取消预审列表单个卡片
 * @param { String } data id
 */
 export function setCancel (data) {
    return request({
        url: '/open/decision/del',
        method: 'get',
        params: data
    })
}

/**
 * 获取产品列表
 * @param { Object } data
 * @returns
 */
 export function getGoodsList (data) {
    return request({
        url: `/open/goods/list`,
        method: 'get',
        params: data
    })
}

/**
 * 获取银行列表
 * @param { Object } data
 * @returns
 */
 export function getBank (data) {
    return request({
        url: `/open/bank/querybankinfobymechanismcode`,
        method: 'get',
        params: data
    })
}



/**
 * 获取我的担保公司明细
 * @param { Object } data
 * @returns
 */
export function getDetails () {
    return request({
        url: `/open/user/getDeptInfo`,
        method: 'get'
    })
}

// 校验是否存在子节点
export function judgeSon(id) {
    return request({
        url: '/open/goods/judgeSon/' + id,
        method: 'get',
    })
}

/**
 * 企业基本信息预审
 */
export function saveCompanyQl (data) {
    return request({
        url: '/open/ydInformation/saveCompany',
        method: 'post',
        data
    })
}


/**
 * 个人基本信息预审
 */
export function savePersonQl (data) {
    return request({
        url: '/open/ydInformation/savePerson',
        method: 'post',
        data
    })
}

/**
 * 中国银行新增
 */
export function zhBankAdd (data) {
    return request({
        url: '/open/zhBank/add',
        method: 'post',
        data
    })
}

/**
 * 中国银行修改
 */
export function zhBankApply (data) {
    return request({
        url: '/open/zhBank/apply',
        method: 'post',
        data
    })
}

/**
 * 中国银行取消申请中
 */
export function zhBankCancel (data) {
    return request({
        url: '/open/zhBank/cancel',
        method: 'post',
        data
    })
}