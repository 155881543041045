<!--
* @Description: 供应链
* @Author: zhaogeng
* @CreateDate: 2024/03/14 11:38:31
* @FileName: supplyChain.vue
!-->
<template>
   <div  class="cusman-bac-oner">
    <img   src="../../assets/images/gyljr.jpg" model="widthFix" style="width:100%">
   </div>
</template>

<script>
export default {
    // 组件注册
    components: {},
    data () {
        return {

        }
    },
    created() {

    },
    mounted () {},
    methods: {

    }
}
</script>

<style lang='scss' scoped>
.cusman-bac-oner {
    display: flex;
    width: 100%;
    height:auto
}
.user-bac-one {
    background-image: none
}

</style>