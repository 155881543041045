import request from "@/request/index"


/**
 * 莱商银行 提交接口
 * usetType 区分个人和企业
 */
 export function setDoPolicy (data) {
    return request({
        url: '/open/laiShang/doPolicy',
        method: 'post',
        data
    })
}


/**
 * 缴费订单详情查询
 */
export function getPreComView (data) {
    return request({
        url: '/open/openPlt/ydManage/queryMiniPayBusinessOrderDetail',
        method: 'get',
        params: data
    })
}

/**
 * 缴费订单状态
 */
export function getPayStatus (data) {
    return request({
        url: '/open/openPlt/ydManage/queryPayStatus',
        method: 'post',
        data
    })
}