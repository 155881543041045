<!--
* @Description: 客户经理登录页面
* @Author: zhaogeng
* @CreateDate: 2024/03/11 17:22:36
* @FileName: index.vue
!-->
<template>
    <div class="persoal-box">
        
        <div class="center-logo">
            <img src="../../assets/images/logo.png" alt="">
        </div>
        <div class="center-title">鲁担惠企通</div>
        <div class="cardForm card-view">
            <div style="display:flex">
                <div> <img src="../../assets/images/cus-login.png" style="width:24px;height:24px" alt=""></div>
                <div style="font-size:18px;margin-left:9px">客户经理登录</div>
            </div>
            <van-form ref='form' class="loginform">
                <van-field
                    class="phont-number"
                    style="font-size:16px;background: none;"
                    v-model="form.phone"
                    name="phone"
                    maxlength="11"
                    placeholder="请输入手机号"
                    type="tel"
                    :rules="[{ required: true, message: '请输入手机号' }, { validator, message: '手机号码格式不正确' }]"
                />
                <van-field
                    class="phone-input phont-number"
                    style="font-size:16px"
                    v-model="form.code"
                    name="code"
                    center
                    maxlength="6"
                    placeholder="请输入验证码"
                    :rules="[{ required: true, message: '请输入验证码' }]"
                >
                    <template #button>
                        <van-button
                            native-type="button"
                            @click="onPullCode(180)"
                            size="small"
                            :disabled="cusmnum!=0"
                            type="info"
                            style="font-size:15px;height: auto;display: flex">
                            {{ cusmnum == 0 ? codeText : cusmnum + '秒后重试' }} 
                        </van-button>
                    </template>
                </van-field>
            </van-form>
            <div class="agreen">
                <van-radio-group class="radios" v-model="agreement" @change="onAgreementChange" direction="horizontal">
                    <van-radio @click="onAgreementClick" name="1" style="margin-top:1%" icon-size="15px">
                        <span style="color: #999;font-size: 13px">阅读并同意</span>
                    </van-radio>
                    <span class="text-colors" style="font-size: 13px;margin-top:1%;" @click="onUser">《服务协议》</span><span class="text-colors" style="font-size: 13px;margin-top:1%;" @click="onPir">《隐私政策》</span>
                </van-radio-group>
                <van-divider></van-divider>
                <van-button :loading="loading" class="ant-btn btn-stl-1" style="font-size: 16px;" round  type="info" native-type="submit" @click="onNext">登 录</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { cusLogin, cusInfo, getPhoneNum, cusIsExist } from "@/api/cusManagerlogin/index"
import { mapGetters } from "vuex"
export default {
    data () {
        return {
            // pageFlag: true,
            phone: '', // 记录当前登录手机号
            form:{
                phone: '', // 手机号
                agreementFlag: false, // 协议状态
                code: '', // 验证码
                textArea: '', // 企业名称
            },
            code: '', // 接收对比code
            agreement: '', // 阅读参数
            cusmnum: 0, // 默认倒计时秒数
            codeText: '发送验证码',
            noFlag: false, // 判断是否首次进入
            loading: false, // 登录按钮loading加载状态
            phoneISright:true//验证手机号格式是否正确
        }
    },
    computed: {
        ...mapGetters(['dataInfo'])
    },
    activated(){
        if (this.$route.query.form && this.$route.query.form!= '') {
            let form = JSON.parse(this.$route.query.form)
            this.form.code = form.code
            this.form.phone = form.phone
            this.agreement = form.flag
        }
    },
    created() {
        // this.$store.dispatch('perLoginTimeFun', 0)
        this.form.phone = this.dataInfo.loginPhone
        this.agreement = this.dataInfo.loginReading ? '1' : '' // 阅读参数
        // 获取存储的倒计时还剩多少秒
        this.cusmnum = this.$store.getters.perLoginTime || 0
        if (this.cusmnum != 0) {
            // 二次进入禁止重新获取验证码
            this.noFlag = false
            this.getTimeStart()
        } else {
            // 首次进入获取验证码
            this.noFlag = true
        }
    },
    methods: {
        /**
         * 校验手机号是否正确
         * @param {String} val 手机号
         */
         validator(val) {
            this.phoneISright=/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(val)
            return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(val)
        },
        /**
         * 发送验证码
         * @param {Number} num 秒数
         */
        onPullCode(num) {
            if(this.phoneISright){
            this.$refs.form.validate('phone').then(_ => {
                // 查看当前账号是否已经注册，注册后在进行获取验证码
                cusIsExist({phone: this.form.phone}).then(res => {
                    if (res.code == 3007)  {
                        this.$toast('暂无账号信息')
                    } else if (res.code == 3011) {
                        this.getNum(num)
                    } else {
                        this.$toast(res.msg)
                    }
                })
            })
        }else{
            this.$dialog.confirm({
                        confirmButtonText: '确定',
                        confirmButtonColor: '#1989fa',
                        message: '请输入正确格式的手机号码！',
                    })  
        }
        },
        /**
         * 倒计时开始
         */
        getTimeStart() {
            let time = setInterval(() => {
                this.cusmnum -= 1
                if (this.cusmnum == 0) {
                    // 读秒结束清除计时器
                    this.noFlag = true
                    clearInterval(time)
                    this.$store.dispatch('cusmLoginTimeFun', 1) // 个人缓存定时
                    return false
                }
            }, 1000);
        },
        /**
         * 获取验证码
         * @param {Number} num 倒计时秒
         */
        getNum(num) {
            getPhoneNum({phone: this.form.phone, phoneprefix: 'khjl'}).then(res => {
                if (res.code == 200) {
                    // 缓存定时器开始
                    this.$store.dispatch('cusmLoginTimeFun', num) // 个人缓存定时
                    this.code = res.data
                    // 赋值倒计时
                    this.cusmnum = num
                    let time = setInterval(() => {
                        this.cusmnum -= 1
                        if (this.cusmnum == 0) {
                            // 读秒结束清除计时器
                            this.noFlag = true
                            clearInterval(time)
                            return false
                        }
                    }, 1000);
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        /**
         * 服务协议
         */
        onUser() {
            this.$router.push({
                path: '/homeUserText',
                query: {
                    path: '/cusManagerlogin',
                    form: JSON.stringify({
                    code:this.form.code,
                    phone: this.form.phone,
                    flag: this.agreement
                    })
                }
            })
        },
        /**
         * 隐私协议
         */
        onPir() {
            this.$router.push({
                path: '/homePirText',
                query: {
                    path: '/cusManagerlogin',
                    form: JSON.stringify({
                    code:this.form.code,
                    phone: this.form.phone,
                    flag: this.agreement
                    })
                }
            })
        },
        /**
         * 获取验证码， 添加计时器
         */
        onNext() {
           if(this.phoneISright){
            this.$refs.form.validate().then(_ => {
                if (this.agreement == '') {
                    this.$toast('请阅读并勾选协议')
                    return
                }
                this.goLogin()
            })
           }else{
            this.$dialog.confirm({
                        confirmButtonText: '确定',
                        confirmButtonColor: '#1989fa',
                        message: '请输入正确格式的手机号码！',
                    })
           }
            
        },
        /**
         * 登录
         */
        goLogin() {
            // 判断是否输入验证码
            if (this.form.code) {
                this.loading = true
                // 登录参数
                let loginObj = {
                    phone: this.form.phone,
                    code: this.form.code
                }
                // 登录接口
                cusLogin(loginObj).then(res => {
                    // 成功后 存储token
                    if (res.code == 1000) {
                        this.loading = false
                        this.$store.dispatch('Login', res.data['authentication-token'])
                        this.$toast.success('登陆成功')
                        this.$store.dispatch('setLoginUserType', 'cusm')
                        // 获取个人信息
                        cusInfo().then(res => {
                            // this.$store.dispatch('setUserType', res.data.ysOpenUser.userType)
                            this.$store.dispatch('setPhoneNumber', res.data.phoneNumber)
                            // this.$store.dispatch('setRealName', res.data.realName)
                            this.$nextTick(_ => {
                                this.$router.push({ path: '/cusManagerMine' })
                            })
                        })
                    // 失败后 提示
                    } else {
                        this.loading = false
                        this.$toast.fail(res.msg)
                    }
                // 接口错误
                }, error => {
                    this.loading = false
                    this.$toast.fail('登陆失败，请联系管理员')
                })
            } else {
                this.$toast('请输入验证码')
            }
        },
        // 协议选择
        onAgreementChange(val) {
            this.agreementFlag = true
        },
        /**
         * 选择同意登录协议
         * @param {String} val 当前选择redio
         */
        onAgreementClick(val) {
            // 个人
            if (!this.agreementFlag) {
                this.agreement = ''
            }
            this.agreementFlag = false
        },
    },
    // 离开页面时清空当前缓存的数据
    beforeRouteLeave (to, from, next) {
        if (to.path != '/homeUserText' || to.path != '/homePirText') {
            if (this.dataInfo.loginPhone != '') {
                this.$store.dispatch('clearPhone', '')
            }
            if (this.dataInfo.loginReading) {
                this.$store.dispatch('clearReading', false)
            }
        }
        next()
    }
}
</script>

<style lang='scss' scoped>
.persoal-box{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 20px 30px;
    flex-direction: column;
    // background-image: linear-gradient(to bottom , #fff, #D5E9F7);
    .title{
        // color: #45a0e0;
        // font-family: Cursive;
        margin-top: 150px;
        font-size: 50px;
    }
    /deep/
    .van-form{
        width: 100%;
    }
    /deep/
    .van-cell__title{
        width: 70px;
        border-right: 1px solid #CACACA;
    }
    .phont-number{
        width: 100%;
        margin-top: 42px;
        border: 1px solid #e6d2bc;
        border-radius: 20px;
        position: relative;
        overflow:visible;
        .van-button{
            background: none;
            color: #bea588;
            border: none;
        }
    }
    .agreen{
        text-align: center;
        margin-top:  20px;
        .ant-btn{
            margin-top: 40px;
            padding: 0 170px;
        }
    }
}

.center-logo{
    margin: 23% 10% 0 10%;
    text-align: center;
    img{
        width: 140px;
        height: 150px;
    }
}

.center-title{
    color: black;
    // font-family: Cursive;
    margin-top: 5%;
    font-size: 50px;
    text-align: center;
    font-weight: 500;
}
.card-view{
    padding: 40px;
    border-radius: 20px;
}
.type-change{
    align-items: center;
    justify-content: space-around;
    padding: 0 50px;
    font-size: 30px;
}
/deep/ 
.van-field__error-message{
    display: none;
}

.text-area{
    width: 100%;
    margin-top: 42px;
    border: 1px solid #e6d2bc;
    border-radius: 20px;
    position: relative;
    overflow:visible;
    font-size: 28px;

}


.note-text{
    color: #bea588;
    font-size: 28px;
    text-align: left;
    line-height: 36px;
}
</style>