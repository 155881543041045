
export const bidApplicate = {
    name: 'bidApplicate',
    path: '/takeBid/bidApplicate',
    meta: {
        title: '保函申请',
        keepAlive: true
    },
    component: () => import( /* webpackChunkName: "page" */'@/pages/takeBid/bidApplicate'),
}

export const bidProBook = {
    name: 'bidProBook',
    path: '/takeBid/proBook',
    meta: {
        title: '承诺函',
        keepAlive: false
    },
    component: () => import( /* webpackChunkName: "page" */'@/pages/takeBid/proBook'),
}

export const bidcompanyProbook = {
    name: 'bidcompanyProbook',
    path: '/takeBid/companyProbook',
    meta: {
        title: '企业授权书',
        keepAlive: false
    },
    component: () => import( /* webpackChunkName: "page" */'@/pages/takeBid/companyProbook'),
}

export const letterDownload = {
    name: 'letterDownload',
    path: '/takeBid/letterDownload',
    meta: {
        title: '保函申请',
        keepAlive: false
    },
    component: () => import( /* webpackChunkName: "page" */'@/pages/takeBid/letterDownload'),
}
//保函验真
export const letterCheck = {
    name: 'letterCheck',
    path: '/takeBid/letterCheck',
    meta: {
        title: '保函验真',
        keepAlive: false
    },
    component: () => import( /* webpackChunkName: "page" */'@/pages/takeBid/letterCheck'),
}
//验真结果显示页
export const resultView = {
    name: 'resultView',
    path: '/takeBid/resultView',
    meta: {
        title: '保函验真',
        keepAlive: false
    },
    component: () => import( /* webpackChunkName: "page" */'@/pages/takeBid/resultView'),
}
//保函申请结果显示页
export const bidResult = {
    name: 'bidResult',
    path: '/takeBid/bidResult',
    meta: {
        title: '保函申请',
        keepAlive: false
    },
    component: () => import( /* webpackChunkName: "page" */'@/pages/takeBid/bidResult'),
}
//保函申请结果显示页
export const bidProduct = {
    name: 'bidProduct',
    path: '/takeBid/bidProduct',
    meta: {
        title: '非融资担保业务',
        keepAlive: false
    },
    component: () => import( /* webpackChunkName: "page" */'@/pages/takeBid/bidProduct'),
}
//银行支付页
export const payBank = {
    name: 'payBank',
    path: '/takeBid/payBank',
    meta: {
        title: '收银台',
        keepAlive: false
    },
    component: () => import( /* webpackChunkName: "page" */'@/pages/takeBid/payBank'),
}
//支付详情页
export const payDetail = {
    name: 'payDetail',
    path: '/takeBid/payDetail',
    meta: {
        title: '收银台',
        keepAlive: false
    },
    component: () => import( /* webpackChunkName: "page" */'@/pages/takeBid/payDetail'),
}