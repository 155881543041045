<!--
* @Description: 应急转贷
* @Author: zhaogeng
* @CreateDate: 2024/03/14 11:45:42
* @FileName: emergLoantransfer.vue
!-->
<template>
   <div class="cusman-bac-oner">
    <img src="../../assets/images/emerg.png" model="widthFix" style="width:100%">
   </div>
</template>

<script>
export default {
    data () {
        return {

        }
    }
}
</script>

<style lang='scss' scoped>
    .cusman-bac-oner {
    display: flex;
    width: 100%;
    height:auto
}
.user-bac-one {
    background-image: none
}
</style>