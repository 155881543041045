import request from "../../request/index"

/**
 * 登录
 * @param { Object } data 
 * @returns 
 */
export function login (data) {
    return request({
        url: '/open/sms/login',
        method: 'post',
        params: data,
        headers: {
            'authentication-token': ''

        }
    })
}

/**
 * 判断账号是否注册
 * @param { Object } data 
 * @returns 
 */
 export function isUser (data) {
    return request({
        url: '/open/user/isExist',
        method: 'get',
        params: data,
        headers: {
            'authentication-token': ''
        }
    })
}

/**
 * 获取验证码
 * @param { Object } data 
 * @returns 
 */
 export function getPhoneNum (data) {
    return request({
        url: '/open/send/code',
        method: 'get',
        params: data,
        headers: {
            'authentication-token': ''
        }
    })
}

/**
 * 注册
 * @param { Object } data 
 * @returns 
 */
 export function setRegistered (data) {
    return request({
        url: '/open/user/register',
        method: 'post',
        data,
        headers: {
            'authentication-token': ''

        }
    })
}
/**
 * 企业是否存在
 * @param { Object } data 
 * @returns 
 */
 export function isCompany (data) {
    return request({
        url: '/open/user/companyIsExist',
        method: 'get',
        params: data,
        headers: {
            'authentication-token': ''

        }
    })
}

/**
 * 注销账号
 */
 export function setDelUser (data) {
    return request({
        url: '/open/user/del',
        method: 'get'
    })
}