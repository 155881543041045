/**
 * 齐鲁银行路由列表
 */
import add from '../../pages/qlBank/add.vue'
import comAdd from '../../pages/qlBank/comAdd.vue'

import pirText from '../../pages/qlBank/agreement/pirText.vue'
import preText from '../../pages/qlBank/agreement/preText.vue'
import userText from '../../pages/qlBank/agreement/userText.vue'

export const qlBankEditAdd = {
    name: 'qlBankEdit',
    path: '/qlBank/edit',
    meta:{
        title:'普惠担保申请',
        keepAlive: true
    },
    component: add
}
export const qlBankEditComAdd = {
    name: 'qlBankComEdit',
    path: '/qlBank/comEdit',
    meta:{
        title:'普惠担保申请',
        keepAlive: true
    },
    component: comAdd
}

export const pir = {
    name: 'qlPir',
    path: '/qlBank/qlPir',
    meta:{
        title:'企业信息授权书',
        keepAlive: true
    },
    component: pirText
}

export const pre = {
    name: 'qlPre',
    path: '/qlBank/qlPre',
    meta:{
        title:'个人信息授权书',
        keepAlive: true
    },
    component: preText
}

export const user = {
    name: 'qlUser',
    path: '/qlBank/qlUser',
    meta:{
        title:'敏感个人信息授权书',
        keepAlive: true
    },
    component: userText
}