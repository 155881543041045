<!--
* @Description: 齐鲁银行 新增
* @Author: sunchunchun
* @CreateDate: 2022/09/01 08:59:53
* @FileName: edit.vue
!-->
<template>
    <div class="form-box">
        <div class="form-boxs">
            <div class="form-title">基本信息填写</div>
            <van-form ref="form" style="padding: 0 10px;background: #fff;" validate-first>
                <!-- 姓名 -->
                <van-field
                    v-model="form.custName"
                    name="姓名"
                    label="姓名"
                    placeholder="请输入姓名"
                    disabled
                    required
                />
                <!-- 身份证号码 -->
                <van-field
                    v-model="form.idCard"
                    name="身份证号码"
                    label="身份证号码"
                    placeholder="请输入身份证号码"
                    disabled
                    required
                />
                <!-- 联系电话 -->
                <van-field
                    v-model="form.custPhone"
                    name="联系电话"
                    label="联系电话"
                    placeholder="请输入联系电话"
                    disabled
                    required
                />
                <!-- 个人主体类型 -->
                <van-field name="radio" label="个人主体类型" required :rules="[{ required: true, message: '请选择个人主体类型' }]">
                    <template #input>
                        <van-radio-group v-model="form.mainType" @change="onMainType" direction="horizontal">
                            <van-radio
                                style="margin-top: 10px;"
                                v-for="(item, index) in mainTypeList"
                                :key="index"
                                :name="item.dictValue"
                            >
                            {{ item.dictLabel }}
                            </van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <!-- 经营主体名称 -->
                <van-field
                    right-icon=""
                    v-if="form.mainType!=1"
                    maxlength="30"
                    class="none"
                    v-model="form.businessMainName"
                    name="businessMainName"
                    label="经营主体名称"
                    placeholder="请输入经营主体名称"
                    :required=" form.mainType==1 ? false : true"
                    :rules="[{ required: form.mainType==1 ? false : true, message: '请输入经营主体名称'}]"
                >
                    <template #button>
                        <van-icon style="font-size:20px;" name="question-o" @click="onViewAlert"></van-icon>
                    </template>
                </van-field>  
                <!-- 合作银行 -->
                <van-field
                    readonly
                    class="none"
                    clickable
                    name="guarComp"
                    v-model="form.loanBank"
                    label="合作银行"
                    placeholder="请选择合作银行"
                    @click="onBankClick"
                    required
                    :rules="[{ required: true, message: '请选择合作银行' }]"
                />
                <van-popup v-model="showBank" position="bottom">
                    <van-picker
                        value-key="dictLabel"
                        show-toolbar
                        :columns="bankColumns"
                        @confirm="onBankCnfirm"
                        @cancel="showBank = false"
                    />
                </van-popup>
                
            </van-form>
        </div>
        <div class="agreement">
            <span @click="onRading"><span>《个人信息授权》</span><span v-if="form.mainType!=1">《企业信息授权》</span><span>《敏感个人信息授权》</span></span>
            <span :class="isAgreement?'text-rad-t':'text-rad-f'">{{ agreementText }}</span>
        </div>
        <div style="margin: 20px 16px 0 16px;padding-bottom: 20px;">
            <van-button round block type="info" @click="onFailed" :loading="loading">提交</van-button>
        </div>
    </div>
</template>

<script>
import { getInfo, savePersonQl } from "@/api/home/index"
// import { setPhoneEncr, idCard } from "@/utils/validate"
import { getDicData } from "@/api/home/dicData"
import { mapGetters } from "vuex"
import { isCompany } from "@/api/user/index"
export default {
    name: "qlBankEdit",
    data () {
        return {
            loading: false,
            mainTypeList: [], // 主体类型list
            bankColumns: [], // 贷款银行list
            showBank: false, // 银行展示状态
            agreementText: '未阅读', // 协议默认text
            isAgreement: false, // 是否阅读class样式判断
            form: {
                goodsId: '',
                custName: '', // 姓名
                idCard: '', // 身份证号
                custPhone: '', // 联系电话
                loanBank: '齐鲁银行', // 银行
                bankId: '0', // 银行code
                mainType: '1',
            },
        }
    },
    computed: {
        ...mapGetters(['phone', 'userTypes'])
    },
    created() {
        if (this.$route.query.goodsId) {
            this.form.goodsId = this.$route.query.goodsId
        }
        if (this.$route.query && this.$route.query.form!= '') {
            // 判断当前阅读记录是否已阅读
            if (Boolean(this.$route.query.flag)) {
                this.isAgreement = Boolean(this.$route.query.flag)
                this.agreementText = '已阅读'
                this.form = JSON.parse(this.$route.query.form)
                this.$nextTick(_ => {
                    this.setFlag()
                })
            }
        }
        // 获取字典值
        this.getDic() 
    },
    methods: {
        // 查看经营主体提示
        onViewAlert () {
            this.$dialog.alert({
                message:'个人主体类型为小微企业主、个体工商户时，填写用款小微企业或个体工商户全称！'
            })
        },
        // 
        setFlag() {
            this.isAgreement = true
            this.agreementText = '已阅读'
        },
        // 个人主体类型
        onMainType(val) {
            this.isAgreement = false
            this.agreementText = '未阅读'
            if (val == 1) {
                this.form.businessMainName = ''
            }
        },
        // 获取字典值
        getDic() {
            // 个人信息
            getInfo().then(res => {
                let data = res.data
                this.form.custPhone = data.ysOpenUser.phoneNumber
                this.form.custName = data.ysLegalpersonInformation.name
                this.form.idCard = data.ysLegalpersonInformation.certNo
            })
            // 银行列表
            getDicData('sys_bank_type').then(res => {
                this.bankColumns = res.data
            })
            // 个人主体类型
            getDicData('per_type').then(res => {
                this.mainTypeList = res.data
            })
        },
        // 表单提交
        onFailed() {
            this.$refs.form.validate().then(_ => {
                if (!this.isAgreement) {
                    this.$toast("请点击并阅读下方协议")
                    return
                }
                // 个人主体类型 为 小微企业主或个体工商户的时候检测经营主体名称是否存在
                if (this.form.mainType == '2' || this.form.mainType == '3') {
                    // 判断当前经营主体是否存在
                    isCompany({name: this.form.businessMainName}).then(res => {
                        if (res.code == 200) {
                            this.addForm()
                        } else {
                            this.$toast("经营主体名称不存在,请核实确认！")
                        }
                    })
                } else {
                    this.addForm()
                }
            }).catch(_ => {

            })
        },
        // 新增
        addForm() {
            this.loading = true
            // 添加一条数据
            savePersonQl(this.form).then(res => {
                if (res.code == 200) {
                    this.$toast('新增成功')
                    const toast = this.$toast.loading({
                        duration: 3000,
                        forbidClick: true,
                        message: '受理中...'
                    })
                    setTimeout(_ => {
                        toast.clear()
                        this.loading = false
                        this.$store.commit('CHANGE_TAB_ACTIVE', '/home/task')
                        this.$router.replace({name: 'task', params: {active: 1}})
                    }, 3000)
                } else {
                    this.loading = false
                    this.$toast(res.msg)
                }
            }, error => {
                this.loading = false
                this.$toast('新增失败')
            })
        },
        onBankClick() {
            this.showBank = true
        },
        // 银行选择
        onBankCnfirm(val) {
            this.showBank = false
            this.form.loanBank = val.dictLabel
            this.form.bankId = val.dictValue
        },
        // 阅读协议
        onRading() {
            console.log(JSON.stringify(this.form))
            this.$router.push({
                name: 'qlPre',
                query: {
                    form: JSON.stringify(this.form)
                }
            })
        },
    }
}
</script>

<style lang='scss' scoped>
/deep/
input.van-field__control::-webkit-input-placeholder {
    color: #b3b3b3 !important;
}
/deep/
textarea.van-field__control::-webkit-input-placeholder {
    color: #b3b3b3 !important;
}
.form-box{
    text-align: center;
    min-height: 98vh;
    // background-image: linear-gradient(to bottom , #fff, #D5E9F7);
    .form-title{
        width: 100%;
        font-size: 30px;
        padding: 20px 0;
        background: #D1ECF5;
        color: #3D658E;
        overflow: hidden;
    }
    .form-boxs{
        width: 90vw;
        height: 100%;
        border-radius: 20px;
        box-shadow: 0 0 25px rgba(130, 130, 133, 0.43);
        overflow: hidden;
        margin-left: 5vw;
        margin-top: 30px;
    }
    .agreement{
        margin-top: 20px;
        font-size: 28px;
        color: #1D6EAD;
        width: 100%;
        text-align: center;
        .text-rad-f{
            color: #808080;
        }
        .text-rad-t{
            color: #00C01F;
        }
    }
}
.checkbox{
    margin-bottom: 28px;
}
/deep/
.van-field__control{
    color: #323233 !important;
}
</style>